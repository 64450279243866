import React, { useContext } from "react"
import { useStyletron } from "baseui"
import { Modal, SIZE, ModalOverrides } from "baseui/modal"

import AuthModalImage from "~components/shared/auth-modal/auth-modal-image"
import AuthModalContent from "~components/shared/auth-modal/auth-modal-content"
import { AuthModalContext } from "~components/shared/header/header"

const AuthModal = () => {
    const modalContext = useContext(AuthModalContext)
    const styles = useStyles()

    return (
        <Modal
            size={SIZE.auto}
            isOpen={modalContext?.openAuthModal}
            overrides={getModalStylesOverrides()}
            onClose={() => modalContext?.setOpenAuthModal(false)}
            closeable
        >
            <div className={styles.wrapper}>
                <AuthModalImage />
                <AuthModalContent />
            </div>
        </Modal>
    )
}

function getModalStylesOverrides(): ModalOverrides {
    return {
        Close: { style: { display: "none" } },
        Dialog: {
            style: ({ $theme }) => {
                const borderRadius = $theme.borders.radius400

                return {
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                    overflow: "hidden",
                }
            },
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        wrapper: css({
            display: "flex",
            flexDirection: "row",
            maxWidth: "95vw",
            color: theme.colors.contentPrimary,
        }),
    }
}

export default AuthModal

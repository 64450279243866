import React, { useContext } from "react"
import { useStyletron } from "baseui"
import { useLocation, useMatch } from "@reach/router"

import Header from "~components/shared/header/header"
import Footer from "~components/shared/footer"
import Tabbar from "~components/shared/tab-bar/tab-bar"
import { useHelpCrunch } from "~utils/use-help-crunch"
import { ResponsiveContext } from "~config/responsive-provider"

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    useHelpCrunch()

    const styles = useStyles()
    const matchGuide = useMatch("/guide/*")
    const { pathname } = useLocation()
    const { windowSize } = useContext(ResponsiveContext)
    const showFooter = ["/booking", "/wishlists", "/inbox"].every(
        (path) => !pathname.includes(path)
    )

    if (matchGuide) return <>{children}</>

    return (
        <div className={styles.root}>
            <main className={styles.content}>{children}</main>
            {((windowSize.isMedium && pathname !== "/") ||
                pathname.includes("/search")) && <Header />}
            {showFooter && <Footer />}
            <Tabbar />
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()
    const { pathname } = useLocation()

    return {
        root: css({
            backgroundColor: theme.colors.backgroundPrimary,
            color: theme.colors.contentPrimary,
        }),
        content: css({
            position: "relative",
            marginTop: "0",
            marginRight: "auto",
            marginBottom: "0",
            marginLeft: "auto",
            minHeight: "100vh",
            [theme.mediaQuery.medium]: {
                minHeight: "calc(100vh - 72px)",
                paddingTop: pathname === "/" ? 0 : "72px",
            },
        }),
        loadingScreen: css({
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            color: theme.colors.contentPrimary,
            backgroundColor: theme.colors.backgroundPrimary,
        }),
    }
}

export default Layout

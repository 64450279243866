import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { format, isBefore } from "date-fns"
import { Drawer, SIZE, ANCHOR, DrawerOverrides } from "baseui/drawer"
import { StatefulPopover, PLACEMENT, PopoverOverrides } from "baseui/popover"
import { Input, InputOverrides } from "baseui/input"
import { Search } from "baseui/icon"
import { useStyletron } from "baseui"

import MobileMenu from "~components/shared/search-input/mobile-menu"
import SearchButton from "~components/shared/search-input/search-button"
import SearchDropdown from "~components/shared/search-input/search-dropdown"
import AvailabilityCalendar from "~components/pages/listing/availability-calendar"
import { getStateSuggestions } from "~components/shared/search-input/search-helpers"
import {
    SearchSpeciesFieldsFragment,
    useSearchSpeciesQuery,
} from "~graphql/generated/graphql"

const popoverOverrides: PopoverOverrides = {
    Body: {
        style: ({ $theme }) => ({
            borderTopLeftRadius: $theme.borders.radius500,
            borderTopRightRadius: $theme.borders.radius500,
            borderBottomRightRadius: $theme.borders.radius500,
            borderBottomLeftRadius: $theme.borders.radius500,
            overflow: "hidden",
            width: "350px",
        }),
    },
    Inner: {
        style: ({ $theme }) => ({
            backgroundColor: $theme.colors.backgroundPrimary,
            borderTopLeftRadius: $theme.borders.radius500,
            borderTopRightRadius: $theme.borders.radius500,
            borderBottomRightRadius: $theme.borders.radius500,
            borderBottomLeftRadius: $theme.borders.radius500,
            paddingTop: $theme.sizing.scale800,
            paddingRight: 0,
            paddingBottom: $theme.sizing.scale800,
            paddingLeft: 0,
            overflow: "auto",
            maxHeight: "400px",
        }),
    },
}

function getInputOverrides({
    handleButtonClick,
    isOpen,
    withIcon,
}: {
    handleButtonClick: (...args: any[]) => any
    isOpen?: boolean
    withIcon?: boolean
}): InputOverrides {
    return {
        Root: {
            style: ({ $theme }) => {
                return {
                    position: "relative",
                    borderTopRightRadius: "48px",
                    borderTopLeftRadius: "48px",
                    borderBottomRightRadius: "48px",
                    borderBottomLeftRadius: "48px",
                    boxShadow: $theme.lighting.shadow600,
                    backgroundColor: $theme.colors.backgroundPrimary,
                    width: isOpen ? "calc(100% - 110px)" : "100%",
                    marginTop: isOpen ? $theme.sizing.scale600 : "0",
                    marginRight: isOpen ? $theme.sizing.scale800 : "0",
                    marginBottom: isOpen ? $theme.sizing.scale600 : "0",
                    marginLeft: isOpen ? $theme.sizing.scale800 : "0",
                    fontSize: "16px",
                    minWidth: "120px",
                    overflow: "hidden",
                    paddingRight: "0px",
                }
            },
        },
        InputContainer: {
            style: ({ $theme }) => {
                return {
                    borderTopRightRadius: "48px",
                    borderTopLeftRadius: "48px",
                    borderBottomRightRadius: "48px",
                    borderBottomLeftRadius: "48px",
                    backgroundColor: $theme.colors.backgroundPrimary,
                }
            },
        },
        Input: {
            style: ({ $theme }) => {
                return {
                    paddingRight: $theme.sizing.scale600,
                    paddingLeft: $theme.sizing.scale1200,
                    [$theme.mediaQuery.small]: {
                        paddingRight: $theme.sizing.scale900,
                        paddingLeft: $theme.sizing.scale900,
                    },
                }
            },
        },
        Before: function Before() {
            if (withIcon)
                return (
                    <Search
                        // @ts-ignore
                        style={{
                            position: "absolute",
                            top: "50%",
                            transform: "translate(16px, -50%)",
                            color: "#f65e38",
                        }}
                        size={25}
                    />
                )

            return null
        },
        After: function After() {
            return <SearchButton onClick={handleButtonClick}></SearchButton>
        },
    }
}

function getDrawerOverrides(
    closeButton: string,
    onClick: () => void
): DrawerOverrides {
    return {
        DrawerBody: {
            style: {
                marginTop: 0,
                marginRight: 0,
                marginBottom: 0,
                marginLeft: 0,
            },
        },
        // eslint-disable-next-line react/prop-types
        Close: () => <></>,
    }
}

function getLargeInputOverrides(selected = false): InputOverrides {
    return {
        Root: {
            style: ({ $theme, $isFocused }) => {
                const color =
                    $isFocused || selected
                        ? $theme.colors.backgroundPrimary
                        : "transparent"

                return {
                    borderTopRightRadius: "40px",
                    borderTopLeftRadius: "40px",
                    borderBottomRightRadius: "40px",
                    borderBottomLeftRadius: "40px",
                    borderTopColor: color,
                    borderRightColor: color,
                    borderBottomColor: color,
                    borderLeftColor: color,
                    backgroundColor: color,
                    transitionTiming: "0s",
                    boxShadow:
                        $isFocused || selected
                            ? $theme.lighting.shadow600
                            : "none",
                    ":hover": {
                        cursor: "pointer",
                        boxShadow: $theme.lighting.shadow600,
                    },
                }
            },
        },
        InputContainer: {
            style: ({ $theme }) => ({
                backgroundColor: "transparent",
                paddingLeft: $theme.sizing.scale400,
            }),
        },
        Input: {
            style: ({ $theme }) => ({
                fontSize: "15px",
                fontWeight: 600,
                ":hover": {
                    cursor: "pointer",
                },
                "::placeholder": {
                    fontWeight: 500,
                    color: $theme.colors.contentTertiary,
                },
            }),
        },
        ClearIconContainer: {
            style: ({ $isFocused }) => ({
                display: $isFocused || selected ? "flex" : "none",
            }),
        },
    }
}

interface Props {
    query?: string
    dates: Date[]
    setDates: (dates: Date[]) => void
}

const SearchInput: React.FC<Props> = ({ query, dates, setDates }) => {
    const styles = useStyles()
    const [value, setValue] = useState(query || "")
    const { states, species } = useSuggestions(value)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [selectedBox, setSelectedBox] = useState<"query" | "start" | "end">()

    useEffect(() => {
        if (query) {
            setValue(query)
        } else {
            setValue("")
        }
    }, [query])

    const inputSharedProps = {
        value,
        // clearable: true,
        // clearOnEscape: true,
        placeholder: "Search for state or species",
        onChange: (e: any) => setValue(e.target.value),
    }

    function onCalendarChange(newDate: { date: Date[] | Date }) {
        if (Array.isArray(newDate.date)) {
            if (selectedBox === "start") {
                setSelectedBox("end")
                setDates(newDate.date)
            } else if (isBefore(newDate.date[0], dates[0])) {
                setSelectedBox("start")
                setDates(newDate.date)
            } else {
                if (dates.length < 2) {
                    setDates(newDate.date)
                } else {
                    setDates([dates[0], ...newDate.date])
                }
            }
        } else {
            setDates([newDate.date])
        }
    }

    function handleButtonClick(e: React.FormEvent<HTMLButtonElement>) {
        e.preventDefault()
        if (value !== "" || dates.length >= 2) {
            navigate(
                `/search?query=${value}${
                    dates.length > 1
                        ? `&startDate=${dates[0].getTime()}&endDate=${dates[1].getTime()}`
                        : ""
                }`
            )
        }
        setIsDrawerOpen(false)
    }

    function handleLinks(query: string) {
        setIsDrawerOpen(false)
        setValue(query)
    }

    return (
        <>
            {/* Mobile Version */}
            <div className={styles.mobileRoot}>
                <Input
                    {...inputSharedProps}
                    overrides={getInputOverrides({
                        handleButtonClick,
                        withIcon: true,
                    })}
                    onFocus={(e: any) => {
                        if (!isDrawerOpen) setIsDrawerOpen(true)
                        e.target.blur() // prevent focusing again after closing drawer
                    }}
                />
                {/* @ts-ignore */}
                <Drawer
                    isOpen={isDrawerOpen}
                    onClose={() => {
                        setIsDrawerOpen(false)
                        setValue("")
                    }}
                    size={SIZE.full}
                    anchor={ANCHOR.top}
                    animate={false}
                    overrides={getDrawerOverrides(
                        styles.closeButton,
                        () => setIsDrawerOpen(false) // pass to override for custom close button
                    )}
                >
                    <MobileMenu
                        query={query}
                        dates={dates}
                        closeDrawer={() => setIsDrawerOpen(false)}
                    />
                </Drawer>
            </div>
            {/* END Mobile Version */}

            {/* Desktop Version */}
            <div className={styles.desktopRoot}>
                {/* <form onSubmit={handleButtonClick}> */}
                <div className={styles.inputContainer}>
                    <StatefulPopover
                        content={
                            <SearchDropdown
                                input={value}
                                states={states}
                                species={species}
                                clickHandler={handleLinks}
                            />
                        }
                        placement={PLACEMENT.bottomLeft}
                        overrides={popoverOverrides}
                        popoverMargin={16}
                        returnFocus
                        autoFocus
                    >
                        <div style={{ flex: 1 }}>
                            <Input
                                autoFocus
                                onFocus={() => setSelectedBox("query")}
                                overrides={getLargeInputOverrides(
                                    selectedBox === "query"
                                )}
                                placeholder="Location, Species, or Outfitter"
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                clearable
                            />
                        </div>
                    </StatefulPopover>

                    {/* Divider */}
                    <div className={styles.divider} />

                    <StatefulPopover
                        content={
                            <div
                                style={{
                                    borderTopLeftRadius: 10,
                                    borderTopRightRadius: 10,
                                    borderBottomRightRadius: 10,
                                    borderBottomLeftRadius: 10,
                                    overflow: "hidden",
                                }}
                            >
                                <AvailabilityCalendar
                                    minDate={new Date()}
                                    includeDates={[]}
                                    value={dates}
                                    onChange={onCalendarChange}
                                />
                            </div>
                        }
                        popoverMargin={16}
                        returnFocus
                        autoFocus
                    >
                        <div className={styles.dateInputContainer}>
                            <Input
                                readOnly
                                onFocus={() => setSelectedBox("start")}
                                overrides={getLargeInputOverrides(
                                    selectedBox === "start"
                                )}
                                placeholder="Start date"
                                value={
                                    dates.length > 0
                                        ? format(dates[0], "MMM dd, yyyy")
                                        : ""
                                }
                            />
                            <div className={styles.divider} />
                            <Input
                                readOnly
                                onFocus={() => setSelectedBox("end")}
                                overrides={getLargeInputOverrides(
                                    selectedBox === "end"
                                )}
                                placeholder="End date"
                                value={
                                    dates.length > 1
                                        ? format(dates[1], "MMM dd, yyyy")
                                        : ""
                                }
                            />
                        </div>
                    </StatefulPopover>
                    <SearchButton
                        onClick={handleButtonClick}
                        disabled={value === "" && dates.length <= 1 }
                    />
                </div>
            </div>
            {/* END Desktop Version */}
        </>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        mobileRoot: css({
            display: "initial",
            [theme.mediaQuery.small]: {
                display: "none",
            },
        }),
        inputContainer: css({
            display: "flex",
            width: "100%",
            background: theme.colors.backgroundTertiary,
            border: `1px solid ${theme.colors.borderOpaque}`,
            borderRadius: "40px",
            padding: "2px",
        }),
        dateInputContainer: css({
            display: "flex",
            maxWidth: "40%",
            marginRight: "2px",
            width: "fit-content",
        }),
        desktopRoot: css({
            display: "none",
            [theme.mediaQuery.small]: {
                display: "initial",
            },
        }),
        closeButton: css({
            paddingTop: 0,
            paddingRight: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            borderTopWidth: "0px",
            borderRightWidth: "0px",
            borderBottomWidth: "0px",
            borderLeftWidth: "0px",
            backgroundColor: "transparent",
            color: theme.colors.contentPrimary,
            position: "absolute",
            top: "24px",
            left: "24px",
            fontWeight: 700,
            ":hover": {
                cursor: "pointer",
            },
        }),
        divider: css({
            minWidth: "2px",
            height: "32px",
            margin: "auto 2px",
            backgroundColor: theme.colors.borderOpaque,
        }),
    }
}

function useSuggestions(value: string) {
    const [states, setStates] = useState<string[]>([])
    const [species, setSpecies] = useState<SearchSpeciesFieldsFragment[]>([])
    // TODO: Implement debounce for performance
    const { data } = useSearchSpeciesQuery({
        variables: { data: { text: value } },
    })

    useEffect(() => {
        setStates(getStateSuggestions(value))
        if (data) setSpecies(data.searchSpecies.slice(0, 3))
    }, [data, value])

    return { states, species }
}

export default SearchInput

import React, { useContext } from "react"
import { navigate } from "gatsby"
import { useAuth0 } from "@auth0/auth0-react"
import { useStyletron } from "baseui"
import {
    RiSunLine,
    RiMoonClearFill,
    RiShieldCheckLine,
    RiCalendarLine,
    RiCustomerServiceLine,
    RiLogoutCircleRLine,
    RiMapPin2Line,
    RiInboxArchiveLine,
    RiHeartLine,
} from "react-icons/ri"

import ExternalLink from "~components/shared/external-link"
import { ThemeStates, useThemeContext } from "~config/theme-provider"
import { UserContext } from "~config/user-provider"

interface Props {
    close: () => void
}
const HeaderProfileButtonMenu: React.FC<Props> = ({ close }) => {
    const styles = useStyles()
    const { logout } = useAuth0()
    const { userRoles } = useContext(UserContext)
    const { theme, toggle } = useThemeContext()

    const handleTripsClick = () => {
        navigate("/trips")
        close()
    }

    const handleThemeToggle = () => {
        toggle()
        close()
    }

    const handleLogout = () => {
        logout({ returnTo: window.location.origin })
        close()
    }

    const handleAccountClick = () => {
        navigate("/account")
        close()
    }

    const handleWishlistsClicked = () => {
        navigate("/wishlists")
        close()
    }

    const handleHelpClick = () => {
        navigate("/help")
        close()
    }

    const handleInboxClick = () => {
        navigate("/inbox")
        close()
    }

    return (
        <>
            <div className={styles.row} onClick={handleTripsClick}>
                <span className={styles.bold}>Trips</span>
                <RiMapPin2Line className={styles.icon} />
            </div>
            <div className={styles.row} onClick={handleInboxClick}>
                <span className={styles.bold}>Inbox</span>
                <RiInboxArchiveLine className={styles.icon} />
            </div>
            <div className={styles.row} onClick={handleWishlistsClicked}>
                <span className={styles.bold}>Wishlists</span>
                <RiHeartLine className={styles.icon} />
            </div>
            <Divider />
            <div className={styles.row} onClick={handleAccountClick}>
                <span>Account</span>
            </div>
            <div className={styles.row} onClick={handleHelpClick}>
                <span>Help</span>
                <RiCustomerServiceLine className={styles.icon} />
            </div>
            <div className={styles.row} onClick={handleThemeToggle}>
                {theme === ThemeStates.LIGHT ? (
                    <>
                        <span>Dark mode</span>
                        <RiMoonClearFill className={styles.icon} />
                    </>
                ) : (
                    <>
                        <span>Light mode</span>
                        <RiSunLine className={styles.icon} />
                    </>
                )}
            </div>
            <Divider />
            {userRoles && userRoles.includes("admins") && (
                <ExternalLink to={process.env.GATSBY_DASHBOARD_URL!}>
                    <div className={`${styles.row}`}>
                        <span>Admin dashboard</span>
                        <RiShieldCheckLine className={styles.icon} />
                    </div>
                </ExternalLink>
            )}
            {userRoles && userRoles.includes("outfitters") && (
                <ExternalLink to={process.env.GATSBY_DASHBOARD_URL!}>
                    <div className={`${styles.row}`}>
                        <span>Manage listings</span>
                        <RiCalendarLine className={styles.icon} />
                    </div>
                </ExternalLink>
            )}
            <div className={styles.row} onClick={handleLogout}>
                <span>Log out</span>
                <RiLogoutCircleRLine className={styles.icon} />
            </div>
        </>
    )
}

const Divider = () => {
    const styles = useStyles()

    return <div className={styles.divider}></div>
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        row: css({
            fontSize: "15px",
            fontWeight: 300,
            userSelect: "none",
            paddingTop: theme.sizing.scale400,
            paddingRight: theme.sizing.scale700,
            paddingBottom: theme.sizing.scale400,
            paddingLeft: theme.sizing.scale700,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            ":hover": {
                cursor: "pointer",
                backgroundColor: theme.colors.backgroundSecondary,
            },
        }),
        bold: css({
            fontWeight: 600,
        }),
        icon: css({
            fontSize: "18px",
            verticalAlign: "middle",
        }),
        divider: css({
            marginTop: theme.sizing.scale300,
            marginRight: "0",
            marginBottom: theme.sizing.scale300,
            marginLeft: "0",
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
        }),
    }
}

export default HeaderProfileButtonMenu

import React from "react"
import { Link } from "gatsby"
import { useStyletron } from "baseui"
import {
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaHeart,
    FaYoutube,
    FaLinkedin,
} from "react-icons/fa"

import ResponsiveBlock from "~components/shared/responsive-block"
import Divider from "~components/shared/divider"
import HeaderLogo from "~components/shared/header/header-logo"
import ExternalLink from "~components/shared/external-link"
import DotSeparator from "~components/shared/dot-separator"
import { SOCIAL_LINKS } from "~config/constants"

const Footer: React.FC = () => {
    const styles = useStyles()

    return (
        <ResponsiveBlock className={styles.footer}>
            <div className={styles.top}>
                <div className={styles.topLogoContainer}>
                    <HeaderLogo isCentered />
                    <div className={styles.titleTop}>
                        The quickest way for a sportsman to book with reputable
                        guides and outfitters.
                    </div>
                </div>
                <div className={styles.topLinkContainer}>
                    <div className={styles.topLink}>
                        <Link
                            to="/about"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            About Us
                        </Link>
                    </div>
                    <div className={styles.topLink}>
                        <Link
                            to="/press"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            Mallard Bay News
                        </Link>
                    </div>
                    <div className={styles.topLink}>
                        <Link
                            to="/help"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            Help & Support
                        </Link>
                    </div>
                    <div className={styles.topLink}>
                        <a
                            href="https://shop.mallardbay.com/"
                            target="_blank"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            Shop Gear
                        </a>
                    </div>
                    <div className={styles.topLink}>
                        <Link
                            to="/guide"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            List on Mallard Bay
                        </Link>
                    </div>
                    <div className={styles.topLink}>
                        <a
                            href="https://guidetech.mallardbay.com/"
                            target="_blank"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            Demo Guidetech
                        </a>
                    </div>
                    <div className={styles.topLink}>
                        <ExternalLink to="/privacy">Privacy</ExternalLink>
                    </div>
                    <div className={styles.topLink}>
                        <ExternalLink to="/terms">Terms</ExternalLink>
                    </div>
                    <div className={styles.topLink}>
                        <Link
                            to="/sitemap/v2"
                            style={{ color: "inherit", textDecoration: "none" }}
                        >
                            Sitemap
                        </Link>
                    </div>
                </div>
            </div>
            <Divider />
            <div className={styles.bottom}>
                <div className={styles.bottomContainer}>
                    <div className={styles.titleBottom}>
                        © {new Date().getFullYear()} Mallard Bay, Inc.
                    </div>
                    <span className={styles.dotSeparator}>
                        <DotSeparator />
                    </span>
                    <div className={styles.heartSection}>
                        Made with
                        <FaHeart className={styles.heartIcon} />
                        in Baton Rouge, LA
                    </div>
                </div>
                <div>
                    <ExternalLink to={SOCIAL_LINKS.FACEBOOK}>
                        <FaFacebookF className={styles.socialIcon} />
                    </ExternalLink>
                    <ExternalLink to={SOCIAL_LINKS.TWITTER}>
                        <FaTwitter className={styles.socialIcon} />
                    </ExternalLink>
                    <ExternalLink to={SOCIAL_LINKS.INSTAGRAM}>
                        <FaInstagram className={styles.socialIcon} />
                    </ExternalLink>
                    <ExternalLink to={SOCIAL_LINKS.YOUTUBE}>
                        <FaYoutube className={styles.socialIcon} />
                    </ExternalLink>
                    <ExternalLink to={SOCIAL_LINKS.LINKEDIN}>
                        <FaLinkedin className={styles.socialIcon} />
                    </ExternalLink>
                </div>
            </div>
        </ResponsiveBlock>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        footer: css({
            backgroundColor: theme.colors.backgroundSecondary,
            borderTop: `1px solid ${theme.colors.borderOpaque}`,
            [theme.mediaQuery.small]: {
                paddingBottom: 0,
            },
        }),
        top: css({
            textAlign: "center",
            paddingTop: theme.sizing.scale1000,
            paddingRight: 0,
            paddingBottom: theme.sizing.scale1000,
            paddingLeft: 0,
            [theme.mediaQuery.medium]: {
                paddingTop: theme.sizing.scale1600,
                paddingRight: 0,
                paddingBottom: theme.sizing.scale1600,
                paddingLeft: 0,
            },
        }),
        titleTop: css({
            paddingTop: "16px",
            paddingRight: "0",
            paddingBottom: "16px",
            paddingLeft: "0",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.medium]: {
                fontSize: "16px",
                paddingTop: "20px",
                paddingRight: "0",
                paddingBottom: "10px",
                paddingLeft: "0",
            },
        }),
        topLogoContainer: css({
            [theme.mediaQuery.medium]: {
                paddingTop: "10px",
            },
        }),
        topLinkContainer: css({
            display: "grid",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            fontSize: "16px",
            fontWeight: "bold",
            gridTemplateColumns: "repeat(2, 1fr)",
            [theme.mediaQuery.medium]: {
                gridTemplateColumns: "repeat(4, 1fr)",
                // flexDirection: "row",
                fontSize: "18px",
            },
        }),
        topLink: css({
            marginTop: "8px",
            marginRight: "8px",
            marginBottom: "8px",
            marginLeft: "8px",
            minWidth: "max-content",
            textAlign: "left",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.medium]: {
                marginTop: "12px",
                marginRight: "16px",
                marginBottom: "0",
                marginLeft: "16px",
                textAlign: "center",
            },
        }),
        bottom: css({
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: theme.sizing.scale800,
            paddingRight: 0,
            paddingBottom: theme.sizing.scale800,
            paddingLeft: 0,
            color: theme.colors.contentPrimary,
            fontSize: "14px",
            fontWeight: 300,
            [theme.mediaQuery.medium]: {
                flexDirection: "row",
                textAlign: "left",
            },
        }),
        bottomContainer: css({
            display: "flex",
            flexDirection: "column",
            [theme.mediaQuery.medium]: {
                flexDirection: "row",
            },
        }),
        titleBottom: css({
            marginBottom: "8px",
            [theme.mediaQuery.medium]: {
                marginBottom: 0,
            },
        }),
        heartSection: css({
            display: "flex",
            justifyContent: "center",
        }),
        heartIcon: css({
            fontSize: "14px",
            marginTop: "0px",
            marginRight: "6px",
            marginBottom: "0px",
            marginLeft: "6px",
            color: "rgb(246, 94, 56)",
        }),
        socialIcon: css({
            fontSize: "18px",
            marginTop: "16px",
            marginRight: "8px",
            marginBottom: "0",
            marginLeft: "8px",
            color: theme.colors.contentPrimary,
            ":hover": {
                cursor: "pointer",
                color: theme.colors.contentSecondary,
            },
            [theme.mediaQuery.medium]: {
                marginTop: "0",
                marginRight: "0",
                marginBottom: "0",
                marginLeft: "24px",
            },
        }),
        dotSeparator: css({
            display: "none",
            [theme.mediaQuery.medium]: {
                display: "initial",
            },
        }),
    }
}

export default Footer

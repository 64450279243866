import React, { useState, useContext } from "react"
import { useStyletron } from "baseui"
import { Input } from "baseui/input"
import { Button } from "baseui/button"

import FormControl from "~components/shared/form-control"
import SocialButton from "~components/shared/auth-modal/social-button"
import Divider from "~components/shared/divider"
import { authForms, AuthModalContext } from "~components/shared/header/header"

const SignInForm = () => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const modalContext = useContext(AuthModalContext)
    const styles = useStyles()

    return (
        <div className={styles.root}>
            <SocialButton google text="Log in with Google" />
            <SocialButton facebook hasMarginTop text="Log in with Facebook" />
            <Divider text="or" margin />
            <form onSubmit={(e) => e.preventDefault()}>
                <FormControl label={"Email address"} caption={""}>
                    <Input
                        id="email-input"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl label={"Password"} caption={""}>
                    <Input
                        id="password-input"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </FormControl>
                <Button
                    type="submit"
                    overrides={{
                        BaseButton: {
                            style: {
                                width: "100%",
                                marginTop: "8px",
                                marginBottom: "24px",
                            },
                        },
                    }}
                >
                    Log in
                </Button>
            </form>
            <div className={styles.link}>Forgot password?</div>
            <div className={styles.question}>
                Not a member?{" "}
                <span
                    className={styles.link}
                    onClick={() => modalContext?.setAuthForm(authForms.SIGN_UP)}
                >
                    Sign up
                </span>
            </div>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        root: css({
            paddingTop: "40px",
            paddingRight: "24px",
            paddingBottom: "40px",
            paddingLeft: "24px",
            [theme.mediaQuery.small]: {
                paddingTop: "40px",
                paddingRight: "64px",
                paddingBottom: "40px",
                paddingLeft: "64px",
            },
        }),
        question: css({
            fontSize: "15px",
            fontWeight: 300,
            color: theme.colors.contentSecondary,
            marginTop: "24px",
        }),
        link: css({
            fontSize: "15px",
            fontWeight: 600,
            color: theme.colors.contentPrimary,
            textDecoration: "underline",
            ":hover": {
                cursor: "pointer",
            },
        }),
    }
}

export default SignInForm

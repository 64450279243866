import React from "react"
import ContentLoader from "react-content-loader"

import { ThemeStates, useThemeContext } from "~config/theme-provider"

type LoadingSkeletonProps = {
    dark?: boolean
    circle?: boolean
    width?: string
    height?: string
}

const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
    dark = false,
    circle = false,
    width = "100%",
    height = "100%",
}) => {
    const { theme } = useThemeContext()
    const isDarkTheme = theme === ThemeStates.DARK || dark
    const radius = circle ? "50%" : "2px"

    return (
        <ContentLoader
            speed={1.5}
            width={width}
            height={height}
            backgroundColor={isDarkTheme ? "#1F1F1F" : "#EEEEEE"}
            foregroundColor={isDarkTheme ? "#141414" : "#F6F6F6"}
        >
            <rect
                x="0"
                y="0"
                rx={radius}
                ry={radius}
                width="100%"
                height="100%"
            />
        </ContentLoader>
    )
}

export default LoadingSkeleton

import React from "react"
import { GatsbySSR } from "gatsby"
import { Auth0Provider } from "@auth0/auth0-react"
import { navigate } from "@reach/router"
import { polyfill } from "interweave-ssr"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ApolloProvider from "~apollo/provider"
import { UserProvider } from "~config/user-provider"
import { ResponsiveProvider } from "~config/responsive-provider"
import { ReactQueryProvider } from "~config/react-query-provider"

polyfill()

const WrapRootElement: GatsbySSR["wrapRootElement"] = ({ element }): any => {
    const redirectUri =
        typeof window !== "undefined" ? window.location.origin : ""

    return (
        <Auth0Provider
            domain={process.env.GATSBY_AUTH0_DOMAIN!}
            clientId={process.env.GATSBY_AUTH0_CLIENT_ID!}
            redirectUri={redirectUri}
            onRedirectCallback={(appState) => {
                navigate(
                    appState
                        ? appState.returnTo || ""
                        : window.location.pathname,
                    { replace: true }
                )
            }}
            audience={process.env.GATSBY_AUTH0_AUDIENCE}
            responseType="token id_token"
            scope="openid profile email"
        >
            <ApolloProvider>
                <ReactQueryProvider>
                    <ResponsiveProvider>
                        <UserProvider>{element}</UserProvider>
                    </ResponsiveProvider>
                </ReactQueryProvider>
            </ApolloProvider>
        </Auth0Provider>
    )
}

export default WrapRootElement

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types, prettier/prettier */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AccountNumber: any;
  BigInt: any;
  Byte: any;
  CountryCode: any;
  Cuid: any;
  Currency: any;
  DID: any;
  Date: any;
  DateTime: any;
  Duration: any;
  EmailAddress: any;
  GUID: any;
  HSL: any;
  HSLA: any;
  HexColorCode: any;
  Hexadecimal: any;
  IBAN: any;
  IP: any;
  IPv4: any;
  IPv6: any;
  ISBN: any;
  ISO8601Duration: any;
  JSON: any;
  JSONObject: any;
  JWT: any;
  Latitude: any;
  LocalDate: any;
  LocalEndTime: any;
  LocalTime: any;
  Locale: any;
  Long: any;
  Longitude: any;
  MAC: any;
  NegativeFloat: any;
  NegativeInt: any;
  NonEmptyString: any;
  NonNegativeFloat: any;
  NonNegativeInt: any;
  NonPositiveFloat: any;
  NonPositiveInt: any;
  ObjectID: any;
  PhoneNumber: any;
  Port: any;
  PositiveFloat: any;
  PositiveInt: any;
  PostalCode: any;
  RGB: any;
  RGBA: any;
  RoutingNumber: any;
  SafeInt: any;
  SemVer: any;
  Time: any;
  TimeZone: any;
  Timestamp: any;
  URL: any;
  USCurrency: any;
  UUID: any;
  UnsignedFloat: any;
  UnsignedInt: any;
  UtcOffset: any;
  Void: any;
};

export type AccountOnboardLinkInput = {
  refreshUrl: Scalars['String'];
  returnUrl: Scalars['String'];
};

/** A type that describes an address */
export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  country: Scalars['String'];
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  note?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  street_a: Scalars['String'];
  street_b?: Maybe<Scalars['String']>;
  zip: Scalars['PostalCode'];
};

export type AddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  lat?: InputMaybe<Scalars['Float']>;
  lng?: InputMaybe<Scalars['Float']>;
  note?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  street_a: Scalars['String'];
  street_b?: InputMaybe<Scalars['String']>;
  zip: Scalars['PostalCode'];
};

/** A type that describes an amenity */
export type Amenity = {
  __typename?: 'Amenity';
  category: AmenityCategory;
  created_at: Scalars['Timestamp'];
  description: Scalars['String'];
  icon_url: Scalars['URL'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updated_at: Scalars['Timestamp'];
};

export enum AmenityCategory {
  Basic = 'BASIC',
  Features = 'FEATURES',
  Include = 'INCLUDE'
}

/** A type that describes an available date with slots amount */
export type Availability = {
  __typename?: 'Availability';
  available_slots: Scalars['NonNegativeInt'];
  booked_slots: Scalars['NonNegativeInt'];
  date: Scalars['Timestamp'];
  total_slots: Scalars['NonNegativeInt'];
};

export type AvailabilityInput = {
  booked_slots: Scalars['NonNegativeInt'];
  date: Scalars['Timestamp'];
  total_slots: Scalars['NonNegativeInt'];
};

export enum BedType {
  FullBed = 'FULL_BED',
  Hammock = 'HAMMOCK',
  KingBed = 'KING_BED',
  Other = 'OTHER',
  QueenBed = 'QUEEN_BED',
  SleepingBag = 'SLEEPING_BAG',
  Sofa = 'SOFA',
  TwinBed = 'TWIN_BED'
}

/** A type that describes a booking */
export type Booking = {
  __typename?: 'Booking';
  coupon?: Maybe<Coupon>;
  created_at: Scalars['Timestamp'];
  dates: Array<Scalars['Timestamp']>;
  denial_reasons?: Maybe<Array<DenialReason>>;
  discount?: Maybe<Scalars['String']>;
  end_date: Scalars['Timestamp'];
  guide?: Maybe<Guide>;
  id: Scalars['ID'];
  items: Array<QuoteItem>;
  listing: Listing;
  message_to_outfitter?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  outfitter: Outfitter;
  payments: Array<Payment>;
  rates: Rates;
  service_fee: Scalars['String'];
  start_date: Scalars['Timestamp'];
  state_tax: Scalars['String'];
  status: BookingStatus;
  sub_total: Scalars['String'];
  total: Scalars['String'];
  updated_at: Scalars['Timestamp'];
  user: User;
};

export enum BookingStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Denied = 'DENIED',
  Requested = 'REQUESTED'
}

/** A type that describes a chat conversation */
export type Conversation = {
  __typename?: 'Conversation';
  bookings: Array<Booking>;
  created_at: Scalars['Timestamp'];
  id: Scalars['ID'];
  last_sent?: Maybe<Message>;
  members: Array<User>;
  messages: Array<Message>;
  name: Scalars['String'];
  outfitter: Outfitter;
  updated_at: Scalars['Timestamp'];
};

/** A type that describes a coupon */
export type Coupon = {
  __typename?: 'Coupon';
  amount_off?: Maybe<Scalars['Float']>;
  created_at: Scalars['Timestamp'];
  currency?: Maybe<Scalars['String']>;
  custom_code: Scalars['ID'];
  duration: CouponDuration;
  duration_in_months?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  max_redemptions?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  outfitter?: Maybe<Outfitter>;
  percent_off?: Maybe<Scalars['Float']>;
  redeem_by?: Maybe<Scalars['Timestamp']>;
  updated_at: Scalars['Timestamp'];
};

export enum CouponDuration {
  Forever = 'forever',
  Once = 'once',
  Repeating = 'repeating'
}

export type CreateAmenityInput = {
  category: Scalars['String'];
  description: Scalars['String'];
  icon_url: Scalars['String'];
  name: Scalars['String'];
};

export type CreateBookingInput = {
  message_to_outfitter?: InputMaybe<Scalars['String']>;
  payment_method: Scalars['ID'];
  payment_plan_id: Scalars['ID'];
  quote_id: Scalars['ID'];
};

export type CreateCouponInput = {
  amount_off?: InputMaybe<Scalars['Float']>;
  currency?: InputMaybe<Scalars['String']>;
  custom_code: Scalars['ID'];
  duration: CouponDuration;
  duration_in_months?: InputMaybe<Scalars['Int']>;
  max_redemptions?: InputMaybe<Scalars['Int']>;
  outfitter?: InputMaybe<Scalars['ID']>;
  percent_off?: InputMaybe<Scalars['Float']>;
  redeem_by?: InputMaybe<Scalars['Timestamp']>;
};

export type CreateGoogleReviewInput = {
  author_id?: InputMaybe<Scalars['String']>;
  author_image?: InputMaybe<Scalars['String']>;
  author_link?: InputMaybe<Scalars['String']>;
  author_title?: InputMaybe<Scalars['String']>;
  google_id?: InputMaybe<Scalars['String']>;
  outfitter: Scalars['ID'];
  owner_answer?: InputMaybe<Scalars['String']>;
  owner_answer_timestamp?: InputMaybe<Scalars['PositiveInt']>;
  owner_answer_timestamp_datetime_utc?: InputMaybe<Scalars['String']>;
  published?: Scalars['Boolean'];
  review_datetime_utc?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['String']>;
  review_img_url?: InputMaybe<Scalars['String']>;
  review_likes?: InputMaybe<Scalars['NonNegativeInt']>;
  review_link?: InputMaybe<Scalars['String']>;
  review_rating?: InputMaybe<Scalars['NonNegativeInt']>;
  review_text?: InputMaybe<Scalars['String']>;
  review_timestamp?: InputMaybe<Scalars['NonNegativeInt']>;
  reviews_id?: InputMaybe<Scalars['String']>;
};

export type CreateGuideInput = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['HexColorCode']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  name: Scalars['String'];
  outfitter: Scalars['ID'];
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  years_of_experience?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type CreateLicenseInfoInput = {
  country: Scalars['String'];
  fishing_info_url?: InputMaybe<Scalars['String']>;
  general_info_url?: InputMaybe<Scalars['String']>;
  hunting_info_url?: InputMaybe<Scalars['String']>;
  online_portal_url?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
};

export type CreateListingInput = {
  admin_note?: InputMaybe<Scalars['String']>;
  amenities?: InputMaybe<Array<Scalars['ID']>>;
  availability?: InputMaybe<Array<AvailabilityInput>>;
  booking_instructions?: InputMaybe<Scalars['String']>;
  deposit_percentage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<ListingDuration>;
  featured?: InputMaybe<Scalars['Boolean']>;
  guides?: InputMaybe<Array<Scalars['ID']>>;
  license_required?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<AddressInput>;
  lodging?: InputMaybe<Scalars['ID']>;
  max_guest?: InputMaybe<Scalars['PositiveInt']>;
  min_guest?: InputMaybe<Scalars['PositiveInt']>;
  outfitter: Scalars['ID'];
  photos?: InputMaybe<Array<MediaInput>>;
  pricing?: InputMaybe<PricingInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Array<Scalars['ID']>>;
  time_of_day?: InputMaybe<ListingTimeOfDay>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ListingType>;
};

export type CreateLodgingInput = {
  amenities?: InputMaybe<Array<Scalars['String']>>;
  beds?: InputMaybe<Array<LodgingBedInput>>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outfitter: Scalars['ID'];
  type: LodgingType;
};

export type CreateMessageInput = {
  images?: InputMaybe<Array<MediaInput>>;
  system?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export type CreateNativeReviewInput = {
  booking?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  guide?: InputMaybe<Scalars['ID']>;
  hunt_rating?: InputMaybe<Scalars['PositiveInt']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  listing?: InputMaybe<Scalars['ID']>;
  lodging_rating?: InputMaybe<Scalars['PositiveInt']>;
  outfitter: Scalars['ID'];
  overall_rating: Scalars['PositiveInt'];
  price_rating?: InputMaybe<Scalars['PositiveInt']>;
  reviewer: Scalars['ID'];
};

export type CreateOutfitterInput = {
  about: Scalars['String'];
  admin_note?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<MediaInput>;
  booking_fee_fixed?: InputMaybe<Scalars['String']>;
  booking_fee_rate?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<OutfitterContactInput>>;
  days_before_trip_balance_due?: InputMaybe<Scalars['PositiveInt']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  land_size?: InputMaybe<Scalars['PositiveInt']>;
  licensed?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<AddressInput>;
  max_booking_fee?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  outfitter_fee_fixed?: InputMaybe<Scalars['String']>;
  outfitter_fee_rate?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['ID']>;
  photos?: InputMaybe<Array<MediaInput>>;
  processing_fee_fixed?: InputMaybe<Scalars['String']>;
  processing_fee_rate?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<OutfitterStatus>;
  tax_rate?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  video?: InputMaybe<VideoInput>;
  years_in_operation?: InputMaybe<Scalars['PositiveInt']>;
};

export type CreatePaymentInput = {
  booking: Scalars['ID'];
  booking_fee: Scalars['String'];
  date: Scalars['Timestamp'];
  description?: InputMaybe<Scalars['String']>;
  errors: Array<PaymentErrorInput>;
  id: Scalars['ID'];
  intent_id?: InputMaybe<Scalars['String']>;
  mb_amount: Scalars['String'];
  outfitter: Scalars['ID'];
  outfitter_amount: Scalars['String'];
  payment_method_id: Scalars['String'];
  processing_fee: Scalars['String'];
  sales_tax: Scalars['String'];
  service_fee: Scalars['String'];
  status: PaymentStatus;
  user: Scalars['ID'];
  value: Scalars['String'];
};

export type CreateQuoteInput = {
  coupon_id?: InputMaybe<Scalars['ID']>;
  dates: Array<Scalars['Timestamp']>;
  listing_id: Scalars['ID'];
  pricing_options: Array<QuotePricingOptionInput>;
};

export type CreateSeoPageInput = {
  image?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_image?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  other_listings?: InputMaybe<Array<Scalars['ID']>>;
  other_outfitters?: InputMaybe<Array<Scalars['ID']>>;
  published?: InputMaybe<Scalars['Boolean']>;
  sections?: InputMaybe<Array<SeoPageSectionInput>>;
  slug: Scalars['String'];
  species: Scalars['ID'];
  state: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  top_listings?: InputMaybe<Array<Scalars['ID']>>;
  top_outfitters?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateSpeciesCategoryInput = {
  image?: InputMaybe<MediaInput>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type CreateSpeciesInput = {
  category?: InputMaybe<SpeciesCategoryInput>;
  image?: InputMaybe<MediaInput>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  type: SpeciesType;
};

export type CreateSportsmanReviewInput = {
  booking?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  guide?: InputMaybe<Scalars['ID']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  listing?: InputMaybe<Scalars['ID']>;
  outfitter: Scalars['ID'];
  overall_rating: Scalars['PositiveInt'];
  reviewer?: InputMaybe<Scalars['ID']>;
  sportsman: Scalars['ID'];
};

export type CreateTransactionInput = {
  payment_processor_transaction_id: Scalars['ID'];
  payment_processor_type: Scalars['String'];
};

export type CreateUserInput = {
  avatar_url?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  bookings?: InputMaybe<Array<Scalars['ID']>>;
  date_of_birth?: InputMaybe<Scalars['PositiveInt']>;
  email: Scalars['EmailAddress'];
  favorite_game?: InputMaybe<Array<Scalars['String']>>;
  first_name?: InputMaybe<Scalars['String']>;
  history_listings?: InputMaybe<Array<UserListingHistoryInput>>;
  history_outfitters?: InputMaybe<Array<UserOutfitterHistoryInput>>;
  last_name?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  stripe_connected_account_id?: InputMaybe<Scalars['String']>;
  stripe_user_id?: InputMaybe<Scalars['String']>;
  years_of_experience?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type CreateWidgetInput = {
  button_color?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  google_analytics_id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_external?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['ID']>;
  outfitter: Scalars['ID'];
  pixel_id?: InputMaybe<Scalars['String']>;
  text_color?: InputMaybe<Scalars['String']>;
  trigger_type: WidgetTriggerType;
  widget_name: Scalars['String'];
};

export type CreateWishlistInput = {
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  listings?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
  user: Scalars['ID'];
};

export type DenialReason = {
  __typename?: 'DenialReason';
  content: Scalars['String'];
  id: Scalars['ID'];
};

export type DenialReasonInput = {
  content: Scalars['String'];
  id: Scalars['ID'];
};

export type DraftQuote = {
  __typename?: 'DraftQuote';
  coupon?: Maybe<Coupon>;
  dates: Array<Scalars['Timestamp']>;
  discount?: Maybe<Scalars['String']>;
  items: Array<QuoteItem>;
  listing: Listing;
  payment_plans: Array<QuotePaymentPlan>;
  prices: Array<QuotePrice>;
  total: Scalars['String'];
};

export type GoogleReview = {
  __typename?: 'GoogleReview';
  author_id?: Maybe<Scalars['String']>;
  author_image?: Maybe<Scalars['String']>;
  author_link?: Maybe<Scalars['String']>;
  author_title?: Maybe<Scalars['String']>;
  google_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  outfitter: Outfitter;
  owner_answer?: Maybe<Scalars['String']>;
  owner_answer_timestamp?: Maybe<Scalars['PositiveInt']>;
  owner_answer_timestamp_datetime_utc?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  review_datetime_utc?: Maybe<Scalars['String']>;
  review_id?: Maybe<Scalars['String']>;
  review_img_url?: Maybe<Scalars['String']>;
  review_likes?: Maybe<Scalars['NonNegativeInt']>;
  review_link?: Maybe<Scalars['String']>;
  review_rating?: Maybe<Scalars['NonNegativeInt']>;
  review_text?: Maybe<Scalars['String']>;
  review_timestamp?: Maybe<Scalars['NonNegativeInt']>;
  reviews_id?: Maybe<Scalars['String']>;
};

export type GoogleReviewMetadata = {
  __typename?: 'GoogleReviewMetadata';
  contentful_reviews?: Maybe<Scalars['NonNegativeInt']>;
  id: Scalars['ID'];
  outfitter: Outfitter;
  rating?: Maybe<Scalars['NonNegativeFloat']>;
  reviews?: Maybe<Scalars['NonNegativeInt']>;
  reviews_per_score?: Maybe<GoogleReviewMetadataReviewsPerScore>;
};

export type GoogleReviewMetadataInput = {
  contentful_reviews?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitter: Scalars['ID'];
  rating?: InputMaybe<Scalars['NonNegativeFloat']>;
  reviews?: InputMaybe<Scalars['NonNegativeInt']>;
  reviews_per_score?: InputMaybe<GoogleReviewMetadataReviewsPerScoreInput>;
};

export type GoogleReviewMetadataPopularTime = {
  __typename?: 'GoogleReviewMetadataPopularTime';
  hour?: Maybe<Scalars['NonNegativeFloat']>;
  percentage?: Maybe<Scalars['NonNegativeFloat']>;
  time?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GoogleReviewMetadataPopularTimeParent = {
  __typename?: 'GoogleReviewMetadataPopularTimeParent';
  day?: Maybe<Scalars['NonNegativeInt']>;
  popular_times?: Maybe<Array<Maybe<GoogleReviewMetadataPopularTime>>>;
};

export type GoogleReviewMetadataReviewsPerScore = {
  __typename?: 'GoogleReviewMetadataReviewsPerScore';
  five?: Maybe<Scalars['NonNegativeInt']>;
  four?: Maybe<Scalars['NonNegativeInt']>;
  one?: Maybe<Scalars['NonNegativeInt']>;
  three?: Maybe<Scalars['NonNegativeInt']>;
  two?: Maybe<Scalars['NonNegativeInt']>;
};

export type GoogleReviewMetadataReviewsPerScoreInput = {
  five?: InputMaybe<Scalars['NonNegativeInt']>;
  four?: InputMaybe<Scalars['NonNegativeInt']>;
  one?: InputMaybe<Scalars['NonNegativeInt']>;
  three?: InputMaybe<Scalars['NonNegativeInt']>;
  two?: InputMaybe<Scalars['NonNegativeInt']>;
};

/** A type that describes a guide profile */
export type Guide = {
  __typename?: 'Guide';
  avatar: Scalars['URL'];
  bio: Scalars['String'];
  color?: Maybe<Scalars['HexColorCode']>;
  created_at: Scalars['Timestamp'];
  email?: Maybe<Scalars['EmailAddress']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  outfitter: Outfitter;
  phone?: Maybe<Scalars['PhoneNumber']>;
  updated_at: Scalars['Timestamp'];
  years_of_experience: Scalars['NonNegativeInt'];
};

/** A type that describes hunting & fishing license info of a state */
export type LicenseInfo = {
  __typename?: 'LicenseInfo';
  country: Scalars['String'];
  created_at: Scalars['Timestamp'];
  fishing_info_url?: Maybe<Scalars['String']>;
  general_info_url?: Maybe<Scalars['String']>;
  hunting_info_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  online_portal_url?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updated_at: Scalars['Timestamp'];
};

export type LinkTokenRes = {
  __typename?: 'LinkTokenRes';
  expiration: Scalars['DateTime'];
  link_token: Scalars['String'];
};

/** A type that describes a listing */
export type Listing = {
  __typename?: 'Listing';
  admin_note?: Maybe<Scalars['String']>;
  amenities: Array<Amenity>;
  availability: Array<Availability>;
  booking_instructions?: Maybe<Scalars['String']>;
  cover_image?: Maybe<Scalars['URL']>;
  created_at: Scalars['Timestamp'];
  deposit_percentage: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<ListingDuration>;
  featured?: Maybe<Scalars['Boolean']>;
  guides?: Maybe<Array<Guide>>;
  id: Scalars['ID'];
  license_info?: Maybe<LicenseInfo>;
  license_required?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Address>;
  lodging?: Maybe<Lodging>;
  max_guest?: Maybe<Scalars['PositiveInt']>;
  min_guest?: Maybe<Scalars['PositiveInt']>;
  outfitter: Outfitter;
  owner: User;
  photos: Array<Media>;
  pricing?: Maybe<Pricing>;
  published?: Maybe<Scalars['Boolean']>;
  rule?: Maybe<Scalars['String']>;
  species: Array<Species>;
  time_of_day?: Maybe<ListingTimeOfDay>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<ListingType>;
  updated_at: Scalars['Timestamp'];
};

export enum ListingDuration {
  MultiDay = 'MULTI_DAY',
  SingleDay = 'SINGLE_DAY'
}

export type ListingSearchResults = {
  __typename?: 'ListingSearchResults';
  results: Array<Listing>;
  total: Scalars['NonNegativeInt'];
};

export enum ListingTimeOfDay {
  Afternoon = 'AFTERNOON',
  AllDay = 'ALL_DAY',
  Evening = 'EVENING',
  Morning = 'MORNING'
}

export enum ListingType {
  BlastCast = 'BLAST_CAST',
  Fishing = 'FISHING',
  Hunting = 'HUNTING'
}

/** A type that describes a lodge */
export type Lodging = {
  __typename?: 'Lodging';
  amenities: Array<Scalars['String']>;
  beds: Array<LodgingBed>;
  created_at: Scalars['Timestamp'];
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  outfitter: Outfitter;
  type: LodgingType;
  updated_at: Scalars['Timestamp'];
};

export type LodgingBed = {
  __typename?: 'LodgingBed';
  count: Scalars['NonNegativeInt'];
  type: BedType;
};

export type LodgingBedInput = {
  count: Scalars['NonNegativeInt'];
  type: BedType;
};

export enum LodgingType {
  Bedroom = 'BEDROOM',
  CommonArea = 'COMMON_AREA',
  Other = 'OTHER',
  Tent = 'TENT'
}

export type Media = {
  __typename?: 'Media';
  description?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type MediaInput = {
  description?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** A type that describes a chat message */
export type Message = {
  __typename?: 'Message';
  created_at: Scalars['Timestamp'];
  id: Scalars['ID'];
  images: Array<Media>;
  sender: User;
  system: Scalars['Boolean'];
  text: Scalars['String'];
  updated_at: Scalars['Timestamp'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['Void']>;
  acceptBooking: Booking;
  acceptPaymentRequest: PaymentRequest;
  addListingHistory: User;
  addOutfitterHistory: User;
  applyCoupon: Booking;
  applyQuoteCoupon: Quote;
  assignOutfitterRole?: Maybe<Scalars['Boolean']>;
  changeUserPassword: Scalars['Boolean'];
  createAmenity: Amenity;
  createBooking: Booking;
  createBookingFromWidget: Booking;
  createConversation: Conversation;
  createCoupon: Coupon;
  createGoogleReview: GoogleReview;
  createGuide: Guide;
  createLicenseInfo: LicenseInfo;
  createListing: Listing;
  createLodging: Lodging;
  createNativeReview: NativeReview;
  createOutfitter: Outfitter;
  createPaymentRequest: PaymentRequest;
  createQuote: Quote;
  createSeoPage: SeoPage;
  createSpecies: Species;
  createSpeciesCategory: SpeciesCategory;
  createSportsmanReview: SportsmanReview;
  createUser: User;
  createUserFromAuth0: User;
  createWidget: Widget;
  createWishlist: Wishlist;
  declineBooking: Booking;
  processPublicToken?: Maybe<Scalars['Void']>;
  reassignOutfitterOwner: Outfitter;
  registerUserFromWidget?: Maybe<Scalars['Void']>;
  removeBookingCoupon: Booking;
  removeCoupon?: Maybe<Scalars['Void']>;
  removeGuide?: Maybe<Scalars['Void']>;
  removeListing?: Maybe<Scalars['Void']>;
  removeLodging?: Maybe<Scalars['Void']>;
  removeOutfitter?: Maybe<Scalars['Void']>;
  removeOutfitterRole?: Maybe<Scalars['Boolean']>;
  removeQuoteCoupon: Quote;
  removeSeoPage?: Maybe<Scalars['Void']>;
  removeWidget?: Maybe<Scalars['Void']>;
  removeWishlist?: Maybe<Scalars['Void']>;
  retryFailedPayment: Payment;
  retryFailedPayments: Array<Payment>;
  sendMessage: Message;
  suspendOutfitter: Outfitter;
  unpublishAllGoogleReviews: Array<GoogleReview>;
  unpublishAllNativeReviews: Array<NativeReview>;
  updateAmenity: Amenity;
  updateAvailability: Listing;
  updateBooking: Booking;
  updateBookingPaymentMethod: Array<Payment>;
  updateGoogleReview: GoogleReview;
  updateGuide: Guide;
  updateLicenseInfo: LicenseInfo;
  updateListing: Listing;
  updateLodging: Lodging;
  updateNativeReview: NativeReview;
  updateOutfitter: Outfitter;
  updateOutfitterAdsScore: Outfitter;
  updateOutfitterRankingScore: Outfitter;
  updatePayment: Payment;
  updatePaymentRequest: PaymentRequest;
  updateSeoPage: SeoPage;
  updateSpecies: Species;
  updateSpeciesCategory?: Maybe<SpeciesCategory>;
  updateSportsmanReview: SportsmanReview;
  updateUser: User;
  updateWidget: Widget;
  updateWishlist: Wishlist;
};


export type MutationAcceptBookingArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptPaymentRequestArgs = {
  id: Scalars['ID'];
};


export type MutationAddListingHistoryArgs = {
  data: UserListingHistoryInput;
};


export type MutationAddOutfitterHistoryArgs = {
  data: UserOutfitterHistoryInput;
};


export type MutationApplyCouponArgs = {
  bookingId: Scalars['ID'];
  couponId: Scalars['ID'];
};


export type MutationApplyQuoteCouponArgs = {
  couponCode: Scalars['String'];
  quoteId: Scalars['ID'];
};


export type MutationChangeUserPasswordArgs = {
  newPassword: Scalars['String'];
};


export type MutationCreateAmenityArgs = {
  data: CreateAmenityInput;
};


export type MutationCreateBookingArgs = {
  data: CreateBookingInput;
};


export type MutationCreateBookingFromWidgetArgs = {
  data: CreateBookingInput;
  email: Scalars['EmailAddress'];
};


export type MutationCreateConversationArgs = {
  data: CreateMessageInput;
  outfitterId: Scalars['ID'];
};


export type MutationCreateCouponArgs = {
  data: CreateCouponInput;
};


export type MutationCreateGoogleReviewArgs = {
  data: CreateGoogleReviewInput;
};


export type MutationCreateGuideArgs = {
  data: CreateGuideInput;
};


export type MutationCreateLicenseInfoArgs = {
  data: CreateLicenseInfoInput;
};


export type MutationCreateListingArgs = {
  data: CreateListingInput;
};


export type MutationCreateLodgingArgs = {
  data: CreateLodgingInput;
};


export type MutationCreateNativeReviewArgs = {
  data: CreateNativeReviewInput;
};


export type MutationCreateOutfitterArgs = {
  data: CreateOutfitterInput;
};


export type MutationCreatePaymentRequestArgs = {
  booking: Scalars['ID'];
  data: PaymentRequestInput;
};


export type MutationCreateQuoteArgs = {
  data: CreateQuoteInput;
};


export type MutationCreateSeoPageArgs = {
  data: CreateSeoPageInput;
};


export type MutationCreateSpeciesArgs = {
  data: CreateSpeciesInput;
};


export type MutationCreateSpeciesCategoryArgs = {
  data: CreateSpeciesCategoryInput;
};


export type MutationCreateSportsmanReviewArgs = {
  data: CreateSportsmanReviewInput;
};


export type MutationCreateUserArgs = {
  data: CreateUserInput;
  id: Scalars['ID'];
};


export type MutationCreateUserFromAuth0Args = {
  data: CreateUserInput;
};


export type MutationCreateWidgetArgs = {
  data: CreateWidgetInput;
};


export type MutationCreateWishlistArgs = {
  data: CreateWishlistInput;
};


export type MutationDeclineBookingArgs = {
  denialReasons?: InputMaybe<Array<DenialReasonInput>>;
  id: Scalars['ID'];
};


export type MutationProcessPublicTokenArgs = {
  data: ProcessPublicTokenInput;
};


export type MutationReassignOutfitterOwnerArgs = {
  outfitterId: Scalars['ID'];
  ownerId: Scalars['ID'];
};


export type MutationRegisterUserFromWidgetArgs = {
  bookingId: Scalars['ID'];
  password: Scalars['String'];
};


export type MutationRemoveBookingCouponArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveCouponArgs = {
  id: Scalars['String'];
};


export type MutationRemoveGuideArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveListingArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveLodgingArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveOutfitterArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveQuoteCouponArgs = {
  quoteId: Scalars['ID'];
};


export type MutationRemoveSeoPageArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveWidgetArgs = {
  id: Scalars['ID'];
};


export type MutationRemoveWishlistArgs = {
  id: Scalars['ID'];
};


export type MutationRetryFailedPaymentArgs = {
  paymentId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};


export type MutationRetryFailedPaymentsArgs = {
  bookingId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};


export type MutationSendMessageArgs = {
  conversationId: Scalars['ID'];
  data: CreateMessageInput;
};


export type MutationSuspendOutfitterArgs = {
  id: Scalars['ID'];
};


export type MutationUnpublishAllGoogleReviewsArgs = {
  outfitterId: Scalars['ID'];
};


export type MutationUnpublishAllNativeReviewsArgs = {
  outfitterId: Scalars['ID'];
};


export type MutationUpdateAmenityArgs = {
  data: UpdateAmenityInput;
  id: Scalars['ID'];
};


export type MutationUpdateAvailabilityArgs = {
  availability: AvailabilityInput;
  listingId: Scalars['ID'];
};


export type MutationUpdateBookingArgs = {
  data: UpdateBookingInput;
  id: Scalars['ID'];
};


export type MutationUpdateBookingPaymentMethodArgs = {
  bookingId: Scalars['ID'];
  paymentMethodId: Scalars['ID'];
};


export type MutationUpdateGoogleReviewArgs = {
  data: UpdateGoogleReviewInput;
  id: Scalars['ID'];
};


export type MutationUpdateGuideArgs = {
  data: UpdateGuideInput;
  id: Scalars['ID'];
};


export type MutationUpdateLicenseInfoArgs = {
  data: UpdateLicenseInfoInput;
  id: Scalars['ID'];
};


export type MutationUpdateListingArgs = {
  data: UpdateListingInput;
  id: Scalars['ID'];
};


export type MutationUpdateLodgingArgs = {
  data: UpdateLodgingInput;
  id: Scalars['ID'];
};


export type MutationUpdateNativeReviewArgs = {
  data: UpdateNativeReviewInput;
  id: Scalars['ID'];
};


export type MutationUpdateOutfitterArgs = {
  data: UpdateOutfitterInput;
  id: Scalars['ID'];
};


export type MutationUpdateOutfitterAdsScoreArgs = {
  adsScore: Scalars['PositiveInt'];
  outfitterId: Scalars['ID'];
};


export type MutationUpdateOutfitterRankingScoreArgs = {
  outfitterId: Scalars['ID'];
};


export type MutationUpdatePaymentArgs = {
  data: UpdatePaymentInput;
  id: Scalars['ID'];
};


export type MutationUpdatePaymentRequestArgs = {
  data: UpdatePaymentRequestInput;
  id: Scalars['ID'];
};


export type MutationUpdateSeoPageArgs = {
  data: UpdateSeoPageInput;
  id: Scalars['ID'];
};


export type MutationUpdateSpeciesArgs = {
  data: UpdateSpeciesInput;
  id: Scalars['ID'];
};


export type MutationUpdateSpeciesCategoryArgs = {
  data: UpdateSpeciesCategoryInput;
  id: Scalars['ID'];
};


export type MutationUpdateSportsmanReviewArgs = {
  data: UpdateSportsmanReviewInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
};


export type MutationUpdateWidgetArgs = {
  data: UpdateWidgetInput;
  id: Scalars['ID'];
};


export type MutationUpdateWishlistArgs = {
  data: UpdateWishlistInput;
  id: Scalars['ID'];
};

export type NativeReview = {
  __typename?: 'NativeReview';
  booking?: Maybe<Booking>;
  content: Scalars['String'];
  created_at: Scalars['Timestamp'];
  guide?: Maybe<Guide>;
  hunt_rating?: Maybe<Scalars['PositiveInt']>;
  id: Scalars['ID'];
  is_published: Scalars['Boolean'];
  is_verified: Scalars['Boolean'];
  listing?: Maybe<Listing>;
  lodging_rating?: Maybe<Scalars['PositiveInt']>;
  outfitter: Outfitter;
  overall_rating: Scalars['PositiveInt'];
  owner_answer?: Maybe<Scalars['String']>;
  owner_answer_timestamp?: Maybe<Scalars['PositiveInt']>;
  price_rating?: Maybe<Scalars['PositiveInt']>;
  reviewer: User;
  updated_at: Scalars['Timestamp'];
};

export type NativeReviewMetadata = {
  __typename?: 'NativeReviewMetadata';
  id: Scalars['ID'];
  outfitter: Outfitter;
  rating?: Maybe<Scalars['NonNegativeFloat']>;
  reviews?: Maybe<Scalars['NonNegativeInt']>;
  reviews_per_score?: Maybe<GoogleReviewMetadataReviewsPerScore>;
};

export type NativeReviewMetadataInput = {
  outfitter: Scalars['ID'];
  rating?: InputMaybe<Scalars['NonNegativeFloat']>;
  reviews?: InputMaybe<Scalars['NonNegativeInt']>;
  reviews_per_score?: InputMaybe<GoogleReviewMetadataReviewsPerScoreInput>;
};

/** A type that describes an outfitter */
export type Outfitter = {
  __typename?: 'Outfitter';
  about: Scalars['String'];
  admin_note?: Maybe<Scalars['String']>;
  ads_score?: Maybe<Scalars['PositiveInt']>;
  avatar?: Maybe<Media>;
  booking_fee_fixed: Scalars['String'];
  booking_fee_rate: Scalars['String'];
  contacts?: Maybe<Array<OutfitterContact>>;
  coupons?: Maybe<Array<Coupon>>;
  cover_image?: Maybe<Scalars['URL']>;
  created_at: Scalars['Timestamp'];
  days_before_trip_balance_due: Scalars['PositiveInt'];
  featured: Scalars['Boolean'];
  guides: Array<Guide>;
  id: Scalars['ID'];
  land_size?: Maybe<Scalars['PositiveInt']>;
  licensed: Scalars['Boolean'];
  listings: Array<Listing>;
  location?: Maybe<Address>;
  lodgings: Array<Lodging>;
  max_booking_fee: Scalars['String'];
  name: Scalars['String'];
  outfitter_fee_fixed: Scalars['String'];
  outfitter_fee_rate: Scalars['String'];
  owner: User;
  photos: Array<Media>;
  processing_fee_fixed: Scalars['String'];
  processing_fee_rate: Scalars['String'];
  ranking_score?: Maybe<Scalars['PositiveInt']>;
  slug: Scalars['String'];
  species: Array<Species>;
  status: OutfitterStatus;
  tax_rate: Scalars['String'];
  updated_at: Scalars['Timestamp'];
  verified: Scalars['Boolean'];
  video?: Maybe<Video>;
  years_in_operation: Scalars['PositiveInt'];
};

export type OutfitterContact = {
  __typename?: 'OutfitterContact';
  email?: Maybe<Scalars['EmailAddress']>;
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['PhoneNumber']>;
  snapchat?: Maybe<Scalars['String']>;
  tiktok?: Maybe<Scalars['String']>;
  title: OutfitterContactTitle;
  twitter?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type OutfitterContactInput = {
  email?: InputMaybe<Scalars['EmailAddress']>;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  snapchat?: InputMaybe<Scalars['String']>;
  tiktok?: InputMaybe<Scalars['String']>;
  title: OutfitterContactTitle;
  twitter?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export enum OutfitterContactTitle {
  Guide = 'Guide',
  Manager = 'Manager',
  Marketing = 'Marketing',
  Other = 'Other',
  Owner = 'Owner',
  Sales = 'Sales',
  Secretary = 'Secretary'
}

export type OutfitterSearchResults = {
  __typename?: 'OutfitterSearchResults';
  pages: Scalars['NonNegativeInt'];
  results: Array<Outfitter>;
  total: Scalars['NonNegativeInt'];
};

export type OutfitterSlugResult = {
  __typename?: 'OutfitterSlugResult';
  slug: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type OutfitterStats = {
  __typename?: 'OutfitterStats';
  outfitter: Outfitter;
  stats_by_month: Array<OutfitterStatsByMonth>;
  total_bookings: Scalars['NonNegativeInt'];
  total_revenue: Scalars['String'];
};

export type OutfitterStatsByMonth = {
  __typename?: 'OutfitterStatsByMonth';
  month: Scalars['String'];
  total_bookings: Scalars['NonNegativeInt'];
  total_revenue: Scalars['String'];
  year: Scalars['String'];
};

export enum OutfitterStatus {
  Approved = 'APPROVED',
  PendingApproval = 'PENDING_APPROVAL',
  Suspended = 'SUSPENDED'
}

export type Paging = {
  page: Scalars['Int'];
  size: Scalars['Int'];
};

export type Payment = {
  __typename?: 'Payment';
  booking: Booking;
  booking_fee: Scalars['String'];
  created_at: Scalars['Timestamp'];
  date: Scalars['Timestamp'];
  description?: Maybe<Scalars['String']>;
  errors: Array<PaymentError>;
  id: Scalars['ID'];
  intent_id?: Maybe<Scalars['String']>;
  invoice_hosted_url?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  mb_amount: Scalars['String'];
  outfitter: Outfitter;
  outfitter_amount: Scalars['String'];
  payment_method_id: Scalars['String'];
  processing_fee: Scalars['String'];
  receipt_hosted_url?: Maybe<Scalars['String']>;
  sales_tax: Scalars['String'];
  service_fee: Scalars['String'];
  status: PaymentStatus;
  updated_at: Scalars['Timestamp'];
  user: User;
  value: Scalars['String'];
};

export type PaymentError = {
  __typename?: 'PaymentError';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type PaymentErrorInput = {
  code?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};

export type PaymentItemRequest = {
  __typename?: 'PaymentItemRequest';
  amount: Scalars['String'];
  description: Scalars['String'];
};

export type PaymentItemRequestInput = {
  amount: Scalars['String'];
  description: Scalars['String'];
};

export type PaymentRequest = {
  __typename?: 'PaymentRequest';
  booking: Booking;
  created_at: Scalars['Timestamp'];
  created_by: User;
  errors: Array<PaymentError>;
  id: Scalars['ID'];
  intent_id?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  items: Array<PaymentItemRequest>;
  metadata: Scalars['JSONObject'];
  outfitter: Outfitter;
  sportsman: User;
  status: PaymentRequestStatus;
  updated_at: Scalars['Timestamp'];
};

export type PaymentRequestInput = {
  items: Array<PaymentItemRequestInput>;
};

export enum PaymentRequestStatus {
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Requested = 'REQUESTED',
  Success = 'SUCCESS'
}

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Success = 'SUCCESS'
}

export type PriceSubsection = {
  __typename?: 'PriceSubsection';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Pricing = {
  __typename?: 'Pricing';
  options: Array<PricingOption>;
  type: PricingType;
};

export type PricingInput = {
  options: Array<PricingOptionInput>;
  type: PricingType;
};

export type PricingOption = {
  __typename?: 'PricingOption';
  day_rate: Scalars['String'];
  id: Scalars['ID'];
  max: Scalars['PositiveInt'];
  min: Scalars['NonNegativeInt'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
};

export type PricingOptionInput = {
  day_rate: Scalars['String'];
  id: Scalars['ID'];
  max: Scalars['PositiveInt'];
  min: Scalars['NonNegativeInt'];
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
};

export enum PricingType {
  PerGuest = 'PER_GUEST'
}

export type ProcessPublicTokenInput = {
  accountId: Scalars['String'];
  publicToken: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['Void']>;
  allAmenities: Array<Amenity>;
  /** Query all bookings */
  allBookings: Array<Booking>;
  /** Query all bookings by status */
  allBookingsByStatus: Array<Booking>;
  allConversations: Array<Conversation>;
  allCoupons?: Maybe<Array<Coupon>>;
  allLicenseInfos: Array<LicenseInfo>;
  allListings: Array<Listing>;
  allOutfitters: Array<Outfitter>;
  allSeoPages: Array<SeoPage>;
  allSpecies: Array<Species>;
  allSpeciesCategories?: Maybe<Array<SpeciesCategory>>;
  allWidgets: Array<Widget>;
  amenity: Amenity;
  booking: Booking;
  bookingPayments: Array<Payment>;
  /** Query bookings by a user */
  bookings: Array<Booking>;
  /** Query bookings for a listing */
  bookingsByListing: Array<Booking>;
  /** Query bookings for a listing, time-range */
  bookingsByListingBetweenDates: Array<Booking>;
  /** Query bookings for an outfitter */
  bookingsByOutfitter: Array<Booking>;
  /** Query bookings for an owner */
  bookingsByOwner: Array<Booking>;
  /** Query bookings for an owner with status filter */
  bookingsByOwnerWithStatus: Array<Booking>;
  /** Cancelled bookings by outfitter */
  cancelledBookingsByOutfitter: Array<Booking>;
  /** Completed bookings by outfitter */
  completedBookingsByOutfitter: Array<Booking>;
  conversation: Conversation;
  conversationWithOutfitter?: Maybe<Conversation>;
  coupon?: Maybe<Coupon>;
  couponsByOutfitter?: Maybe<Array<Coupon>>;
  /** Denied bookings by outfitter */
  denialReasons: Array<DenialReason>;
  deniedBookingsByOutfitter: Array<Booking>;
  draftQuote: DraftQuote;
  existingAuthUser: Scalars['Boolean'];
  featuredListings: Array<Listing>;
  featuredOutfitters: Array<Maybe<Outfitter>>;
  googleReview: GoogleReview;
  googleReviewMetadataForOutfitter?: Maybe<GoogleReviewMetadata>;
  googleReviewsForOutfitter: Array<GoogleReview>;
  guide: Guide;
  guides: Array<Guide>;
  guidesByOutfitter: Array<Guide>;
  /** In-Progress bookings by outfitter */
  inProgressBookingsByOutfitter: Array<Booking>;
  isUserUsingPasswordLogin: Scalars['Boolean'];
  licenseInfo: LicenseInfo;
  listing: Listing;
  listings: Array<Listing>;
  listingsByOwner: Array<Listing>;
  listingsByState: Array<Listing>;
  lodging: Lodging;
  lodgings: Array<Lodging>;
  lodgingsByOutfitter: Array<Lodging>;
  nativeReview: NativeReview;
  nativeReviewMetadataForOutfitter?: Maybe<NativeReviewMetadata>;
  nativeReviewsByUser: Array<NativeReview>;
  nativeReviewsForListing: Array<NativeReview>;
  nativeReviewsForOutfitter: Array<NativeReview>;
  outfitter: Outfitter;
  outfitterBySlug: Outfitter;
  outfitterConversations: Array<Conversation>;
  outfitterSlug: OutfitterSlugResult;
  outfitterStats: OutfitterStats;
  outfitterStatsByOwner: Array<OutfitterStats>;
  outfitters: Array<Outfitter>;
  outfittersByOwner: Array<Outfitter>;
  outfittersByStatus: Array<Outfitter>;
  payment: Payment;
  paymentRequest: PaymentRequest;
  paymentsByStatus: Array<Payment>;
  paymentsByUser: Array<Payment>;
  plaidLinkToken: LinkTokenRes;
  publishedGoogleReviewsForOutfitter: Array<GoogleReview>;
  publishedNativeReviewsForListing: Array<NativeReview>;
  publishedNativeReviewsForOutfitter: Array<NativeReview>;
  publishedSeoPages: Array<SeoPage>;
  quote: Quote;
  /** Pending bookings by outfitter */
  requestedBookingsByOutfitter: Array<Booking>;
  searchAllListings: ListingSearchResults;
  searchListings: ListingSearchResults;
  searchOutfitters: OutfitterSearchResults;
  searchOutfittersByStateAndSpecies: OutfitterSearchResults;
  searchSpecies: Array<Species>;
  searchUsers: UsersSearchResults;
  seoPage: SeoPage;
  seoPageBySlug: SeoPage;
  seoPages: Array<SeoPage>;
  species: Species;
  speciesCategory: SpeciesCategory;
  sportsmanReview: SportsmanReview;
  sportsmanReviewMetadataForUser: SportsmanReviewMetadata;
  sportsmanReviewsByOutfitter: Array<SportsmanReview>;
  sportsmanReviewsForUser: Array<SportsmanReview>;
  stripeAccountCheck: Scalars['Boolean'];
  stripeAccountLoginLink: Scalars['URL'];
  stripeAccountOnboardLink: Scalars['URL'];
  unpublishedSeoPages: Array<SeoPage>;
  /** Upcoming bookings by outfitter, time-range */
  upcomingBookingsBetweenDatesByOutfitter: Array<Booking>;
  user: User;
  userConversations: Array<Conversation>;
  userMetadata?: Maybe<UserMetadata>;
  userPaymentsHistory: Array<Payment>;
  userPublic: User;
  users: Array<User>;
  widget: Widget;
  widgetsByOutfitter: Array<Widget>;
  wishlist: Wishlist;
  wishlistsByUser: Array<Wishlist>;
};


export type QueryAllBookingsByStatusArgs = {
  status: BookingStatus;
};


export type QueryAmenityArgs = {
  id: Scalars['ID'];
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingPaymentsArgs = {
  bookingId: Scalars['ID'];
};


export type QueryBookingsByListingArgs = {
  listingId: Scalars['ID'];
};


export type QueryBookingsByListingBetweenDatesArgs = {
  fromDate: Scalars['Timestamp'];
  listingId: Scalars['ID'];
  toDate: Scalars['Timestamp'];
};


export type QueryBookingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryBookingsByOwnerArgs = {
  ownerId: Scalars['ID'];
};


export type QueryBookingsByOwnerWithStatusArgs = {
  ownerId: Scalars['ID'];
  status: BookingStatus;
};


export type QueryCancelledBookingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
  paging?: InputMaybe<Paging>;
};


export type QueryCompletedBookingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
  paging?: InputMaybe<Paging>;
};


export type QueryConversationArgs = {
  id: Scalars['ID'];
};


export type QueryConversationWithOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryCouponArgs = {
  id: Scalars['ID'];
};


export type QueryCouponsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryDeniedBookingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
  paging?: InputMaybe<Paging>;
};


export type QueryDraftQuoteArgs = {
  data: CreateQuoteInput;
};


export type QueryExistingAuthUserArgs = {
  email: Scalars['EmailAddress'];
};


export type QueryFeaturedListingsArgs = {
  size?: InputMaybe<Scalars['PositiveInt']>;
};


export type QueryFeaturedOutfittersArgs = {
  size?: InputMaybe<Scalars['PositiveInt']>;
};


export type QueryGoogleReviewArgs = {
  id: Scalars['ID'];
};


export type QueryGoogleReviewMetadataForOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryGoogleReviewsForOutfitterArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QueryGuideArgs = {
  id: Scalars['ID'];
};


export type QueryGuidesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGuidesByOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryInProgressBookingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
  paging?: InputMaybe<Paging>;
};


export type QueryLicenseInfoArgs = {
  id: Scalars['ID'];
};


export type QueryListingArgs = {
  id: Scalars['ID'];
};


export type QueryListingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryListingsByOwnerArgs = {
  ownerId: Scalars['ID'];
};


export type QueryListingsByStateArgs = {
  state: Scalars['String'];
};


export type QueryLodgingArgs = {
  id: Scalars['ID'];
};


export type QueryLodgingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryLodgingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryNativeReviewArgs = {
  id: Scalars['ID'];
};


export type QueryNativeReviewMetadataForOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryNativeReviewsByUserArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  userId: Scalars['ID'];
};


export type QueryNativeReviewsForListingArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  listingId: Scalars['ID'];
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QueryNativeReviewsForOutfitterArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QueryOutfitterArgs = {
  id: Scalars['ID'];
};


export type QueryOutfitterBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryOutfitterSlugArgs = {
  name: Scalars['String'];
};


export type QueryOutfitterStatsArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryOutfitterStatsByOwnerArgs = {
  ownerId: Scalars['ID'];
};


export type QueryOutfittersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryOutfittersByOwnerArgs = {
  ownerId: Scalars['ID'];
};


export type QueryOutfittersByStatusArgs = {
  status: OutfitterStatus;
};


export type QueryPaymentArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentRequestArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentsByStatusArgs = {
  status: Array<PaymentStatus>;
};


export type QueryPaymentsByUserArgs = {
  userId: Scalars['ID'];
};


export type QueryPublishedGoogleReviewsForOutfitterArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QueryPublishedNativeReviewsForListingArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  listingId: Scalars['ID'];
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QueryPublishedNativeReviewsForOutfitterArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QueryQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryRequestedBookingsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
  paging?: InputMaybe<Paging>;
};


export type QuerySearchAllListingsArgs = {
  featured?: InputMaybe<Scalars['Boolean']>;
  from: Scalars['NonNegativeInt'];
  published?: InputMaybe<Scalars['Boolean']>;
  size?: InputMaybe<Scalars['PositiveInt']>;
  title: Scalars['String'];
};


export type QuerySearchListingsArgs = {
  data: SearchListingsInput;
  from: Scalars['NonNegativeInt'];
};


export type QuerySearchOutfittersArgs = {
  page: Scalars['NonNegativeInt'];
  size: Scalars['PositiveInt'];
  status?: InputMaybe<Scalars['String']>;
  text: Scalars['String'];
};


export type QuerySearchOutfittersByStateAndSpeciesArgs = {
  speciesId: Scalars['ID'];
  state: Scalars['String'];
  text: Scalars['String'];
};


export type QuerySearchSpeciesArgs = {
  data: SearchSpeciesInput;
};


export type QuerySearchUsersArgs = {
  page: Scalars['NonNegativeInt'];
  size: Scalars['PositiveInt'];
  text: Scalars['String'];
};


export type QuerySeoPageArgs = {
  id: Scalars['ID'];
};


export type QuerySeoPageBySlugArgs = {
  slug: Scalars['String'];
};


export type QuerySeoPagesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySpeciesArgs = {
  id: Scalars['ID'];
};


export type QuerySpeciesCategoryArgs = {
  id: Scalars['ID'];
};


export type QuerySportsmanReviewArgs = {
  id: Scalars['ID'];
};


export type QuerySportsmanReviewMetadataForUserArgs = {
  userId: Scalars['ID'];
};


export type QuerySportsmanReviewsByOutfitterArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  offset?: InputMaybe<Scalars['NonNegativeInt']>;
  outfitterId: Scalars['ID'];
};


export type QuerySportsmanReviewsForUserArgs = {
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
  userId: Scalars['ID'];
};


export type QueryStripeAccountOnboardLinkArgs = {
  data: AccountOnboardLinkInput;
};


export type QueryUpcomingBookingsBetweenDatesByOutfitterArgs = {
  fromDate: Scalars['Timestamp'];
  outfitterId: Scalars['ID'];
  paging?: InputMaybe<Paging>;
  toDate: Scalars['Timestamp'];
};


export type QueryUserPublicArgs = {
  id: Scalars['ID'];
};


export type QueryWidgetArgs = {
  id: Scalars['ID'];
};


export type QueryWidgetsByOutfitterArgs = {
  outfitterId: Scalars['ID'];
};


export type QueryWishlistArgs = {
  id: Scalars['ID'];
};


export type QueryWishlistsByUserArgs = {
  userId: Scalars['ID'];
};

export type Quote = {
  __typename?: 'Quote';
  coupon?: Maybe<Coupon>;
  created_at: Scalars['Timestamp'];
  dates: Array<Scalars['Timestamp']>;
  discount?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  items: Array<QuoteItem>;
  listing: Listing;
  payment_plans: Array<QuotePaymentPlan>;
  prices: Array<QuotePrice>;
  total: Scalars['String'];
  updated_at: Scalars['Timestamp'];
};

export type QuoteItem = {
  __typename?: 'QuoteItem';
  name: Scalars['String'];
  option_id: Scalars['ID'];
  price: Scalars['String'];
  quantity: Scalars['PositiveInt'];
  rate: Scalars['String'];
};

export type QuoteItemInput = {
  name: Scalars['String'];
  option_id: Scalars['ID'];
  price: Scalars['String'];
  quantity: Scalars['PositiveInt'];
  rate: Scalars['String'];
};

export type QuotePayment = {
  __typename?: 'QuotePayment';
  booking_fee: Scalars['String'];
  date: Scalars['Timestamp'];
  id: Scalars['ID'];
  mb_amount: Scalars['String'];
  outfitter_amount: Scalars['String'];
  outfitter_fee: Scalars['String'];
  processing_fee: Scalars['String'];
  sales_tax: Scalars['String'];
  service_fee: Scalars['String'];
  value: Scalars['String'];
};

export type QuotePaymentPlan = {
  __typename?: 'QuotePaymentPlan';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  payments: Array<QuotePayment>;
};

export type QuotePrice = {
  __typename?: 'QuotePrice';
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  subsections: Array<PriceSubsection>;
  value: Scalars['String'];
};

export type QuotePricingOptionInput = {
  id: Scalars['ID'];
  quantity: Scalars['PositiveInt'];
};

export type Rates = {
  __typename?: 'Rates';
  booking_fee_fixed: Scalars['String'];
  booking_fee_rate: Scalars['String'];
  max_booking_fee: Scalars['String'];
  max_service_fee: Scalars['String'];
  processing_fee_fixed: Scalars['String'];
  processing_fee_rate: Scalars['String'];
  service_fee_fixed: Scalars['String'];
  service_fee_rate: Scalars['String'];
};

export type RatesInput = {
  booking_fee_fixed: Scalars['String'];
  booking_fee_rate: Scalars['String'];
  max_booking_fee: Scalars['String'];
  max_service_fee: Scalars['String'];
  processing_fee_fixed: Scalars['String'];
  processing_fee_rate: Scalars['String'];
  service_fee_fixed: Scalars['String'];
  service_fee_rate: Scalars['String'];
};

export type SearchListingsInput = {
  amenities?: InputMaybe<Array<Scalars['ID']>>;
  endDate?: InputMaybe<Scalars['NonNegativeInt']>;
  maxGuest?: InputMaybe<Scalars['PositiveInt']>;
  minGuest?: InputMaybe<Scalars['NonNegativeInt']>;
  priceMax?: InputMaybe<Scalars['PositiveInt']>;
  priceMin?: InputMaybe<Scalars['NonNegativeInt']>;
  species?: InputMaybe<Array<Scalars['ID']>>;
  startDate?: InputMaybe<Scalars['NonNegativeInt']>;
  text?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type SearchSpeciesInput = {
  text: Scalars['String'];
};

/** A type that describes a SEO content page */
export type SeoPage = {
  __typename?: 'SeoPage';
  created_at: Scalars['Timestamp'];
  id: Scalars['ID'];
  image: Scalars['URL'];
  license?: Maybe<LicenseInfo>;
  meta_description: Scalars['String'];
  meta_image: Scalars['URL'];
  meta_title: Scalars['String'];
  other_listings: Array<Listing>;
  other_outfitters: Array<Outfitter>;
  published: Scalars['Boolean'];
  sections: Array<SeoPageSection>;
  slug: Scalars['String'];
  species: Species;
  state: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
  top_listings: Array<Listing>;
  top_outfitters: Array<Outfitter>;
  updated_at: Scalars['Timestamp'];
};

export type SeoPageSection = {
  __typename?: 'SeoPageSection';
  content: Scalars['String'];
  heading: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
};

export type SeoPageSectionInput = {
  content: Scalars['String'];
  heading: Scalars['String'];
  icon?: InputMaybe<Scalars['String']>;
};

export type Species = {
  __typename?: 'Species';
  category?: Maybe<SpeciesCategory>;
  created_at: Scalars['Timestamp'];
  id: Scalars['ID'];
  image?: Maybe<Media>;
  keywords?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  type: SpeciesType;
  updated_at: Scalars['Timestamp'];
};

export type SpeciesCategory = {
  __typename?: 'SpeciesCategory';
  created_at: Scalars['Timestamp'];
  id: Scalars['ID'];
  image?: Maybe<Media>;
  keywords?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  species?: Maybe<Array<Species>>;
  updated_at: Scalars['Timestamp'];
};

export type SpeciesCategoryInput = {
  id: Scalars['ID'];
  image?: InputMaybe<MediaInput>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export enum SpeciesType {
  Fishing = 'FISHING',
  Hunting = 'HUNTING'
}

export type SportsmanReview = {
  __typename?: 'SportsmanReview';
  booking?: Maybe<Booking>;
  content: Scalars['String'];
  created_at: Scalars['Timestamp'];
  guide?: Maybe<Guide>;
  id: Scalars['ID'];
  is_published: Scalars['Boolean'];
  is_verified: Scalars['Boolean'];
  listing?: Maybe<Listing>;
  outfitter: Outfitter;
  overall_rating: Scalars['PositiveInt'];
  reviewer?: Maybe<User>;
  sportsman: User;
  updated_at: Scalars['Timestamp'];
};

export type SportsmanReviewMetadata = {
  __typename?: 'SportsmanReviewMetadata';
  id: Scalars['ID'];
  rating?: Maybe<Scalars['NonNegativeFloat']>;
  reviews?: Maybe<Scalars['NonNegativeInt']>;
  reviews_per_score?: Maybe<GoogleReviewMetadataReviewsPerScore>;
  sportsman: User;
};

export type SportsmanReviewMetadataInput = {
  rating?: InputMaybe<Scalars['NonNegativeFloat']>;
  reviews?: InputMaybe<Scalars['NonNegativeInt']>;
  reviews_per_score?: InputMaybe<GoogleReviewMetadataReviewsPerScoreInput>;
  sportsman: Scalars['ID'];
};

export type Transaction = {
  __typename?: 'Transaction';
  payment_processor_transaction_id: Scalars['ID'];
  payment_processor_type: Scalars['String'];
};

export type UpdateAmenityInput = {
  category?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  icon_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateBookingInput = {
  dates?: InputMaybe<Array<Scalars['Timestamp']>>;
  end_date?: InputMaybe<Scalars['Timestamp']>;
  guide?: InputMaybe<Scalars['ID']>;
  items?: InputMaybe<Array<QuoteItemInput>>;
  message_to_outfitter?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  rates?: InputMaybe<RatesInput>;
  service_fee?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['Timestamp']>;
  state_tax?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BookingStatus>;
  sub_total?: InputMaybe<Scalars['String']>;
  total?: InputMaybe<Scalars['String']>;
};

export type UpdateGoogleReviewInput = {
  author_id?: InputMaybe<Scalars['String']>;
  author_image?: InputMaybe<Scalars['String']>;
  author_link?: InputMaybe<Scalars['String']>;
  author_title?: InputMaybe<Scalars['String']>;
  google_id?: InputMaybe<Scalars['String']>;
  outfitter?: InputMaybe<Scalars['ID']>;
  owner_answer?: InputMaybe<Scalars['String']>;
  owner_answer_timestamp?: InputMaybe<Scalars['PositiveInt']>;
  owner_answer_timestamp_datetime_utc?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  review_datetime_utc?: InputMaybe<Scalars['String']>;
  review_id?: InputMaybe<Scalars['String']>;
  review_img_url?: InputMaybe<Scalars['String']>;
  review_likes?: InputMaybe<Scalars['NonNegativeInt']>;
  review_link?: InputMaybe<Scalars['String']>;
  review_rating?: InputMaybe<Scalars['NonNegativeInt']>;
  review_text?: InputMaybe<Scalars['String']>;
  review_timestamp?: InputMaybe<Scalars['NonNegativeInt']>;
  reviews_id?: InputMaybe<Scalars['String']>;
};

export type UpdateGuideInput = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['HexColorCode']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  years_of_experience?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type UpdateLicenseInfoInput = {
  country?: InputMaybe<Scalars['String']>;
  fishing_info_url?: InputMaybe<Scalars['String']>;
  general_info_url?: InputMaybe<Scalars['String']>;
  hunting_info_url?: InputMaybe<Scalars['String']>;
  online_portal_url?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
};

export type UpdateListingInput = {
  admin_note?: InputMaybe<Scalars['String']>;
  amenities?: InputMaybe<Array<Scalars['ID']>>;
  availability?: InputMaybe<Array<AvailabilityInput>>;
  booking_instructions?: InputMaybe<Scalars['String']>;
  deposit_percentage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<ListingDuration>;
  featured?: InputMaybe<Scalars['Boolean']>;
  guides?: InputMaybe<Array<Scalars['ID']>>;
  license_required?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<AddressInput>;
  lodging?: InputMaybe<Scalars['ID']>;
  max_guest?: InputMaybe<Scalars['PositiveInt']>;
  min_guest?: InputMaybe<Scalars['PositiveInt']>;
  owner?: InputMaybe<Scalars['ID']>;
  photos?: InputMaybe<Array<MediaInput>>;
  pricing?: InputMaybe<PricingInput>;
  published?: InputMaybe<Scalars['Boolean']>;
  rule?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Array<Scalars['ID']>>;
  time_of_day?: InputMaybe<ListingTimeOfDay>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ListingType>;
};

export type UpdateLodgingInput = {
  amenities?: InputMaybe<Array<Scalars['String']>>;
  beds?: InputMaybe<Array<LodgingBedInput>>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<LodgingType>;
};

export type UpdateNativeReviewInput = {
  content?: InputMaybe<Scalars['String']>;
  guide?: InputMaybe<Scalars['ID']>;
  hunt_rating?: InputMaybe<Scalars['PositiveInt']>;
  is_published?: InputMaybe<Scalars['Boolean']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  lodging_rating?: InputMaybe<Scalars['PositiveInt']>;
  overall_rating?: InputMaybe<Scalars['PositiveInt']>;
  owner_answer?: InputMaybe<Scalars['String']>;
  price_rating?: InputMaybe<Scalars['PositiveInt']>;
};

export type UpdateOutfitterInput = {
  about?: InputMaybe<Scalars['String']>;
  admin_note?: InputMaybe<Scalars['String']>;
  avatar?: InputMaybe<MediaInput>;
  booking_fee_fixed?: InputMaybe<Scalars['String']>;
  booking_fee_rate?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<OutfitterContactInput>>;
  days_before_trip_balance_due?: InputMaybe<Scalars['PositiveInt']>;
  featured?: InputMaybe<Scalars['Boolean']>;
  land_size?: InputMaybe<Scalars['PositiveInt']>;
  licensed?: InputMaybe<Scalars['Boolean']>;
  location?: InputMaybe<AddressInput>;
  max_booking_fee?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  outfitter_fee_fixed?: InputMaybe<Scalars['String']>;
  outfitter_fee_rate?: InputMaybe<Scalars['String']>;
  photos?: InputMaybe<Array<MediaInput>>;
  processing_fee_fixed?: InputMaybe<Scalars['String']>;
  processing_fee_rate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OutfitterStatus>;
  tax_rate?: InputMaybe<Scalars['String']>;
  verified?: InputMaybe<Scalars['Boolean']>;
  video?: InputMaybe<VideoInput>;
  years_in_operation?: InputMaybe<Scalars['PositiveInt']>;
};

export type UpdatePaymentInput = {
  booking_fee?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Timestamp']>;
  description?: InputMaybe<Scalars['String']>;
  errors?: InputMaybe<Array<PaymentErrorInput>>;
  intent_id?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  mb_amount?: InputMaybe<Scalars['String']>;
  outfitter_amount?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  processing_fee?: InputMaybe<Scalars['String']>;
  sales_tax?: InputMaybe<Scalars['String']>;
  service_fee?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentStatus>;
  value?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentRequestInput = {
  items: Array<PaymentItemRequestInput>;
};

export type UpdateSeoPageInput = {
  image?: InputMaybe<Scalars['String']>;
  meta_description?: InputMaybe<Scalars['String']>;
  meta_image?: InputMaybe<Scalars['String']>;
  meta_title?: InputMaybe<Scalars['String']>;
  other_listings?: InputMaybe<Array<Scalars['ID']>>;
  other_outfitters?: InputMaybe<Array<Scalars['ID']>>;
  published?: InputMaybe<Scalars['Boolean']>;
  sections?: InputMaybe<Array<SeoPageSectionInput>>;
  slug?: InputMaybe<Scalars['String']>;
  species?: InputMaybe<Scalars['ID']>;
  state?: InputMaybe<Scalars['String']>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  top_listings?: InputMaybe<Array<Scalars['ID']>>;
  top_outfitters?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateSpeciesCategoryInput = {
  image?: InputMaybe<MediaInput>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateSpeciesInput = {
  category?: InputMaybe<SpeciesCategoryInput>;
  image?: InputMaybe<MediaInput>;
  keywords?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<SpeciesType>;
};

export type UpdateSportsmanReviewInput = {
  content?: InputMaybe<Scalars['String']>;
  guide?: InputMaybe<Scalars['ID']>;
  is_published?: InputMaybe<Scalars['Boolean']>;
  is_verified?: InputMaybe<Scalars['Boolean']>;
  overall_rating?: InputMaybe<Scalars['PositiveInt']>;
};

export type UpdateTransactionInput = {
  payment_processor_transaction_id?: InputMaybe<Scalars['ID']>;
  payment_processor_type?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  avatar_url?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  date_of_birth?: InputMaybe<Scalars['PositiveInt']>;
  email?: InputMaybe<Scalars['EmailAddress']>;
  favorite_game?: InputMaybe<Array<Scalars['String']>>;
  first_name?: InputMaybe<Scalars['String']>;
  history_listings?: InputMaybe<Array<UserListingHistoryInput>>;
  history_outfitters?: InputMaybe<Array<UserOutfitterHistoryInput>>;
  last_name?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['PhoneNumber']>;
  socials?: InputMaybe<UserSocialsInput>;
  stripe_connected_account_id?: InputMaybe<Scalars['String']>;
  stripe_user_id?: InputMaybe<Scalars['String']>;
  years_of_experience?: InputMaybe<Scalars['NonNegativeInt']>;
};

export type UpdateWidgetInput = {
  button_color?: InputMaybe<Scalars['String']>;
  button_text?: InputMaybe<Scalars['String']>;
  google_analytics_id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_external?: InputMaybe<Scalars['Boolean']>;
  listing_id?: InputMaybe<Scalars['ID']>;
  pixel_id?: InputMaybe<Scalars['String']>;
  text_color?: InputMaybe<Scalars['String']>;
  trigger_type?: InputMaybe<WidgetTriggerType>;
  widget_name?: InputMaybe<Scalars['String']>;
};

export type UpdateWishlistInput = {
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  listings?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

/** A type that describes a user */
export type User = {
  __typename?: 'User';
  avatar_url?: Maybe<Scalars['URL']>;
  bio: Scalars['String'];
  bookings: Array<Booking>;
  created_at: Scalars['Timestamp'];
  date_of_birth?: Maybe<Scalars['Timestamp']>;
  email: Scalars['EmailAddress'];
  favorite_game: Array<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  history_listings: Array<UserListingHistory>;
  history_outfitters: Array<UserOutfitterHistory>;
  id: Scalars['ID'];
  last_name?: Maybe<Scalars['String']>;
  location: Scalars['String'];
  phone?: Maybe<Scalars['PhoneNumber']>;
  settings?: Maybe<UserSetting>;
  socials?: Maybe<UserSocials>;
  stripe_connected_account_id?: Maybe<Scalars['String']>;
  stripe_user_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['Timestamp'];
  years_of_experience?: Maybe<Scalars['NonNegativeInt']>;
};

export type UserListingHistory = {
  __typename?: 'UserListingHistory';
  date: Scalars['Timestamp'];
  listing: Listing;
};

export type UserListingHistoryInput = {
  listing: Scalars['ID'];
};

export type UserMetadata = {
  __typename?: 'UserMetadata';
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  family_name?: Maybe<Scalars['String']>;
  given_name?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  phone_verified?: Maybe<Scalars['Boolean']>;
  picture?: Maybe<Scalars['String']>;
};

export type UserOutfitterHistory = {
  __typename?: 'UserOutfitterHistory';
  date: Scalars['Timestamp'];
  outfitter: Outfitter;
};

export type UserOutfitterHistoryInput = {
  outfitter: Scalars['ID'];
};

export type UserSetting = {
  __typename?: 'UserSetting';
  push_notifications?: Maybe<Scalars['Boolean']>;
  theme: Scalars['String'];
};

export type UserSocials = {
  __typename?: 'UserSocials';
  facebook: Scalars['String'];
  instagram: Scalars['String'];
  twitter: Scalars['String'];
};

export type UserSocialsInput = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
};

export type UsersSearchResults = {
  __typename?: 'UsersSearchResults';
  pages: Scalars['NonNegativeInt'];
  results: Array<User>;
  total: Scalars['NonNegativeInt'];
};

export type Video = {
  __typename?: 'Video';
  hosted?: Maybe<Media>;
  social?: Maybe<Media>;
};

export type VideoInput = {
  hosted?: InputMaybe<MediaInput>;
  social?: InputMaybe<MediaInput>;
};

/** A type that describes a widget */
export type Widget = {
  __typename?: 'Widget';
  button_color?: Maybe<Scalars['String']>;
  button_text?: Maybe<Scalars['String']>;
  created_at: Scalars['Timestamp'];
  google_analytics_id?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_active: Scalars['Boolean'];
  is_external: Scalars['Boolean'];
  listing_id?: Maybe<Scalars['ID']>;
  outfitter: Outfitter;
  pixel_id?: Maybe<Scalars['String']>;
  text_color?: Maybe<Scalars['String']>;
  trigger_type: WidgetTriggerType;
  updated_at: Scalars['Timestamp'];
  widget_name: Scalars['String'];
};

export enum WidgetTriggerType {
  Button = 'BUTTON',
  Inline = 'INLINE',
  Modal = 'MODAL',
  Tab = 'TAB'
}

/** A type that describes a wishlist */
export type Wishlist = {
  __typename?: 'Wishlist';
  created_at: Scalars['Timestamp'];
  id: Scalars['ID'];
  isPrivate: Scalars['Boolean'];
  listings: Array<Listing>;
  name: Scalars['String'];
  updated_at: Scalars['Timestamp'];
  user: User;
};

export type AmenityFieldsFragment = { __typename?: 'Amenity', id: string, name: string, description: string, category: AmenityCategory };

export type AllAmenitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAmenitiesQuery = { __typename?: 'Query', allAmenities: Array<{ __typename?: 'Amenity', id: string, name: string, description: string, category: AmenityCategory }> };

export type BookingFieldsFragment = { __typename?: 'Booking', id: string, dates: Array<any>, status: BookingStatus, sub_total: string, service_fee: string, state_tax: string, total: string, discount?: string | null, created_at: any, user: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null }, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, species: Array<{ __typename?: 'Species', name: string, image?: { __typename?: 'Media', url: string } | null }> }, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, email: any, phone?: any | null, avatar_url?: any | null } }, payments: Array<{ __typename?: 'Payment', date: any, value: string, status: PaymentStatus, invoice_hosted_url?: string | null, receipt_hosted_url?: string | null, errors: Array<{ __typename?: 'PaymentError', code?: string | null, message: string }> }>, coupon?: { __typename?: 'Coupon', id: string, custom_code: string, amount_off?: number | null, percent_off?: number | null } | null };

export type BookingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BookingQuery = { __typename?: 'Query', booking: { __typename?: 'Booking', id: string, dates: Array<any>, status: BookingStatus, sub_total: string, service_fee: string, state_tax: string, total: string, discount?: string | null, created_at: any, user: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null }, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, species: Array<{ __typename?: 'Species', name: string, image?: { __typename?: 'Media', url: string } | null }> }, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, email: any, phone?: any | null, avatar_url?: any | null } }, payments: Array<{ __typename?: 'Payment', date: any, value: string, status: PaymentStatus, invoice_hosted_url?: string | null, receipt_hosted_url?: string | null, errors: Array<{ __typename?: 'PaymentError', code?: string | null, message: string }> }>, coupon?: { __typename?: 'Coupon', id: string, custom_code: string, amount_off?: number | null, percent_off?: number | null } | null } };

export type BookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type BookingsQuery = { __typename?: 'Query', bookings: Array<{ __typename?: 'Booking', id: string, dates: Array<any>, status: BookingStatus, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null }, outfitter: { __typename?: 'Outfitter', name: string, status: OutfitterStatus } }> };

export type CreateBookingMutationVariables = Exact<{
  data: CreateBookingInput;
}>;


export type CreateBookingMutation = { __typename?: 'Mutation', createBooking: { __typename?: 'Booking', id: string } };

export type ConversationFieldsFragment = { __typename?: 'Conversation', id: string, created_at: any, updated_at: any, members: Array<{ __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null }>, outfitter: { __typename?: 'Outfitter', id: string, name: string, owner: { __typename?: 'User', first_name?: string | null, last_name?: string | null, id: string }, avatar?: { __typename?: 'Media', url: string } | null }, bookings: Array<{ __typename?: 'Booking', id: string }>, last_sent?: { __typename?: 'Message', text: string, created_at: any } | null };

export type ConversationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ConversationsQuery = { __typename?: 'Query', userConversations: Array<{ __typename?: 'Conversation', id: string, created_at: any, updated_at: any, members: Array<{ __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null }>, outfitter: { __typename?: 'Outfitter', id: string, name: string, owner: { __typename?: 'User', first_name?: string | null, last_name?: string | null, id: string }, avatar?: { __typename?: 'Media', url: string } | null }, bookings: Array<{ __typename?: 'Booking', id: string }>, last_sent?: { __typename?: 'Message', text: string, created_at: any } | null }> };

export type ConversationQueryVariables = Exact<{
  conversationId: Scalars['ID'];
}>;


export type ConversationQuery = { __typename?: 'Query', conversation: { __typename?: 'Conversation', id: string, outfitter: { __typename?: 'Outfitter', name: string, location?: { __typename?: 'Address', city: string, state: string, zip: any } | null }, messages: Array<{ __typename?: 'Message', id: string, text: string, system: boolean, created_at: any, updated_at: any, sender: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null }, images: Array<{ __typename?: 'Media', url: string, description?: string | null }> }> } };

export type ConversationWithOutfitterQueryVariables = Exact<{
  outfitterId: Scalars['ID'];
}>;


export type ConversationWithOutfitterQuery = { __typename?: 'Query', conversationWithOutfitter?: { __typename?: 'Conversation', id: string } | null };

export type SendMessageMutationVariables = Exact<{
  conversationId: Scalars['ID'];
  data: CreateMessageInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage: { __typename?: 'Message', id: string } };

export type CreateConversationMutationVariables = Exact<{
  outfitterId: Scalars['ID'];
  data: CreateMessageInput;
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation: { __typename?: 'Conversation', id: string } };

export type ListingFieldsFragment = { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, species: Array<{ __typename?: 'Species', type: SpeciesType, name: string, image?: { __typename?: 'Media', url: string } | null }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, created_at: any, name: string, about: string, avatar?: { __typename?: 'Media', url: string } | null }, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null, bio: string, years_of_experience?: any | null, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null } };

export type ListingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ListingQuery = { __typename?: 'Query', listing: { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, species: Array<{ __typename?: 'Species', type: SpeciesType, name: string, image?: { __typename?: 'Media', url: string } | null }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, created_at: any, name: string, about: string, avatar?: { __typename?: 'Media', url: string } | null }, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null, bio: string, years_of_experience?: any | null, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null } } };

export type ListingsFieldsFragment = { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, avatar?: { __typename?: 'Media', url: string } | null } };

export type ListingSuggestionsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ListingSuggestionsQuery = { __typename?: 'Query', listings: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null }> };

export type SearchListingFieldsFragment = { __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, published?: boolean | null, min_guest?: any | null, max_guest?: any | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, outfitter: { __typename?: 'Outfitter', id: string, name: string, slug: string, created_at: any, avatar?: { __typename?: 'Media', url: string } | null, location?: { __typename?: 'Address', city: string, state: string } | null } };

export type SearchListingsQueryVariables = Exact<{
  data: SearchListingsInput;
  from: Scalars['NonNegativeInt'];
}>;


export type SearchListingsQuery = { __typename?: 'Query', listings: { __typename?: 'ListingSearchResults', total: any, results: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, published?: boolean | null, min_guest?: any | null, max_guest?: any | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, outfitter: { __typename?: 'Outfitter', id: string, name: string, slug: string, created_at: any, avatar?: { __typename?: 'Media', url: string } | null, location?: { __typename?: 'Address', city: string, state: string } | null } }> } };

export type ListingsByStateQueryVariables = Exact<{
  state: Scalars['String'];
}>;


export type ListingsByStateQuery = { __typename?: 'Query', listingsByState: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, title?: string | null, published?: boolean | null, min_guest?: any | null, max_guest?: any | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, outfitter: { __typename?: 'Outfitter', id: string, name: string, slug: string, created_at: any, avatar?: { __typename?: 'Media', url: string } | null, location?: { __typename?: 'Address', city: string, state: string } | null } }> };

export type FeaturedListingsQueryVariables = Exact<{ [key: string]: never; }>;


export type FeaturedListingsQuery = { __typename?: 'Query', featuredListings: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, avatar?: { __typename?: 'Media', url: string } | null } }> };

export type OutfitterFieldsFragment = { __typename?: 'Outfitter', id: string, slug: string, name: string, about: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, type?: ListingType | null, published?: boolean | null, min_guest?: any | null, max_guest?: any | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, photos: Array<{ __typename?: 'Media', url: string }>, video?: { __typename?: 'Video', hosted?: { __typename?: 'Media', url: string } | null, social?: { __typename?: 'Media', url: string } | null } | null, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null, bio: string, years_of_experience?: any | null, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null }, lodgings: Array<{ __typename?: 'Lodging', name: string, description: string, type: LodgingType, beds: Array<{ __typename?: 'LodgingBed', type: BedType, count: any }> }>, guides: Array<{ __typename?: 'Guide', name: string, bio: string, years_of_experience: any, avatar: any }> };

export type AllOutfittersFieldsFragment = { __typename?: 'Outfitter', id: string, slug: string, name: string, verified: boolean, photos: Array<{ __typename?: 'Media', url: string }>, video?: { __typename?: 'Video', hosted?: { __typename?: 'Media', url: string } | null, social?: { __typename?: 'Media', url: string } | null } | null, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> };

export type OutfitterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OutfitterQuery = { __typename?: 'Query', outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, about: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, type?: ListingType | null, published?: boolean | null, min_guest?: any | null, max_guest?: any | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, photos: Array<{ __typename?: 'Media', url: string }>, video?: { __typename?: 'Video', hosted?: { __typename?: 'Media', url: string } | null, social?: { __typename?: 'Media', url: string } | null } | null, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null, bio: string, years_of_experience?: any | null, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null }, lodgings: Array<{ __typename?: 'Lodging', name: string, description: string, type: LodgingType, beds: Array<{ __typename?: 'LodgingBed', type: BedType, count: any }> }>, guides: Array<{ __typename?: 'Guide', name: string, bio: string, years_of_experience: any, avatar: any }> } };

export type GetAllOutfittersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllOutfittersQuery = { __typename?: 'Query', allOutfitters: Array<{ __typename?: 'Outfitter', id: string, slug: string, name: string, verified: boolean, photos: Array<{ __typename?: 'Media', url: string }>, video?: { __typename?: 'Video', hosted?: { __typename?: 'Media', url: string } | null, social?: { __typename?: 'Media', url: string } | null } | null, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> }> };

export type GetOutfittersByStatusQueryVariables = Exact<{
  status: OutfitterStatus;
}>;


export type GetOutfittersByStatusQuery = { __typename?: 'Query', outfittersByStatus: Array<{ __typename?: 'Outfitter', id: string, slug: string, name: string, verified: boolean, photos: Array<{ __typename?: 'Media', url: string }>, video?: { __typename?: 'Video', hosted?: { __typename?: 'Media', url: string } | null, social?: { __typename?: 'Media', url: string } | null } | null, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> }> };

export type HomepageQueryVariables = Exact<{
  outfitterIds: Array<Scalars['ID']> | Scalars['ID'];
  listingIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type HomepageQuery = { __typename?: 'Query', outfitters: Array<{ __typename?: 'Outfitter', id: string, slug: string, name: string, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> }>, listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }> }> };

export type FeaturedOutfitterFieldsFragment = { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> };

export type FeaturedOutfittersQueryVariables = Exact<{
  size?: InputMaybe<Scalars['PositiveInt']>;
}>;


export type FeaturedOutfittersQuery = { __typename?: 'Query', featuredOutfitters: Array<{ __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> } | null> };

export type OutfitterBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type OutfitterBySlugQuery = { __typename?: 'Query', outfitterBySlug: { __typename?: 'Outfitter', id: string, slug: string, name: string, about: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, type?: ListingType | null, published?: boolean | null, min_guest?: any | null, max_guest?: any | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, photos: Array<{ __typename?: 'Media', url: string }>, video?: { __typename?: 'Video', hosted?: { __typename?: 'Media', url: string } | null, social?: { __typename?: 'Media', url: string } | null } | null, owner: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null, bio: string, years_of_experience?: any | null, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null }, lodgings: Array<{ __typename?: 'Lodging', name: string, description: string, type: LodgingType, beds: Array<{ __typename?: 'LodgingBed', type: BedType, count: any }> }>, guides: Array<{ __typename?: 'Guide', name: string, bio: string, years_of_experience: any, avatar: any }> } };

export type SearchOutfittersQueryVariables = Exact<{
  text: Scalars['String'];
  size: Scalars['PositiveInt'];
  page: Scalars['NonNegativeInt'];
}>;


export type SearchOutfittersQuery = { __typename?: 'Query', outfitters: { __typename?: 'OutfitterSearchResults', total: any, pages: any, results: Array<{ __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> }> } };

export type LinkTokenResFieldsFragment = { __typename?: 'LinkTokenRes', link_token: string, expiration: any };

export type GetPlaidLinkTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPlaidLinkTokenQuery = { __typename?: 'Query', plaidLinkToken: { __typename?: 'LinkTokenRes', link_token: string, expiration: any } };

export type ProcessPublicTokenMutationVariables = Exact<{
  data: ProcessPublicTokenInput;
}>;


export type ProcessPublicTokenMutation = { __typename?: 'Mutation', processPublicToken?: any | null };

export type QuoteFieldsFragment = { __typename?: 'Quote', id: string, dates: Array<any>, discount?: string | null, total: string, listing: { __typename?: 'Listing', title?: string | null, type?: ListingType | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }> }, items: Array<{ __typename?: 'QuoteItem', name: string, option_id: string, rate: string, quantity: any, price: string }>, prices: Array<{ __typename?: 'QuotePrice', name: string, value: string, note?: string | null, subsections: Array<{ __typename?: 'PriceSubsection', name: string, value: string }> }>, payment_plans: Array<{ __typename?: 'QuotePaymentPlan', id: string, name?: string | null, note?: string | null, payments: Array<{ __typename?: 'QuotePayment', id: string, date: any, value: string }> }>, coupon?: { __typename?: 'Coupon', id: string, custom_code: string, amount_off?: number | null, percent_off?: number | null } | null };

export type DraftQuoteFieldsFragment = { __typename?: 'DraftQuote', dates: Array<any>, total: string, items: Array<{ __typename?: 'QuoteItem', name: string, option_id: string, rate: string, quantity: any, price: string }>, prices: Array<{ __typename?: 'QuotePrice', name: string, value: string, note?: string | null, subsections: Array<{ __typename?: 'PriceSubsection', name: string, value: string }> }>, payment_plans: Array<{ __typename?: 'QuotePaymentPlan', id: string, name?: string | null, note?: string | null, payments: Array<{ __typename?: 'QuotePayment', id: string, date: any, value: string }> }> };

export type QuoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuoteQuery = { __typename?: 'Query', quote: { __typename?: 'Quote', id: string, dates: Array<any>, discount?: string | null, total: string, listing: { __typename?: 'Listing', title?: string | null, type?: ListingType | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }> }, items: Array<{ __typename?: 'QuoteItem', name: string, option_id: string, rate: string, quantity: any, price: string }>, prices: Array<{ __typename?: 'QuotePrice', name: string, value: string, note?: string | null, subsections: Array<{ __typename?: 'PriceSubsection', name: string, value: string }> }>, payment_plans: Array<{ __typename?: 'QuotePaymentPlan', id: string, name?: string | null, note?: string | null, payments: Array<{ __typename?: 'QuotePayment', id: string, date: any, value: string }> }>, coupon?: { __typename?: 'Coupon', id: string, custom_code: string, amount_off?: number | null, percent_off?: number | null } | null } };

export type DraftQuoteQueryVariables = Exact<{
  data: CreateQuoteInput;
}>;


export type DraftQuoteQuery = { __typename?: 'Query', draftQuote: { __typename?: 'DraftQuote', dates: Array<any>, total: string, items: Array<{ __typename?: 'QuoteItem', name: string, option_id: string, rate: string, quantity: any, price: string }>, prices: Array<{ __typename?: 'QuotePrice', name: string, value: string, note?: string | null, subsections: Array<{ __typename?: 'PriceSubsection', name: string, value: string }> }>, payment_plans: Array<{ __typename?: 'QuotePaymentPlan', id: string, name?: string | null, note?: string | null, payments: Array<{ __typename?: 'QuotePayment', id: string, date: any, value: string }> }> } };

export type CreateQuoteMutationVariables = Exact<{
  data: CreateQuoteInput;
}>;


export type CreateQuoteMutation = { __typename?: 'Mutation', createQuote: { __typename?: 'Quote', id: string } };

export type ApplyQuoteCouponMutationVariables = Exact<{
  quoteId: Scalars['ID'];
  couponCode: Scalars['String'];
}>;


export type ApplyQuoteCouponMutation = { __typename?: 'Mutation', applyQuoteCoupon: { __typename?: 'Quote', id: string, dates: Array<any>, discount?: string | null, total: string, listing: { __typename?: 'Listing', title?: string | null, type?: ListingType | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }> }, items: Array<{ __typename?: 'QuoteItem', name: string, option_id: string, rate: string, quantity: any, price: string }>, prices: Array<{ __typename?: 'QuotePrice', name: string, value: string, note?: string | null, subsections: Array<{ __typename?: 'PriceSubsection', name: string, value: string }> }>, payment_plans: Array<{ __typename?: 'QuotePaymentPlan', id: string, name?: string | null, note?: string | null, payments: Array<{ __typename?: 'QuotePayment', id: string, date: any, value: string }> }>, coupon?: { __typename?: 'Coupon', id: string, custom_code: string, amount_off?: number | null, percent_off?: number | null } | null } };

export type RemoveQuoteCouponMutationVariables = Exact<{
  quoteId: Scalars['ID'];
}>;


export type RemoveQuoteCouponMutation = { __typename?: 'Mutation', removeQuoteCoupon: { __typename?: 'Quote', id: string, dates: Array<any>, discount?: string | null, total: string, listing: { __typename?: 'Listing', title?: string | null, type?: ListingType | null, location?: { __typename?: 'Address', city: string, state: string } | null, photos: Array<{ __typename?: 'Media', url: string }> }, items: Array<{ __typename?: 'QuoteItem', name: string, option_id: string, rate: string, quantity: any, price: string }>, prices: Array<{ __typename?: 'QuotePrice', name: string, value: string, note?: string | null, subsections: Array<{ __typename?: 'PriceSubsection', name: string, value: string }> }>, payment_plans: Array<{ __typename?: 'QuotePaymentPlan', id: string, name?: string | null, note?: string | null, payments: Array<{ __typename?: 'QuotePayment', id: string, date: any, value: string }> }>, coupon?: { __typename?: 'Coupon', id: string, custom_code: string, amount_off?: number | null, percent_off?: number | null } | null } };

export type GoogleReviewFieldsFragment = { __typename?: 'GoogleReview', id: string, published: boolean, google_id?: string | null, reviews_id?: string | null, review_id?: string | null, author_link?: string | null, author_title?: string | null, author_id?: string | null, author_image?: string | null, review_text?: string | null, review_img_url?: string | null, owner_answer?: string | null, owner_answer_timestamp?: any | null, owner_answer_timestamp_datetime_utc?: string | null, review_link?: string | null, review_rating?: any | null, review_timestamp?: any | null, review_datetime_utc?: string | null, review_likes?: any | null };

export type GoogleReviewMetadataFieldsFragment = { __typename?: 'GoogleReviewMetadata', id: string, rating?: any | null, reviews?: any | null, reviews_per_score?: { __typename?: 'GoogleReviewMetadataReviewsPerScore', one?: any | null, two?: any | null, three?: any | null, four?: any | null, five?: any | null } | null };

export type NativeReviewFieldsFragment = { __typename?: 'NativeReview', id: string, content: string, overall_rating: any, hunt_rating?: any | null, lodging_rating?: any | null, price_rating?: any | null, owner_answer?: string | null, owner_answer_timestamp?: any | null, is_published: boolean, is_verified: boolean, created_at: any, updated_at: any, guide?: { __typename?: 'Guide', name: string, bio: string, years_of_experience: any, avatar: any } | null, reviewer: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null } };

export type NativeReviewMetadataFieldsFragment = { __typename?: 'NativeReviewMetadata', id: string, rating?: any | null, reviews?: any | null, reviews_per_score?: { __typename?: 'GoogleReviewMetadataReviewsPerScore', one?: any | null, two?: any | null, three?: any | null, four?: any | null, five?: any | null } | null };

export type GoogleReviewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GoogleReviewQuery = { __typename?: 'Query', googleReview: { __typename?: 'GoogleReview', id: string, published: boolean, google_id?: string | null, reviews_id?: string | null, review_id?: string | null, author_link?: string | null, author_title?: string | null, author_id?: string | null, author_image?: string | null, review_text?: string | null, review_img_url?: string | null, owner_answer?: string | null, owner_answer_timestamp?: any | null, owner_answer_timestamp_datetime_utc?: string | null, review_link?: string | null, review_rating?: any | null, review_timestamp?: any | null, review_datetime_utc?: string | null, review_likes?: any | null } };

export type GoogleReviewMetadataForOutfitterQueryVariables = Exact<{
  outfitterId: Scalars['ID'];
}>;


export type GoogleReviewMetadataForOutfitterQuery = { __typename?: 'Query', googleReviewMetadataForOutfitter?: { __typename?: 'GoogleReviewMetadata', id: string, rating?: any | null, reviews?: any | null, reviews_per_score?: { __typename?: 'GoogleReviewMetadataReviewsPerScore', one?: any | null, two?: any | null, three?: any | null, four?: any | null, five?: any | null } | null } | null };

export type PublishedGoogleReviewsForOutfitterQueryVariables = Exact<{
  outfitterId: Scalars['ID'];
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
}>;


export type PublishedGoogleReviewsForOutfitterQuery = { __typename?: 'Query', publishedGoogleReviewsForOutfitter: Array<{ __typename?: 'GoogleReview', id: string, published: boolean, google_id?: string | null, reviews_id?: string | null, review_id?: string | null, author_link?: string | null, author_title?: string | null, author_id?: string | null, author_image?: string | null, review_text?: string | null, review_img_url?: string | null, owner_answer?: string | null, owner_answer_timestamp?: any | null, owner_answer_timestamp_datetime_utc?: string | null, review_link?: string | null, review_rating?: any | null, review_timestamp?: any | null, review_datetime_utc?: string | null, review_likes?: any | null }> };

export type NativeReviewMetadataForOutfitterQueryVariables = Exact<{
  outfitterId: Scalars['ID'];
}>;


export type NativeReviewMetadataForOutfitterQuery = { __typename?: 'Query', nativeReviewMetadataForOutfitter?: { __typename?: 'NativeReviewMetadata', id: string, rating?: any | null, reviews?: any | null, reviews_per_score?: { __typename?: 'GoogleReviewMetadataReviewsPerScore', one?: any | null, two?: any | null, three?: any | null, four?: any | null, five?: any | null } | null } | null };

export type PublishedNativeReviewsForOutfitterQueryVariables = Exact<{
  outfitterId: Scalars['ID'];
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
}>;


export type PublishedNativeReviewsForOutfitterQuery = { __typename?: 'Query', publishedNativeReviewsForOutfitter: Array<{ __typename?: 'NativeReview', id: string, content: string, overall_rating: any, hunt_rating?: any | null, lodging_rating?: any | null, price_rating?: any | null, owner_answer?: string | null, owner_answer_timestamp?: any | null, is_published: boolean, is_verified: boolean, created_at: any, updated_at: any, guide?: { __typename?: 'Guide', name: string, bio: string, years_of_experience: any, avatar: any } | null, reviewer: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null } }> };

export type PublishedNativeReviewsForListingQueryVariables = Exact<{
  listingId: Scalars['ID'];
  outfitterId: Scalars['ID'];
  limit?: InputMaybe<Scalars['NonNegativeInt']>;
}>;


export type PublishedNativeReviewsForListingQuery = { __typename?: 'Query', publishedNativeReviewsForListing: Array<{ __typename?: 'NativeReview', id: string, content: string, overall_rating: any, hunt_rating?: any | null, lodging_rating?: any | null, price_rating?: any | null, owner_answer?: string | null, owner_answer_timestamp?: any | null, is_published: boolean, is_verified: boolean, created_at: any, updated_at: any, guide?: { __typename?: 'Guide', name: string, bio: string, years_of_experience: any, avatar: any } | null, reviewer: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, avatar_url?: any | null } }> };

export type SeoPageFieldsFragment = { __typename?: 'SeoPage', id: string, title: string, subtitle: string, image: any, slug: string, meta_title: string, meta_description: string, meta_image: any, state: string, created_at: any, updated_at: any, top_listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, cover_image?: any | null, amenities: Array<{ __typename?: 'Amenity', id: string, name: string, icon_url: any }>, pricing?: { __typename?: 'Pricing', options: Array<{ __typename?: 'PricingOption', id: string, day_rate: string }> } | null }>, top_outfitters: Array<{ __typename?: 'Outfitter', id: string, name: string, slug: string, cover_image?: any | null, avatar?: { __typename?: 'Media', url: string } | null, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> }>, other_listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, cover_image?: any | null, pricing?: { __typename?: 'Pricing', options: Array<{ __typename?: 'PricingOption', id: string, day_rate: string }> } | null }>, species: { __typename?: 'Species', id: string, type: SpeciesType, name: string }, sections: Array<{ __typename?: 'SeoPageSection', heading: string, content: string }> };

export type UnpublishedSeoPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnpublishedSeoPagesQuery = { __typename?: 'Query', unpublishedSeoPages: Array<{ __typename?: 'SeoPage', id: string, title: string, image: any, slug: string, state: string, species: { __typename?: 'Species', id: string, type: SpeciesType, name: string } }> };

export type SeoPageBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type SeoPageBySlugQuery = { __typename?: 'Query', seoPageBySlug: { __typename?: 'SeoPage', id: string, title: string, subtitle: string, image: any, slug: string, meta_title: string, meta_description: string, meta_image: any, state: string, created_at: any, updated_at: any, top_listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, cover_image?: any | null, amenities: Array<{ __typename?: 'Amenity', id: string, name: string, icon_url: any }>, pricing?: { __typename?: 'Pricing', options: Array<{ __typename?: 'PricingOption', id: string, day_rate: string }> } | null }>, top_outfitters: Array<{ __typename?: 'Outfitter', id: string, name: string, slug: string, cover_image?: any | null, avatar?: { __typename?: 'Media', url: string } | null, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> }>, other_listings: Array<{ __typename?: 'Listing', id: string, title?: string | null, cover_image?: any | null, pricing?: { __typename?: 'Pricing', options: Array<{ __typename?: 'PricingOption', id: string, day_rate: string }> } | null }>, species: { __typename?: 'Species', id: string, type: SpeciesType, name: string }, sections: Array<{ __typename?: 'SeoPageSection', heading: string, content: string }> } };

export type GetPublishedSeoPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublishedSeoPagesQuery = { __typename?: 'Query', publishedSeoPages: Array<{ __typename?: 'SeoPage', id: string, title: string, slug: string }> };

export type SearchSpeciesFieldsFragment = { __typename?: 'Species', id: string, type: SpeciesType, name: string };

export type AllSpeciesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllSpeciesQuery = { __typename?: 'Query', allSpecies: Array<{ __typename?: 'Species', id: string, type: SpeciesType, name: string }> };

export type SearchSpeciesQueryVariables = Exact<{
  data: SearchSpeciesInput;
}>;


export type SearchSpeciesQuery = { __typename?: 'Query', searchSpecies: Array<{ __typename?: 'Species', id: string, type: SpeciesType, name: string }> };

export type UserFieldsFragment = { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, email: any, phone?: any | null, bio: string, location: string, avatar_url?: any | null, favorite_game: Array<string>, years_of_experience?: any | null, created_at: any, updated_at: any, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null };

export type UserHistoryFieldsFragment = { __typename?: 'User', history_listings: Array<{ __typename?: 'UserListingHistory', date: any, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, avatar?: { __typename?: 'Media', url: string } | null } } }>, history_outfitters: Array<{ __typename?: 'UserOutfitterHistory', date: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> } }> };

export type UserPaymentsHistoryFieldsFragment = { __typename?: 'Payment', id: string, date: any, value: string, status: PaymentStatus, receipt_hosted_url?: string | null, booking: { __typename?: 'Booking', id: string } };

export type UserQueryVariables = Exact<{ [key: string]: never; }>;


export type UserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, email: any, phone?: any | null, bio: string, location: string, avatar_url?: any | null, favorite_game: Array<string>, years_of_experience?: any | null, created_at: any, updated_at: any, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null } };

export type UserHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type UserHistoryQuery = { __typename?: 'Query', user: { __typename?: 'User', history_listings: Array<{ __typename?: 'UserListingHistory', date: any, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, avatar?: { __typename?: 'Media', url: string } | null } } }>, history_outfitters: Array<{ __typename?: 'UserOutfitterHistory', date: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> } }> } };

export type UserPaymentsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserPaymentsQuery = { __typename?: 'Query', userPaymentsHistory: Array<{ __typename?: 'Payment', id: string, date: any, value: string, status: PaymentStatus, receipt_hosted_url?: string | null, booking: { __typename?: 'Booking', id: string } }> };

export type IsUserUsingPasswordLoginQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUserUsingPasswordLoginQuery = { __typename?: 'Query', isUserUsingPasswordLogin: boolean };

export type UpdateUserMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, first_name?: string | null, last_name?: string | null, email: any, phone?: any | null, bio: string, location: string, avatar_url?: any | null, favorite_game: Array<string>, years_of_experience?: any | null, created_at: any, updated_at: any, socials?: { __typename?: 'UserSocials', facebook: string, instagram: string, twitter: string } | null } };

export type AddListingHistoryMutationVariables = Exact<{
  data: UserListingHistoryInput;
}>;


export type AddListingHistoryMutation = { __typename?: 'Mutation', addListingHistory: { __typename?: 'User', history_listings: Array<{ __typename?: 'UserListingHistory', date: any, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, avatar?: { __typename?: 'Media', url: string } | null } } }>, history_outfitters: Array<{ __typename?: 'UserOutfitterHistory', date: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> } }> } };

export type AddOutfitterHistoryMutationVariables = Exact<{
  data: UserOutfitterHistoryInput;
}>;


export type AddOutfitterHistoryMutation = { __typename?: 'Mutation', addOutfitterHistory: { __typename?: 'User', history_listings: Array<{ __typename?: 'UserListingHistory', date: any, listing: { __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, min_guest?: any | null, max_guest?: any | null, license_required?: boolean | null, amenities: Array<{ __typename?: 'Amenity', category: AmenityCategory, name: string, icon_url: any }>, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, zip: any, country: string, lat?: number | null, lng?: number | null } | null, availability: Array<{ __typename?: 'Availability', date: any, total_slots: any, booked_slots: any, available_slots: any }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, license_info?: { __typename?: 'LicenseInfo', general_info_url?: string | null, hunting_info_url?: string | null, fishing_info_url?: string | null, online_portal_url?: string | null } | null, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, avatar?: { __typename?: 'Media', url: string } | null } } }>, history_outfitters: Array<{ __typename?: 'UserOutfitterHistory', date: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string, status: OutfitterStatus, avatar?: { __typename?: 'Media', url: string } | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null, listings: Array<{ __typename?: 'Listing', id: string }> } }> } };

export type AssignOutfitterRoleMutationVariables = Exact<{ [key: string]: never; }>;


export type AssignOutfitterRoleMutation = { __typename?: 'Mutation', assignOutfitterRole?: boolean | null };

export type ChangeUserPasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
}>;


export type ChangeUserPasswordMutation = { __typename?: 'Mutation', changeUserPassword: boolean };

export type GetUserBookingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserBookingsQuery = { __typename?: 'Query', bookings: Array<{ __typename?: 'Booking', id: string, dates: Array<any>, status: BookingStatus, listing: { __typename?: 'Listing', id: string, title?: string | null, type?: ListingType | null, photos: Array<{ __typename?: 'Media', url: string }>, location?: { __typename?: 'Address', city: string, state: string } | null } }> };

export type WishlistFieldsFragment = { __typename?: 'Wishlist', id: string, name: string, user: { __typename?: 'User', id: string }, listings: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, rule?: string | null, min_guest?: any | null, max_guest?: any | null, duration?: ListingDuration | null, license_required?: boolean | null, admin_note?: string | null, featured?: boolean | null, created_at: any, updated_at: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string }, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, country: string, lat?: number | null, lng?: number | null, zip: any } | null, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, amenities: Array<{ __typename?: 'Amenity', id: string }>, species: Array<{ __typename?: 'Species', id: string }> }> };

export type WishlistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WishlistQuery = { __typename?: 'Query', wishlist: { __typename?: 'Wishlist', id: string, name: string, user: { __typename?: 'User', id: string }, listings: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, rule?: string | null, min_guest?: any | null, max_guest?: any | null, duration?: ListingDuration | null, license_required?: boolean | null, admin_note?: string | null, featured?: boolean | null, created_at: any, updated_at: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string }, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, country: string, lat?: number | null, lng?: number | null, zip: any } | null, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, amenities: Array<{ __typename?: 'Amenity', id: string }>, species: Array<{ __typename?: 'Species', id: string }> }> } };

export type WishlistsByUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type WishlistsByUserQuery = { __typename?: 'Query', wishlistsByUser: Array<{ __typename?: 'Wishlist', id: string, name: string, user: { __typename?: 'User', id: string }, listings: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, rule?: string | null, min_guest?: any | null, max_guest?: any | null, duration?: ListingDuration | null, license_required?: boolean | null, admin_note?: string | null, featured?: boolean | null, created_at: any, updated_at: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string }, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, country: string, lat?: number | null, lng?: number | null, zip: any } | null, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, amenities: Array<{ __typename?: 'Amenity', id: string }>, species: Array<{ __typename?: 'Species', id: string }> }> }> };

export type UpdateWishlistMutationVariables = Exact<{
  id: Scalars['ID'];
  data: UpdateWishlistInput;
}>;


export type UpdateWishlistMutation = { __typename?: 'Mutation', updateWishlist: { __typename?: 'Wishlist', id: string, name: string, user: { __typename?: 'User', id: string }, listings: Array<{ __typename?: 'Listing', id: string, type?: ListingType | null, published?: boolean | null, title?: string | null, description?: string | null, rule?: string | null, min_guest?: any | null, max_guest?: any | null, duration?: ListingDuration | null, license_required?: boolean | null, admin_note?: string | null, featured?: boolean | null, created_at: any, updated_at: any, outfitter: { __typename?: 'Outfitter', id: string, slug: string, name: string }, location?: { __typename?: 'Address', street_a: string, street_b?: string | null, city: string, state: string, country: string, lat?: number | null, lng?: number | null, zip: any } | null, photos: Array<{ __typename?: 'Media', url: string, description?: string | null }>, pricing?: { __typename?: 'Pricing', type: PricingType, options: Array<{ __typename?: 'PricingOption', id: string, name: string, day_rate: string, min: any, max: any }> } | null, amenities: Array<{ __typename?: 'Amenity', id: string }>, species: Array<{ __typename?: 'Species', id: string }> }> } };

export type CreateWishlistMutationVariables = Exact<{
  data: CreateWishlistInput;
}>;


export type CreateWishlistMutation = { __typename?: 'Mutation', createWishlist: { __typename?: 'Wishlist', id: string } };

export type RemoveWishlistMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveWishlistMutation = { __typename?: 'Mutation', removeWishlist?: any | null };

export const AmenityFieldsFragmentDoc = gql`
    fragment amenityFields on Amenity {
  id
  name
  description
  category
}
    `;
export const BookingFieldsFragmentDoc = gql`
    fragment BookingFields on Booking {
  id
  user {
    id
    first_name
    last_name
    avatar_url
  }
  listing {
    id
    type
    title
    photos {
      url
    }
    location {
      street_a
      street_b
      city
      state
      zip
      country
      lat
      lng
    }
    species {
      name
      image {
        url
      }
    }
  }
  outfitter {
    id
    slug
    name
    avatar {
      url
    }
    owner {
      id
      first_name
      last_name
      email
      phone
      avatar_url
    }
    status
  }
  dates
  status
  sub_total
  service_fee
  state_tax
  total
  sub_total
  payments {
    date
    value
    status
    invoice_hosted_url
    receipt_hosted_url
    errors {
      code
      message
    }
  }
  coupon {
    id
    custom_code
    amount_off
    percent_off
  }
  discount
  created_at
}
    `;
export const ConversationFieldsFragmentDoc = gql`
    fragment ConversationFields on Conversation {
  id
  members {
    id
    first_name
    last_name
  }
  outfitter {
    id
    name
    owner {
      first_name
      last_name
      id
    }
    avatar {
      url
    }
  }
  bookings {
    id
  }
  last_sent {
    text
    created_at
  }
  created_at
  updated_at
}
    `;
export const ListingFieldsFragmentDoc = gql`
    fragment ListingFields on Listing {
  id
  type
  published
  title
  description
  min_guest
  max_guest
  amenities {
    category
    name
    icon_url
  }
  species {
    type
    name
    image {
      url
    }
  }
  photos {
    url
    description
  }
  location {
    street_a
    street_b
    city
    state
    zip
    country
    lat
    lng
  }
  availability {
    date
    total_slots
    booked_slots
    available_slots
  }
  pricing {
    type
    options {
      id
      name
      day_rate
      min
      max
    }
  }
  license_required
  license_info {
    general_info_url
    hunting_info_url
    fishing_info_url
    online_portal_url
  }
  outfitter {
    id
    slug
    created_at
    name
    about
    avatar {
      url
    }
  }
  owner {
    id
    first_name
    last_name
    avatar_url
    bio
    years_of_experience
    socials {
      facebook
      instagram
      twitter
    }
  }
}
    `;
export const SearchListingFieldsFragmentDoc = gql`
    fragment SearchListingFields on Listing {
  id
  type
  title
  published
  min_guest
  max_guest
  photos {
    url
  }
  location {
    street_a
    street_b
    city
    state
    zip
    country
    lat
    lng
  }
  pricing {
    type
    options {
      id
      name
      day_rate
      min
      max
    }
  }
  outfitter {
    id
    name
    slug
    avatar {
      url
    }
    location {
      city
      state
    }
    created_at
  }
}
    `;
export const OutfitterFieldsFragmentDoc = gql`
    fragment OutfitterFields on Outfitter {
  id
  slug
  name
  about
  avatar {
    url
  }
  listings {
    id
    title
    type
    published
    min_guest
    max_guest
    location {
      city
      state
    }
    photos {
      url
    }
    pricing {
      type
      options {
        id
        name
        day_rate
        min
        max
      }
    }
  }
  location {
    street_a
    street_b
    city
    state
    zip
    country
    lat
    lng
  }
  photos {
    url
  }
  video {
    hosted {
      url
    }
    social {
      url
    }
  }
  owner {
    id
    first_name
    last_name
    avatar_url
    bio
    years_of_experience
    socials {
      facebook
      instagram
      twitter
    }
  }
  status
  lodgings {
    name
    description
    type
    beds {
      type
      count
    }
  }
  guides {
    name
    bio
    years_of_experience
    avatar
  }
}
    `;
export const AllOutfittersFieldsFragmentDoc = gql`
    fragment allOutfittersFields on Outfitter {
  id
  slug
  name
  photos {
    url
  }
  video {
    hosted {
      url
    }
    social {
      url
    }
  }
  location {
    city
    state
  }
  listings {
    id
  }
  verified
}
    `;
export const LinkTokenResFieldsFragmentDoc = gql`
    fragment LinkTokenResFields on LinkTokenRes {
  link_token
  expiration
}
    `;
export const QuoteFieldsFragmentDoc = gql`
    fragment QuoteFields on Quote {
  id
  dates
  listing {
    title
    type
    location {
      city
      state
    }
    photos {
      url
    }
  }
  items {
    name
    option_id
    rate
    quantity
    price
  }
  prices {
    name
    value
    note
    subsections {
      name
      value
    }
  }
  payment_plans {
    id
    name
    note
    payments {
      id
      date
      value
    }
  }
  coupon {
    id
    custom_code
    amount_off
    percent_off
  }
  discount
  total
}
    `;
export const DraftQuoteFieldsFragmentDoc = gql`
    fragment DraftQuoteFields on DraftQuote {
  dates
  items {
    name
    option_id
    rate
    quantity
    price
  }
  prices {
    name
    value
    note
    subsections {
      name
      value
    }
  }
  payment_plans {
    id
    name
    note
    payments {
      id
      date
      value
    }
  }
  total
}
    `;
export const GoogleReviewFieldsFragmentDoc = gql`
    fragment GoogleReviewFields on GoogleReview {
  id
  published
  google_id
  reviews_id
  review_id
  author_link
  author_title
  author_id
  author_image
  review_text
  review_img_url
  owner_answer
  owner_answer_timestamp
  owner_answer_timestamp_datetime_utc
  review_link
  review_rating
  review_timestamp
  review_datetime_utc
  review_likes
}
    `;
export const GoogleReviewMetadataFieldsFragmentDoc = gql`
    fragment GoogleReviewMetadataFields on GoogleReviewMetadata {
  id
  rating
  reviews
  reviews_per_score {
    one
    two
    three
    four
    five
  }
}
    `;
export const NativeReviewFieldsFragmentDoc = gql`
    fragment NativeReviewFields on NativeReview {
  id
  guide {
    name
    bio
    years_of_experience
    avatar
  }
  reviewer {
    id
    first_name
    last_name
    avatar_url
  }
  content
  overall_rating
  hunt_rating
  lodging_rating
  price_rating
  owner_answer
  owner_answer_timestamp
  is_published
  is_verified
  created_at
  updated_at
}
    `;
export const NativeReviewMetadataFieldsFragmentDoc = gql`
    fragment NativeReviewMetadataFields on NativeReviewMetadata {
  id
  rating
  reviews
  reviews_per_score {
    one
    two
    three
    four
    five
  }
}
    `;
export const SeoPageFieldsFragmentDoc = gql`
    fragment SeoPageFields on SeoPage {
  id
  title
  subtitle
  image
  slug
  meta_title
  meta_description
  meta_image
  state
  top_listings {
    id
    title
    cover_image
    amenities {
      id
      name
      icon_url
    }
    pricing {
      options {
        id
        day_rate
      }
    }
  }
  top_outfitters {
    id
    name
    slug
    cover_image
    avatar {
      url
    }
    location {
      city
      state
    }
    listings {
      id
    }
  }
  other_listings {
    id
    title
    cover_image
    pricing {
      options {
        id
        day_rate
      }
    }
  }
  species {
    id
    type
    name
  }
  sections {
    heading
    content
  }
  created_at
  updated_at
}
    `;
export const SearchSpeciesFieldsFragmentDoc = gql`
    fragment searchSpeciesFields on Species {
  id
  type
  name
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  first_name
  last_name
  email
  phone
  bio
  location
  avatar_url
  socials {
    facebook
    instagram
    twitter
  }
  favorite_game
  years_of_experience
  created_at
  updated_at
}
    `;
export const ListingsFieldsFragmentDoc = gql`
    fragment ListingsFields on Listing {
  id
  type
  published
  title
  description
  min_guest
  max_guest
  amenities {
    category
    name
    icon_url
  }
  photos {
    url
    description
  }
  location {
    street_a
    street_b
    city
    state
    zip
    country
    lat
    lng
  }
  availability {
    date
    total_slots
    booked_slots
    available_slots
  }
  pricing {
    type
    options {
      id
      name
      day_rate
      min
      max
    }
  }
  license_required
  license_info {
    general_info_url
    hunting_info_url
    fishing_info_url
    online_portal_url
  }
  outfitter {
    id
    slug
    name
    avatar {
      url
    }
  }
}
    `;
export const FeaturedOutfitterFieldsFragmentDoc = gql`
    fragment FeaturedOutfitterFields on Outfitter {
  id
  slug
  name
  status
  avatar {
    url
  }
  photos {
    url
  }
  location {
    city
    state
  }
  listings {
    id
  }
}
    `;
export const UserHistoryFieldsFragmentDoc = gql`
    fragment UserHistoryFields on User {
  history_listings {
    date
    listing {
      ...ListingsFields
    }
  }
  history_outfitters {
    date
    outfitter {
      ...FeaturedOutfitterFields
    }
  }
}
    ${ListingsFieldsFragmentDoc}
${FeaturedOutfitterFieldsFragmentDoc}`;
export const UserPaymentsHistoryFieldsFragmentDoc = gql`
    fragment UserPaymentsHistoryFields on Payment {
  id
  date
  value
  status
  receipt_hosted_url
  booking {
    id
  }
}
    `;
export const WishlistFieldsFragmentDoc = gql`
    fragment WishlistFields on Wishlist {
  id
  name
  user {
    id
  }
  listings {
    id
    outfitter {
      id
      slug
      name
    }
    type
    published
    title
    description
    rule
    min_guest
    max_guest
    location {
      street_a
      street_b
      city
      state
      country
      lat
      lng
      zip
    }
    photos {
      url
      description
    }
    pricing {
      type
      options {
        id
        name
        day_rate
        min
        max
      }
    }
    amenities {
      id
    }
    species {
      id
    }
    duration
    license_required
    admin_note
    featured
    created_at
    updated_at
  }
}
    `;
export const AllAmenitiesDocument = gql`
    query allAmenities {
  allAmenities {
    ...amenityFields
  }
}
    ${AmenityFieldsFragmentDoc}`;

/**
 * __useAllAmenitiesQuery__
 *
 * To run a query within a React component, call `useAllAmenitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAmenitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAmenitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAmenitiesQuery(baseOptions?: Apollo.QueryHookOptions<AllAmenitiesQuery, AllAmenitiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAmenitiesQuery, AllAmenitiesQueryVariables>(AllAmenitiesDocument, options);
      }
export function useAllAmenitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAmenitiesQuery, AllAmenitiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAmenitiesQuery, AllAmenitiesQueryVariables>(AllAmenitiesDocument, options);
        }
export type AllAmenitiesQueryHookResult = ReturnType<typeof useAllAmenitiesQuery>;
export type AllAmenitiesLazyQueryHookResult = ReturnType<typeof useAllAmenitiesLazyQuery>;
export type AllAmenitiesQueryResult = Apollo.QueryResult<AllAmenitiesQuery, AllAmenitiesQueryVariables>;
export const BookingDocument = gql`
    query booking($id: ID!) {
  booking(id: $id) {
    ...BookingFields
  }
}
    ${BookingFieldsFragmentDoc}`;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
      }
export function useBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
        }
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const BookingsDocument = gql`
    query bookings {
  bookings {
    id
    listing {
      id
      type
      title
      photos {
        url
      }
      location {
        city
        state
      }
    }
    outfitter {
      name
      status
    }
    dates
    status
  }
}
    `;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBookingsQuery(baseOptions?: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
      }
export function useBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
        }
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const CreateBookingDocument = gql`
    mutation createBooking($data: CreateBookingInput!) {
  createBooking(data: $data) {
    id
  }
}
    `;
export type CreateBookingMutationFn = Apollo.MutationFunction<CreateBookingMutation, CreateBookingMutationVariables>;

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateBookingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBookingMutation, CreateBookingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBookingMutation, CreateBookingMutationVariables>(CreateBookingDocument, options);
      }
export type CreateBookingMutationHookResult = ReturnType<typeof useCreateBookingMutation>;
export type CreateBookingMutationResult = Apollo.MutationResult<CreateBookingMutation>;
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<CreateBookingMutation, CreateBookingMutationVariables>;
export const ConversationsDocument = gql`
    query conversations {
  userConversations {
    ...ConversationFields
  }
}
    ${ConversationFieldsFragmentDoc}`;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const ConversationDocument = gql`
    query conversation($conversationId: ID!) {
  conversation(id: $conversationId) {
    id
    outfitter {
      name
      location {
        city
        state
        zip
      }
    }
    messages {
      id
      sender {
        id
        first_name
        last_name
        avatar_url
      }
      images {
        url
        description
      }
      text
      system
      created_at
      updated_at
    }
  }
}
    `;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const ConversationWithOutfitterDocument = gql`
    query conversationWithOutfitter($outfitterId: ID!) {
  conversationWithOutfitter(outfitterId: $outfitterId) {
    id
  }
}
    `;

/**
 * __useConversationWithOutfitterQuery__
 *
 * To run a query within a React component, call `useConversationWithOutfitterQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationWithOutfitterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationWithOutfitterQuery({
 *   variables: {
 *      outfitterId: // value for 'outfitterId'
 *   },
 * });
 */
export function useConversationWithOutfitterQuery(baseOptions: Apollo.QueryHookOptions<ConversationWithOutfitterQuery, ConversationWithOutfitterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationWithOutfitterQuery, ConversationWithOutfitterQueryVariables>(ConversationWithOutfitterDocument, options);
      }
export function useConversationWithOutfitterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationWithOutfitterQuery, ConversationWithOutfitterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationWithOutfitterQuery, ConversationWithOutfitterQueryVariables>(ConversationWithOutfitterDocument, options);
        }
export type ConversationWithOutfitterQueryHookResult = ReturnType<typeof useConversationWithOutfitterQuery>;
export type ConversationWithOutfitterLazyQueryHookResult = ReturnType<typeof useConversationWithOutfitterLazyQuery>;
export type ConversationWithOutfitterQueryResult = Apollo.QueryResult<ConversationWithOutfitterQuery, ConversationWithOutfitterQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($conversationId: ID!, $data: CreateMessageInput!) {
  sendMessage(conversationId: $conversationId, data: $data) {
    id
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const CreateConversationDocument = gql`
    mutation createConversation($outfitterId: ID!, $data: CreateMessageInput!) {
  createConversation(outfitterId: $outfitterId, data: $data) {
    id
  }
}
    `;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      outfitterId: // value for 'outfitterId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<CreateConversationMutation, CreateConversationMutationVariables>;
export const ListingDocument = gql`
    query listing($id: ID!) {
  listing(id: $id) {
    ...ListingFields
  }
}
    ${ListingFieldsFragmentDoc}`;

/**
 * __useListingQuery__
 *
 * To run a query within a React component, call `useListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useListingQuery(baseOptions: Apollo.QueryHookOptions<ListingQuery, ListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingQuery, ListingQueryVariables>(ListingDocument, options);
      }
export function useListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingQuery, ListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingQuery, ListingQueryVariables>(ListingDocument, options);
        }
export type ListingQueryHookResult = ReturnType<typeof useListingQuery>;
export type ListingLazyQueryHookResult = ReturnType<typeof useListingLazyQuery>;
export type ListingQueryResult = Apollo.QueryResult<ListingQuery, ListingQueryVariables>;
export const ListingSuggestionsDocument = gql`
    query listingSuggestions($ids: [ID!]!) {
  listings(ids: $ids) {
    id
    type
    title
    photos {
      url
    }
    location {
      street_a
      street_b
      city
      state
      zip
      country
      lat
      lng
    }
    pricing {
      type
      options {
        id
        name
        day_rate
        min
        max
      }
    }
  }
}
    `;

/**
 * __useListingSuggestionsQuery__
 *
 * To run a query within a React component, call `useListingSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingSuggestionsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useListingSuggestionsQuery(baseOptions: Apollo.QueryHookOptions<ListingSuggestionsQuery, ListingSuggestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingSuggestionsQuery, ListingSuggestionsQueryVariables>(ListingSuggestionsDocument, options);
      }
export function useListingSuggestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingSuggestionsQuery, ListingSuggestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingSuggestionsQuery, ListingSuggestionsQueryVariables>(ListingSuggestionsDocument, options);
        }
export type ListingSuggestionsQueryHookResult = ReturnType<typeof useListingSuggestionsQuery>;
export type ListingSuggestionsLazyQueryHookResult = ReturnType<typeof useListingSuggestionsLazyQuery>;
export type ListingSuggestionsQueryResult = Apollo.QueryResult<ListingSuggestionsQuery, ListingSuggestionsQueryVariables>;
export const SearchListingsDocument = gql`
    query searchListings($data: SearchListingsInput!, $from: NonNegativeInt!) {
  listings: searchListings(data: $data, from: $from) {
    results {
      ...SearchListingFields
    }
    total
  }
}
    ${SearchListingFieldsFragmentDoc}`;

/**
 * __useSearchListingsQuery__
 *
 * To run a query within a React component, call `useSearchListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchListingsQuery({
 *   variables: {
 *      data: // value for 'data'
 *      from: // value for 'from'
 *   },
 * });
 */
export function useSearchListingsQuery(baseOptions: Apollo.QueryHookOptions<SearchListingsQuery, SearchListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchListingsQuery, SearchListingsQueryVariables>(SearchListingsDocument, options);
      }
export function useSearchListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchListingsQuery, SearchListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchListingsQuery, SearchListingsQueryVariables>(SearchListingsDocument, options);
        }
export type SearchListingsQueryHookResult = ReturnType<typeof useSearchListingsQuery>;
export type SearchListingsLazyQueryHookResult = ReturnType<typeof useSearchListingsLazyQuery>;
export type SearchListingsQueryResult = Apollo.QueryResult<SearchListingsQuery, SearchListingsQueryVariables>;
export const ListingsByStateDocument = gql`
    query listingsByState($state: String!) {
  listingsByState(state: $state) {
    ...SearchListingFields
  }
}
    ${SearchListingFieldsFragmentDoc}`;

/**
 * __useListingsByStateQuery__
 *
 * To run a query within a React component, call `useListingsByStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useListingsByStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListingsByStateQuery({
 *   variables: {
 *      state: // value for 'state'
 *   },
 * });
 */
export function useListingsByStateQuery(baseOptions: Apollo.QueryHookOptions<ListingsByStateQuery, ListingsByStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListingsByStateQuery, ListingsByStateQueryVariables>(ListingsByStateDocument, options);
      }
export function useListingsByStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListingsByStateQuery, ListingsByStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListingsByStateQuery, ListingsByStateQueryVariables>(ListingsByStateDocument, options);
        }
export type ListingsByStateQueryHookResult = ReturnType<typeof useListingsByStateQuery>;
export type ListingsByStateLazyQueryHookResult = ReturnType<typeof useListingsByStateLazyQuery>;
export type ListingsByStateQueryResult = Apollo.QueryResult<ListingsByStateQuery, ListingsByStateQueryVariables>;
export const FeaturedListingsDocument = gql`
    query featuredListings {
  featuredListings {
    ...ListingsFields
  }
}
    ${ListingsFieldsFragmentDoc}`;

/**
 * __useFeaturedListingsQuery__
 *
 * To run a query within a React component, call `useFeaturedListingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedListingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedListingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFeaturedListingsQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedListingsQuery, FeaturedListingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedListingsQuery, FeaturedListingsQueryVariables>(FeaturedListingsDocument, options);
      }
export function useFeaturedListingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedListingsQuery, FeaturedListingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedListingsQuery, FeaturedListingsQueryVariables>(FeaturedListingsDocument, options);
        }
export type FeaturedListingsQueryHookResult = ReturnType<typeof useFeaturedListingsQuery>;
export type FeaturedListingsLazyQueryHookResult = ReturnType<typeof useFeaturedListingsLazyQuery>;
export type FeaturedListingsQueryResult = Apollo.QueryResult<FeaturedListingsQuery, FeaturedListingsQueryVariables>;
export const OutfitterDocument = gql`
    query outfitter($id: ID!) {
  outfitter(id: $id) {
    ...OutfitterFields
  }
}
    ${OutfitterFieldsFragmentDoc}`;

/**
 * __useOutfitterQuery__
 *
 * To run a query within a React component, call `useOutfitterQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutfitterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutfitterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOutfitterQuery(baseOptions: Apollo.QueryHookOptions<OutfitterQuery, OutfitterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutfitterQuery, OutfitterQueryVariables>(OutfitterDocument, options);
      }
export function useOutfitterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutfitterQuery, OutfitterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutfitterQuery, OutfitterQueryVariables>(OutfitterDocument, options);
        }
export type OutfitterQueryHookResult = ReturnType<typeof useOutfitterQuery>;
export type OutfitterLazyQueryHookResult = ReturnType<typeof useOutfitterLazyQuery>;
export type OutfitterQueryResult = Apollo.QueryResult<OutfitterQuery, OutfitterQueryVariables>;
export const GetAllOutfittersDocument = gql`
    query getAllOutfitters {
  allOutfitters {
    ...allOutfittersFields
  }
}
    ${AllOutfittersFieldsFragmentDoc}`;

/**
 * __useGetAllOutfittersQuery__
 *
 * To run a query within a React component, call `useGetAllOutfittersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOutfittersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOutfittersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOutfittersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOutfittersQuery, GetAllOutfittersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOutfittersQuery, GetAllOutfittersQueryVariables>(GetAllOutfittersDocument, options);
      }
export function useGetAllOutfittersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOutfittersQuery, GetAllOutfittersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOutfittersQuery, GetAllOutfittersQueryVariables>(GetAllOutfittersDocument, options);
        }
export type GetAllOutfittersQueryHookResult = ReturnType<typeof useGetAllOutfittersQuery>;
export type GetAllOutfittersLazyQueryHookResult = ReturnType<typeof useGetAllOutfittersLazyQuery>;
export type GetAllOutfittersQueryResult = Apollo.QueryResult<GetAllOutfittersQuery, GetAllOutfittersQueryVariables>;
export const GetOutfittersByStatusDocument = gql`
    query getOutfittersByStatus($status: OutfitterStatus!) {
  outfittersByStatus(status: $status) {
    ...allOutfittersFields
  }
}
    ${AllOutfittersFieldsFragmentDoc}`;

/**
 * __useGetOutfittersByStatusQuery__
 *
 * To run a query within a React component, call `useGetOutfittersByStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutfittersByStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutfittersByStatusQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetOutfittersByStatusQuery(baseOptions: Apollo.QueryHookOptions<GetOutfittersByStatusQuery, GetOutfittersByStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOutfittersByStatusQuery, GetOutfittersByStatusQueryVariables>(GetOutfittersByStatusDocument, options);
      }
export function useGetOutfittersByStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOutfittersByStatusQuery, GetOutfittersByStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOutfittersByStatusQuery, GetOutfittersByStatusQueryVariables>(GetOutfittersByStatusDocument, options);
        }
export type GetOutfittersByStatusQueryHookResult = ReturnType<typeof useGetOutfittersByStatusQuery>;
export type GetOutfittersByStatusLazyQueryHookResult = ReturnType<typeof useGetOutfittersByStatusLazyQuery>;
export type GetOutfittersByStatusQueryResult = Apollo.QueryResult<GetOutfittersByStatusQuery, GetOutfittersByStatusQueryVariables>;
export const HomepageDocument = gql`
    query homepage($outfitterIds: [ID!]!, $listingIds: [ID!]!) {
  outfitters(ids: $outfitterIds) {
    id
    slug
    name
    photos {
      url
    }
    location {
      city
      state
    }
    listings {
      id
    }
  }
  listings(ids: $listingIds) {
    id
    title
    location {
      city
      state
    }
    photos {
      url
    }
  }
}
    `;

/**
 * __useHomepageQuery__
 *
 * To run a query within a React component, call `useHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomepageQuery({
 *   variables: {
 *      outfitterIds: // value for 'outfitterIds'
 *      listingIds: // value for 'listingIds'
 *   },
 * });
 */
export function useHomepageQuery(baseOptions: Apollo.QueryHookOptions<HomepageQuery, HomepageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomepageQuery, HomepageQueryVariables>(HomepageDocument, options);
      }
export function useHomepageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomepageQuery, HomepageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomepageQuery, HomepageQueryVariables>(HomepageDocument, options);
        }
export type HomepageQueryHookResult = ReturnType<typeof useHomepageQuery>;
export type HomepageLazyQueryHookResult = ReturnType<typeof useHomepageLazyQuery>;
export type HomepageQueryResult = Apollo.QueryResult<HomepageQuery, HomepageQueryVariables>;
export const FeaturedOutfittersDocument = gql`
    query featuredOutfitters($size: PositiveInt = 9) {
  featuredOutfitters(size: $size) {
    ...FeaturedOutfitterFields
  }
}
    ${FeaturedOutfitterFieldsFragmentDoc}`;

/**
 * __useFeaturedOutfittersQuery__
 *
 * To run a query within a React component, call `useFeaturedOutfittersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedOutfittersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedOutfittersQuery({
 *   variables: {
 *      size: // value for 'size'
 *   },
 * });
 */
export function useFeaturedOutfittersQuery(baseOptions?: Apollo.QueryHookOptions<FeaturedOutfittersQuery, FeaturedOutfittersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FeaturedOutfittersQuery, FeaturedOutfittersQueryVariables>(FeaturedOutfittersDocument, options);
      }
export function useFeaturedOutfittersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FeaturedOutfittersQuery, FeaturedOutfittersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FeaturedOutfittersQuery, FeaturedOutfittersQueryVariables>(FeaturedOutfittersDocument, options);
        }
export type FeaturedOutfittersQueryHookResult = ReturnType<typeof useFeaturedOutfittersQuery>;
export type FeaturedOutfittersLazyQueryHookResult = ReturnType<typeof useFeaturedOutfittersLazyQuery>;
export type FeaturedOutfittersQueryResult = Apollo.QueryResult<FeaturedOutfittersQuery, FeaturedOutfittersQueryVariables>;
export const OutfitterBySlugDocument = gql`
    query outfitterBySlug($slug: String!) {
  outfitterBySlug(slug: $slug) {
    ...OutfitterFields
  }
}
    ${OutfitterFieldsFragmentDoc}`;

/**
 * __useOutfitterBySlugQuery__
 *
 * To run a query within a React component, call `useOutfitterBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutfitterBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutfitterBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useOutfitterBySlugQuery(baseOptions: Apollo.QueryHookOptions<OutfitterBySlugQuery, OutfitterBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutfitterBySlugQuery, OutfitterBySlugQueryVariables>(OutfitterBySlugDocument, options);
      }
export function useOutfitterBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutfitterBySlugQuery, OutfitterBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutfitterBySlugQuery, OutfitterBySlugQueryVariables>(OutfitterBySlugDocument, options);
        }
export type OutfitterBySlugQueryHookResult = ReturnType<typeof useOutfitterBySlugQuery>;
export type OutfitterBySlugLazyQueryHookResult = ReturnType<typeof useOutfitterBySlugLazyQuery>;
export type OutfitterBySlugQueryResult = Apollo.QueryResult<OutfitterBySlugQuery, OutfitterBySlugQueryVariables>;
export const SearchOutfittersDocument = gql`
    query searchOutfitters($text: String!, $size: PositiveInt!, $page: NonNegativeInt!) {
  outfitters: searchOutfitters(text: $text, size: $size, page: $page) {
    results {
      ...FeaturedOutfitterFields
    }
    total
    pages
  }
}
    ${FeaturedOutfitterFieldsFragmentDoc}`;

/**
 * __useSearchOutfittersQuery__
 *
 * To run a query within a React component, call `useSearchOutfittersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOutfittersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOutfittersQuery({
 *   variables: {
 *      text: // value for 'text'
 *      size: // value for 'size'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSearchOutfittersQuery(baseOptions: Apollo.QueryHookOptions<SearchOutfittersQuery, SearchOutfittersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchOutfittersQuery, SearchOutfittersQueryVariables>(SearchOutfittersDocument, options);
      }
export function useSearchOutfittersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchOutfittersQuery, SearchOutfittersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchOutfittersQuery, SearchOutfittersQueryVariables>(SearchOutfittersDocument, options);
        }
export type SearchOutfittersQueryHookResult = ReturnType<typeof useSearchOutfittersQuery>;
export type SearchOutfittersLazyQueryHookResult = ReturnType<typeof useSearchOutfittersLazyQuery>;
export type SearchOutfittersQueryResult = Apollo.QueryResult<SearchOutfittersQuery, SearchOutfittersQueryVariables>;
export const GetPlaidLinkTokenDocument = gql`
    query getPlaidLinkToken {
  plaidLinkToken {
    ...LinkTokenResFields
  }
}
    ${LinkTokenResFieldsFragmentDoc}`;

/**
 * __useGetPlaidLinkTokenQuery__
 *
 * To run a query within a React component, call `useGetPlaidLinkTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlaidLinkTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlaidLinkTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlaidLinkTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>(GetPlaidLinkTokenDocument, options);
      }
export function useGetPlaidLinkTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>(GetPlaidLinkTokenDocument, options);
        }
export type GetPlaidLinkTokenQueryHookResult = ReturnType<typeof useGetPlaidLinkTokenQuery>;
export type GetPlaidLinkTokenLazyQueryHookResult = ReturnType<typeof useGetPlaidLinkTokenLazyQuery>;
export type GetPlaidLinkTokenQueryResult = Apollo.QueryResult<GetPlaidLinkTokenQuery, GetPlaidLinkTokenQueryVariables>;
export const ProcessPublicTokenDocument = gql`
    mutation processPublicToken($data: ProcessPublicTokenInput!) {
  processPublicToken(data: $data)
}
    `;
export type ProcessPublicTokenMutationFn = Apollo.MutationFunction<ProcessPublicTokenMutation, ProcessPublicTokenMutationVariables>;

/**
 * __useProcessPublicTokenMutation__
 *
 * To run a mutation, you first call `useProcessPublicTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessPublicTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processPublicTokenMutation, { data, loading, error }] = useProcessPublicTokenMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useProcessPublicTokenMutation(baseOptions?: Apollo.MutationHookOptions<ProcessPublicTokenMutation, ProcessPublicTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessPublicTokenMutation, ProcessPublicTokenMutationVariables>(ProcessPublicTokenDocument, options);
      }
export type ProcessPublicTokenMutationHookResult = ReturnType<typeof useProcessPublicTokenMutation>;
export type ProcessPublicTokenMutationResult = Apollo.MutationResult<ProcessPublicTokenMutation>;
export type ProcessPublicTokenMutationOptions = Apollo.BaseMutationOptions<ProcessPublicTokenMutation, ProcessPublicTokenMutationVariables>;
export const QuoteDocument = gql`
    query quote($id: ID!) {
  quote(id: $id) {
    ...QuoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;

/**
 * __useQuoteQuery__
 *
 * To run a query within a React component, call `useQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteQuery(baseOptions: Apollo.QueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
      }
export function useQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
        }
export type QuoteQueryHookResult = ReturnType<typeof useQuoteQuery>;
export type QuoteLazyQueryHookResult = ReturnType<typeof useQuoteLazyQuery>;
export type QuoteQueryResult = Apollo.QueryResult<QuoteQuery, QuoteQueryVariables>;
export const DraftQuoteDocument = gql`
    query draftQuote($data: CreateQuoteInput!) {
  draftQuote(data: $data) {
    ...DraftQuoteFields
  }
}
    ${DraftQuoteFieldsFragmentDoc}`;

/**
 * __useDraftQuoteQuery__
 *
 * To run a query within a React component, call `useDraftQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useDraftQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDraftQuoteQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useDraftQuoteQuery(baseOptions: Apollo.QueryHookOptions<DraftQuoteQuery, DraftQuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DraftQuoteQuery, DraftQuoteQueryVariables>(DraftQuoteDocument, options);
      }
export function useDraftQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DraftQuoteQuery, DraftQuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DraftQuoteQuery, DraftQuoteQueryVariables>(DraftQuoteDocument, options);
        }
export type DraftQuoteQueryHookResult = ReturnType<typeof useDraftQuoteQuery>;
export type DraftQuoteLazyQueryHookResult = ReturnType<typeof useDraftQuoteLazyQuery>;
export type DraftQuoteQueryResult = Apollo.QueryResult<DraftQuoteQuery, DraftQuoteQueryVariables>;
export const CreateQuoteDocument = gql`
    mutation createQuote($data: CreateQuoteInput!) {
  createQuote(data: $data) {
    id
  }
}
    `;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const ApplyQuoteCouponDocument = gql`
    mutation applyQuoteCoupon($quoteId: ID!, $couponCode: String!) {
  applyQuoteCoupon(quoteId: $quoteId, couponCode: $couponCode) {
    ...QuoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;
export type ApplyQuoteCouponMutationFn = Apollo.MutationFunction<ApplyQuoteCouponMutation, ApplyQuoteCouponMutationVariables>;

/**
 * __useApplyQuoteCouponMutation__
 *
 * To run a mutation, you first call `useApplyQuoteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyQuoteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyQuoteCouponMutation, { data, loading, error }] = useApplyQuoteCouponMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useApplyQuoteCouponMutation(baseOptions?: Apollo.MutationHookOptions<ApplyQuoteCouponMutation, ApplyQuoteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyQuoteCouponMutation, ApplyQuoteCouponMutationVariables>(ApplyQuoteCouponDocument, options);
      }
export type ApplyQuoteCouponMutationHookResult = ReturnType<typeof useApplyQuoteCouponMutation>;
export type ApplyQuoteCouponMutationResult = Apollo.MutationResult<ApplyQuoteCouponMutation>;
export type ApplyQuoteCouponMutationOptions = Apollo.BaseMutationOptions<ApplyQuoteCouponMutation, ApplyQuoteCouponMutationVariables>;
export const RemoveQuoteCouponDocument = gql`
    mutation removeQuoteCoupon($quoteId: ID!) {
  removeQuoteCoupon(quoteId: $quoteId) {
    ...QuoteFields
  }
}
    ${QuoteFieldsFragmentDoc}`;
export type RemoveQuoteCouponMutationFn = Apollo.MutationFunction<RemoveQuoteCouponMutation, RemoveQuoteCouponMutationVariables>;

/**
 * __useRemoveQuoteCouponMutation__
 *
 * To run a mutation, you first call `useRemoveQuoteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveQuoteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeQuoteCouponMutation, { data, loading, error }] = useRemoveQuoteCouponMutation({
 *   variables: {
 *      quoteId: // value for 'quoteId'
 *   },
 * });
 */
export function useRemoveQuoteCouponMutation(baseOptions?: Apollo.MutationHookOptions<RemoveQuoteCouponMutation, RemoveQuoteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveQuoteCouponMutation, RemoveQuoteCouponMutationVariables>(RemoveQuoteCouponDocument, options);
      }
export type RemoveQuoteCouponMutationHookResult = ReturnType<typeof useRemoveQuoteCouponMutation>;
export type RemoveQuoteCouponMutationResult = Apollo.MutationResult<RemoveQuoteCouponMutation>;
export type RemoveQuoteCouponMutationOptions = Apollo.BaseMutationOptions<RemoveQuoteCouponMutation, RemoveQuoteCouponMutationVariables>;
export const GoogleReviewDocument = gql`
    query googleReview($id: ID!) {
  googleReview(id: $id) {
    ...GoogleReviewFields
  }
}
    ${GoogleReviewFieldsFragmentDoc}`;

/**
 * __useGoogleReviewQuery__
 *
 * To run a query within a React component, call `useGoogleReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleReviewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGoogleReviewQuery(baseOptions: Apollo.QueryHookOptions<GoogleReviewQuery, GoogleReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleReviewQuery, GoogleReviewQueryVariables>(GoogleReviewDocument, options);
      }
export function useGoogleReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleReviewQuery, GoogleReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleReviewQuery, GoogleReviewQueryVariables>(GoogleReviewDocument, options);
        }
export type GoogleReviewQueryHookResult = ReturnType<typeof useGoogleReviewQuery>;
export type GoogleReviewLazyQueryHookResult = ReturnType<typeof useGoogleReviewLazyQuery>;
export type GoogleReviewQueryResult = Apollo.QueryResult<GoogleReviewQuery, GoogleReviewQueryVariables>;
export const GoogleReviewMetadataForOutfitterDocument = gql`
    query googleReviewMetadataForOutfitter($outfitterId: ID!) {
  googleReviewMetadataForOutfitter(outfitterId: $outfitterId) {
    ...GoogleReviewMetadataFields
  }
}
    ${GoogleReviewMetadataFieldsFragmentDoc}`;

/**
 * __useGoogleReviewMetadataForOutfitterQuery__
 *
 * To run a query within a React component, call `useGoogleReviewMetadataForOutfitterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleReviewMetadataForOutfitterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleReviewMetadataForOutfitterQuery({
 *   variables: {
 *      outfitterId: // value for 'outfitterId'
 *   },
 * });
 */
export function useGoogleReviewMetadataForOutfitterQuery(baseOptions: Apollo.QueryHookOptions<GoogleReviewMetadataForOutfitterQuery, GoogleReviewMetadataForOutfitterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleReviewMetadataForOutfitterQuery, GoogleReviewMetadataForOutfitterQueryVariables>(GoogleReviewMetadataForOutfitterDocument, options);
      }
export function useGoogleReviewMetadataForOutfitterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleReviewMetadataForOutfitterQuery, GoogleReviewMetadataForOutfitterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleReviewMetadataForOutfitterQuery, GoogleReviewMetadataForOutfitterQueryVariables>(GoogleReviewMetadataForOutfitterDocument, options);
        }
export type GoogleReviewMetadataForOutfitterQueryHookResult = ReturnType<typeof useGoogleReviewMetadataForOutfitterQuery>;
export type GoogleReviewMetadataForOutfitterLazyQueryHookResult = ReturnType<typeof useGoogleReviewMetadataForOutfitterLazyQuery>;
export type GoogleReviewMetadataForOutfitterQueryResult = Apollo.QueryResult<GoogleReviewMetadataForOutfitterQuery, GoogleReviewMetadataForOutfitterQueryVariables>;
export const PublishedGoogleReviewsForOutfitterDocument = gql`
    query publishedGoogleReviewsForOutfitter($outfitterId: ID!, $limit: NonNegativeInt) {
  publishedGoogleReviewsForOutfitter(outfitterId: $outfitterId, limit: $limit) {
    ...GoogleReviewFields
  }
}
    ${GoogleReviewFieldsFragmentDoc}`;

/**
 * __usePublishedGoogleReviewsForOutfitterQuery__
 *
 * To run a query within a React component, call `usePublishedGoogleReviewsForOutfitterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedGoogleReviewsForOutfitterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedGoogleReviewsForOutfitterQuery({
 *   variables: {
 *      outfitterId: // value for 'outfitterId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePublishedGoogleReviewsForOutfitterQuery(baseOptions: Apollo.QueryHookOptions<PublishedGoogleReviewsForOutfitterQuery, PublishedGoogleReviewsForOutfitterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedGoogleReviewsForOutfitterQuery, PublishedGoogleReviewsForOutfitterQueryVariables>(PublishedGoogleReviewsForOutfitterDocument, options);
      }
export function usePublishedGoogleReviewsForOutfitterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedGoogleReviewsForOutfitterQuery, PublishedGoogleReviewsForOutfitterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedGoogleReviewsForOutfitterQuery, PublishedGoogleReviewsForOutfitterQueryVariables>(PublishedGoogleReviewsForOutfitterDocument, options);
        }
export type PublishedGoogleReviewsForOutfitterQueryHookResult = ReturnType<typeof usePublishedGoogleReviewsForOutfitterQuery>;
export type PublishedGoogleReviewsForOutfitterLazyQueryHookResult = ReturnType<typeof usePublishedGoogleReviewsForOutfitterLazyQuery>;
export type PublishedGoogleReviewsForOutfitterQueryResult = Apollo.QueryResult<PublishedGoogleReviewsForOutfitterQuery, PublishedGoogleReviewsForOutfitterQueryVariables>;
export const NativeReviewMetadataForOutfitterDocument = gql`
    query nativeReviewMetadataForOutfitter($outfitterId: ID!) {
  nativeReviewMetadataForOutfitter(outfitterId: $outfitterId) {
    ...NativeReviewMetadataFields
  }
}
    ${NativeReviewMetadataFieldsFragmentDoc}`;

/**
 * __useNativeReviewMetadataForOutfitterQuery__
 *
 * To run a query within a React component, call `useNativeReviewMetadataForOutfitterQuery` and pass it any options that fit your needs.
 * When your component renders, `useNativeReviewMetadataForOutfitterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNativeReviewMetadataForOutfitterQuery({
 *   variables: {
 *      outfitterId: // value for 'outfitterId'
 *   },
 * });
 */
export function useNativeReviewMetadataForOutfitterQuery(baseOptions: Apollo.QueryHookOptions<NativeReviewMetadataForOutfitterQuery, NativeReviewMetadataForOutfitterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NativeReviewMetadataForOutfitterQuery, NativeReviewMetadataForOutfitterQueryVariables>(NativeReviewMetadataForOutfitterDocument, options);
      }
export function useNativeReviewMetadataForOutfitterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NativeReviewMetadataForOutfitterQuery, NativeReviewMetadataForOutfitterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NativeReviewMetadataForOutfitterQuery, NativeReviewMetadataForOutfitterQueryVariables>(NativeReviewMetadataForOutfitterDocument, options);
        }
export type NativeReviewMetadataForOutfitterQueryHookResult = ReturnType<typeof useNativeReviewMetadataForOutfitterQuery>;
export type NativeReviewMetadataForOutfitterLazyQueryHookResult = ReturnType<typeof useNativeReviewMetadataForOutfitterLazyQuery>;
export type NativeReviewMetadataForOutfitterQueryResult = Apollo.QueryResult<NativeReviewMetadataForOutfitterQuery, NativeReviewMetadataForOutfitterQueryVariables>;
export const PublishedNativeReviewsForOutfitterDocument = gql`
    query publishedNativeReviewsForOutfitter($outfitterId: ID!, $limit: NonNegativeInt) {
  publishedNativeReviewsForOutfitter(outfitterId: $outfitterId, limit: $limit) {
    ...NativeReviewFields
  }
}
    ${NativeReviewFieldsFragmentDoc}`;

/**
 * __usePublishedNativeReviewsForOutfitterQuery__
 *
 * To run a query within a React component, call `usePublishedNativeReviewsForOutfitterQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedNativeReviewsForOutfitterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedNativeReviewsForOutfitterQuery({
 *   variables: {
 *      outfitterId: // value for 'outfitterId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePublishedNativeReviewsForOutfitterQuery(baseOptions: Apollo.QueryHookOptions<PublishedNativeReviewsForOutfitterQuery, PublishedNativeReviewsForOutfitterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedNativeReviewsForOutfitterQuery, PublishedNativeReviewsForOutfitterQueryVariables>(PublishedNativeReviewsForOutfitterDocument, options);
      }
export function usePublishedNativeReviewsForOutfitterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedNativeReviewsForOutfitterQuery, PublishedNativeReviewsForOutfitterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedNativeReviewsForOutfitterQuery, PublishedNativeReviewsForOutfitterQueryVariables>(PublishedNativeReviewsForOutfitterDocument, options);
        }
export type PublishedNativeReviewsForOutfitterQueryHookResult = ReturnType<typeof usePublishedNativeReviewsForOutfitterQuery>;
export type PublishedNativeReviewsForOutfitterLazyQueryHookResult = ReturnType<typeof usePublishedNativeReviewsForOutfitterLazyQuery>;
export type PublishedNativeReviewsForOutfitterQueryResult = Apollo.QueryResult<PublishedNativeReviewsForOutfitterQuery, PublishedNativeReviewsForOutfitterQueryVariables>;
export const PublishedNativeReviewsForListingDocument = gql`
    query publishedNativeReviewsForListing($listingId: ID!, $outfitterId: ID!, $limit: NonNegativeInt) {
  publishedNativeReviewsForListing(
    listingId: $listingId
    outfitterId: $outfitterId
    limit: $limit
  ) {
    ...NativeReviewFields
  }
}
    ${NativeReviewFieldsFragmentDoc}`;

/**
 * __usePublishedNativeReviewsForListingQuery__
 *
 * To run a query within a React component, call `usePublishedNativeReviewsForListingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublishedNativeReviewsForListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublishedNativeReviewsForListingQuery({
 *   variables: {
 *      listingId: // value for 'listingId'
 *      outfitterId: // value for 'outfitterId'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function usePublishedNativeReviewsForListingQuery(baseOptions: Apollo.QueryHookOptions<PublishedNativeReviewsForListingQuery, PublishedNativeReviewsForListingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublishedNativeReviewsForListingQuery, PublishedNativeReviewsForListingQueryVariables>(PublishedNativeReviewsForListingDocument, options);
      }
export function usePublishedNativeReviewsForListingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublishedNativeReviewsForListingQuery, PublishedNativeReviewsForListingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublishedNativeReviewsForListingQuery, PublishedNativeReviewsForListingQueryVariables>(PublishedNativeReviewsForListingDocument, options);
        }
export type PublishedNativeReviewsForListingQueryHookResult = ReturnType<typeof usePublishedNativeReviewsForListingQuery>;
export type PublishedNativeReviewsForListingLazyQueryHookResult = ReturnType<typeof usePublishedNativeReviewsForListingLazyQuery>;
export type PublishedNativeReviewsForListingQueryResult = Apollo.QueryResult<PublishedNativeReviewsForListingQuery, PublishedNativeReviewsForListingQueryVariables>;
export const UnpublishedSeoPagesDocument = gql`
    query unpublishedSeoPages {
  unpublishedSeoPages {
    id
    title
    image
    slug
    state
    species {
      id
      type
      name
    }
  }
}
    `;

/**
 * __useUnpublishedSeoPagesQuery__
 *
 * To run a query within a React component, call `useUnpublishedSeoPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnpublishedSeoPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnpublishedSeoPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnpublishedSeoPagesQuery(baseOptions?: Apollo.QueryHookOptions<UnpublishedSeoPagesQuery, UnpublishedSeoPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnpublishedSeoPagesQuery, UnpublishedSeoPagesQueryVariables>(UnpublishedSeoPagesDocument, options);
      }
export function useUnpublishedSeoPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnpublishedSeoPagesQuery, UnpublishedSeoPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnpublishedSeoPagesQuery, UnpublishedSeoPagesQueryVariables>(UnpublishedSeoPagesDocument, options);
        }
export type UnpublishedSeoPagesQueryHookResult = ReturnType<typeof useUnpublishedSeoPagesQuery>;
export type UnpublishedSeoPagesLazyQueryHookResult = ReturnType<typeof useUnpublishedSeoPagesLazyQuery>;
export type UnpublishedSeoPagesQueryResult = Apollo.QueryResult<UnpublishedSeoPagesQuery, UnpublishedSeoPagesQueryVariables>;
export const SeoPageBySlugDocument = gql`
    query seoPageBySlug($slug: String!) {
  seoPageBySlug(slug: $slug) {
    ...SeoPageFields
  }
}
    ${SeoPageFieldsFragmentDoc}`;

/**
 * __useSeoPageBySlugQuery__
 *
 * To run a query within a React component, call `useSeoPageBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useSeoPageBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSeoPageBySlugQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useSeoPageBySlugQuery(baseOptions: Apollo.QueryHookOptions<SeoPageBySlugQuery, SeoPageBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SeoPageBySlugQuery, SeoPageBySlugQueryVariables>(SeoPageBySlugDocument, options);
      }
export function useSeoPageBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SeoPageBySlugQuery, SeoPageBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SeoPageBySlugQuery, SeoPageBySlugQueryVariables>(SeoPageBySlugDocument, options);
        }
export type SeoPageBySlugQueryHookResult = ReturnType<typeof useSeoPageBySlugQuery>;
export type SeoPageBySlugLazyQueryHookResult = ReturnType<typeof useSeoPageBySlugLazyQuery>;
export type SeoPageBySlugQueryResult = Apollo.QueryResult<SeoPageBySlugQuery, SeoPageBySlugQueryVariables>;
export const GetPublishedSeoPagesDocument = gql`
    query getPublishedSeoPages {
  publishedSeoPages {
    id
    title
    slug
  }
}
    `;

/**
 * __useGetPublishedSeoPagesQuery__
 *
 * To run a query within a React component, call `useGetPublishedSeoPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedSeoPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedSeoPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublishedSeoPagesQuery(baseOptions?: Apollo.QueryHookOptions<GetPublishedSeoPagesQuery, GetPublishedSeoPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedSeoPagesQuery, GetPublishedSeoPagesQueryVariables>(GetPublishedSeoPagesDocument, options);
      }
export function useGetPublishedSeoPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedSeoPagesQuery, GetPublishedSeoPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedSeoPagesQuery, GetPublishedSeoPagesQueryVariables>(GetPublishedSeoPagesDocument, options);
        }
export type GetPublishedSeoPagesQueryHookResult = ReturnType<typeof useGetPublishedSeoPagesQuery>;
export type GetPublishedSeoPagesLazyQueryHookResult = ReturnType<typeof useGetPublishedSeoPagesLazyQuery>;
export type GetPublishedSeoPagesQueryResult = Apollo.QueryResult<GetPublishedSeoPagesQuery, GetPublishedSeoPagesQueryVariables>;
export const AllSpeciesDocument = gql`
    query allSpecies {
  allSpecies {
    ...searchSpeciesFields
  }
}
    ${SearchSpeciesFieldsFragmentDoc}`;

/**
 * __useAllSpeciesQuery__
 *
 * To run a query within a React component, call `useAllSpeciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllSpeciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllSpeciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllSpeciesQuery(baseOptions?: Apollo.QueryHookOptions<AllSpeciesQuery, AllSpeciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllSpeciesQuery, AllSpeciesQueryVariables>(AllSpeciesDocument, options);
      }
export function useAllSpeciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllSpeciesQuery, AllSpeciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllSpeciesQuery, AllSpeciesQueryVariables>(AllSpeciesDocument, options);
        }
export type AllSpeciesQueryHookResult = ReturnType<typeof useAllSpeciesQuery>;
export type AllSpeciesLazyQueryHookResult = ReturnType<typeof useAllSpeciesLazyQuery>;
export type AllSpeciesQueryResult = Apollo.QueryResult<AllSpeciesQuery, AllSpeciesQueryVariables>;
export const SearchSpeciesDocument = gql`
    query searchSpecies($data: SearchSpeciesInput!) {
  searchSpecies(data: $data) {
    ...searchSpeciesFields
  }
}
    ${SearchSpeciesFieldsFragmentDoc}`;

/**
 * __useSearchSpeciesQuery__
 *
 * To run a query within a React component, call `useSearchSpeciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSpeciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSpeciesQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSearchSpeciesQuery(baseOptions: Apollo.QueryHookOptions<SearchSpeciesQuery, SearchSpeciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchSpeciesQuery, SearchSpeciesQueryVariables>(SearchSpeciesDocument, options);
      }
export function useSearchSpeciesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchSpeciesQuery, SearchSpeciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchSpeciesQuery, SearchSpeciesQueryVariables>(SearchSpeciesDocument, options);
        }
export type SearchSpeciesQueryHookResult = ReturnType<typeof useSearchSpeciesQuery>;
export type SearchSpeciesLazyQueryHookResult = ReturnType<typeof useSearchSpeciesLazyQuery>;
export type SearchSpeciesQueryResult = Apollo.QueryResult<SearchSpeciesQuery, SearchSpeciesQueryVariables>;
export const UserDocument = gql`
    query user {
  user {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserHistoryDocument = gql`
    query userHistory {
  user {
    ...UserHistoryFields
  }
}
    ${UserHistoryFieldsFragmentDoc}`;

/**
 * __useUserHistoryQuery__
 *
 * To run a query within a React component, call `useUserHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHistoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserHistoryQuery(baseOptions?: Apollo.QueryHookOptions<UserHistoryQuery, UserHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHistoryQuery, UserHistoryQueryVariables>(UserHistoryDocument, options);
      }
export function useUserHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHistoryQuery, UserHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHistoryQuery, UserHistoryQueryVariables>(UserHistoryDocument, options);
        }
export type UserHistoryQueryHookResult = ReturnType<typeof useUserHistoryQuery>;
export type UserHistoryLazyQueryHookResult = ReturnType<typeof useUserHistoryLazyQuery>;
export type UserHistoryQueryResult = Apollo.QueryResult<UserHistoryQuery, UserHistoryQueryVariables>;
export const UserPaymentsDocument = gql`
    query userPayments {
  userPaymentsHistory {
    ...UserPaymentsHistoryFields
  }
}
    ${UserPaymentsHistoryFieldsFragmentDoc}`;

/**
 * __useUserPaymentsQuery__
 *
 * To run a query within a React component, call `useUserPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPaymentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<UserPaymentsQuery, UserPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserPaymentsQuery, UserPaymentsQueryVariables>(UserPaymentsDocument, options);
      }
export function useUserPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserPaymentsQuery, UserPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserPaymentsQuery, UserPaymentsQueryVariables>(UserPaymentsDocument, options);
        }
export type UserPaymentsQueryHookResult = ReturnType<typeof useUserPaymentsQuery>;
export type UserPaymentsLazyQueryHookResult = ReturnType<typeof useUserPaymentsLazyQuery>;
export type UserPaymentsQueryResult = Apollo.QueryResult<UserPaymentsQuery, UserPaymentsQueryVariables>;
export const IsUserUsingPasswordLoginDocument = gql`
    query isUserUsingPasswordLogin {
  isUserUsingPasswordLogin
}
    `;

/**
 * __useIsUserUsingPasswordLoginQuery__
 *
 * To run a query within a React component, call `useIsUserUsingPasswordLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserUsingPasswordLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserUsingPasswordLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserUsingPasswordLoginQuery(baseOptions?: Apollo.QueryHookOptions<IsUserUsingPasswordLoginQuery, IsUserUsingPasswordLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsUserUsingPasswordLoginQuery, IsUserUsingPasswordLoginQueryVariables>(IsUserUsingPasswordLoginDocument, options);
      }
export function useIsUserUsingPasswordLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserUsingPasswordLoginQuery, IsUserUsingPasswordLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsUserUsingPasswordLoginQuery, IsUserUsingPasswordLoginQueryVariables>(IsUserUsingPasswordLoginDocument, options);
        }
export type IsUserUsingPasswordLoginQueryHookResult = ReturnType<typeof useIsUserUsingPasswordLoginQuery>;
export type IsUserUsingPasswordLoginLazyQueryHookResult = ReturnType<typeof useIsUserUsingPasswordLoginLazyQuery>;
export type IsUserUsingPasswordLoginQueryResult = Apollo.QueryResult<IsUserUsingPasswordLoginQuery, IsUserUsingPasswordLoginQueryVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($data: UpdateUserInput!) {
  updateUser(data: $data) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AddListingHistoryDocument = gql`
    mutation addListingHistory($data: UserListingHistoryInput!) {
  addListingHistory(data: $data) {
    ...UserHistoryFields
  }
}
    ${UserHistoryFieldsFragmentDoc}`;
export type AddListingHistoryMutationFn = Apollo.MutationFunction<AddListingHistoryMutation, AddListingHistoryMutationVariables>;

/**
 * __useAddListingHistoryMutation__
 *
 * To run a mutation, you first call `useAddListingHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddListingHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addListingHistoryMutation, { data, loading, error }] = useAddListingHistoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddListingHistoryMutation(baseOptions?: Apollo.MutationHookOptions<AddListingHistoryMutation, AddListingHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddListingHistoryMutation, AddListingHistoryMutationVariables>(AddListingHistoryDocument, options);
      }
export type AddListingHistoryMutationHookResult = ReturnType<typeof useAddListingHistoryMutation>;
export type AddListingHistoryMutationResult = Apollo.MutationResult<AddListingHistoryMutation>;
export type AddListingHistoryMutationOptions = Apollo.BaseMutationOptions<AddListingHistoryMutation, AddListingHistoryMutationVariables>;
export const AddOutfitterHistoryDocument = gql`
    mutation addOutfitterHistory($data: UserOutfitterHistoryInput!) {
  addOutfitterHistory(data: $data) {
    ...UserHistoryFields
  }
}
    ${UserHistoryFieldsFragmentDoc}`;
export type AddOutfitterHistoryMutationFn = Apollo.MutationFunction<AddOutfitterHistoryMutation, AddOutfitterHistoryMutationVariables>;

/**
 * __useAddOutfitterHistoryMutation__
 *
 * To run a mutation, you first call `useAddOutfitterHistoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOutfitterHistoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOutfitterHistoryMutation, { data, loading, error }] = useAddOutfitterHistoryMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddOutfitterHistoryMutation(baseOptions?: Apollo.MutationHookOptions<AddOutfitterHistoryMutation, AddOutfitterHistoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOutfitterHistoryMutation, AddOutfitterHistoryMutationVariables>(AddOutfitterHistoryDocument, options);
      }
export type AddOutfitterHistoryMutationHookResult = ReturnType<typeof useAddOutfitterHistoryMutation>;
export type AddOutfitterHistoryMutationResult = Apollo.MutationResult<AddOutfitterHistoryMutation>;
export type AddOutfitterHistoryMutationOptions = Apollo.BaseMutationOptions<AddOutfitterHistoryMutation, AddOutfitterHistoryMutationVariables>;
export const AssignOutfitterRoleDocument = gql`
    mutation assignOutfitterRole {
  assignOutfitterRole
}
    `;
export type AssignOutfitterRoleMutationFn = Apollo.MutationFunction<AssignOutfitterRoleMutation, AssignOutfitterRoleMutationVariables>;

/**
 * __useAssignOutfitterRoleMutation__
 *
 * To run a mutation, you first call `useAssignOutfitterRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignOutfitterRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignOutfitterRoleMutation, { data, loading, error }] = useAssignOutfitterRoleMutation({
 *   variables: {
 *   },
 * });
 */
export function useAssignOutfitterRoleMutation(baseOptions?: Apollo.MutationHookOptions<AssignOutfitterRoleMutation, AssignOutfitterRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignOutfitterRoleMutation, AssignOutfitterRoleMutationVariables>(AssignOutfitterRoleDocument, options);
      }
export type AssignOutfitterRoleMutationHookResult = ReturnType<typeof useAssignOutfitterRoleMutation>;
export type AssignOutfitterRoleMutationResult = Apollo.MutationResult<AssignOutfitterRoleMutation>;
export type AssignOutfitterRoleMutationOptions = Apollo.BaseMutationOptions<AssignOutfitterRoleMutation, AssignOutfitterRoleMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation changeUserPassword($newPassword: String!) {
  changeUserPassword(newPassword: $newPassword)
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, options);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const GetUserBookingsDocument = gql`
    query getUserBookings {
  bookings {
    id
    listing {
      id
      title
      photos {
        url
      }
      location {
        city
        state
      }
      type
    }
    dates
    status
  }
}
    `;

/**
 * __useGetUserBookingsQuery__
 *
 * To run a query within a React component, call `useGetUserBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBookingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserBookingsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserBookingsQuery, GetUserBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserBookingsQuery, GetUserBookingsQueryVariables>(GetUserBookingsDocument, options);
      }
export function useGetUserBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserBookingsQuery, GetUserBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserBookingsQuery, GetUserBookingsQueryVariables>(GetUserBookingsDocument, options);
        }
export type GetUserBookingsQueryHookResult = ReturnType<typeof useGetUserBookingsQuery>;
export type GetUserBookingsLazyQueryHookResult = ReturnType<typeof useGetUserBookingsLazyQuery>;
export type GetUserBookingsQueryResult = Apollo.QueryResult<GetUserBookingsQuery, GetUserBookingsQueryVariables>;
export const WishlistDocument = gql`
    query wishlist($id: ID!) {
  wishlist(id: $id) {
    ...WishlistFields
  }
}
    ${WishlistFieldsFragmentDoc}`;

/**
 * __useWishlistQuery__
 *
 * To run a query within a React component, call `useWishlistQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWishlistQuery(baseOptions: Apollo.QueryHookOptions<WishlistQuery, WishlistQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WishlistQuery, WishlistQueryVariables>(WishlistDocument, options);
      }
export function useWishlistLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WishlistQuery, WishlistQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WishlistQuery, WishlistQueryVariables>(WishlistDocument, options);
        }
export type WishlistQueryHookResult = ReturnType<typeof useWishlistQuery>;
export type WishlistLazyQueryHookResult = ReturnType<typeof useWishlistLazyQuery>;
export type WishlistQueryResult = Apollo.QueryResult<WishlistQuery, WishlistQueryVariables>;
export const WishlistsByUserDocument = gql`
    query wishlistsByUser($userId: ID!) {
  wishlistsByUser(userId: $userId) {
    ...WishlistFields
  }
}
    ${WishlistFieldsFragmentDoc}`;

/**
 * __useWishlistsByUserQuery__
 *
 * To run a query within a React component, call `useWishlistsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useWishlistsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWishlistsByUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWishlistsByUserQuery(baseOptions: Apollo.QueryHookOptions<WishlistsByUserQuery, WishlistsByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WishlistsByUserQuery, WishlistsByUserQueryVariables>(WishlistsByUserDocument, options);
      }
export function useWishlistsByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WishlistsByUserQuery, WishlistsByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WishlistsByUserQuery, WishlistsByUserQueryVariables>(WishlistsByUserDocument, options);
        }
export type WishlistsByUserQueryHookResult = ReturnType<typeof useWishlistsByUserQuery>;
export type WishlistsByUserLazyQueryHookResult = ReturnType<typeof useWishlistsByUserLazyQuery>;
export type WishlistsByUserQueryResult = Apollo.QueryResult<WishlistsByUserQuery, WishlistsByUserQueryVariables>;
export const UpdateWishlistDocument = gql`
    mutation updateWishlist($id: ID!, $data: UpdateWishlistInput!) {
  updateWishlist(id: $id, data: $data) {
    ...WishlistFields
  }
}
    ${WishlistFieldsFragmentDoc}`;
export type UpdateWishlistMutationFn = Apollo.MutationFunction<UpdateWishlistMutation, UpdateWishlistMutationVariables>;

/**
 * __useUpdateWishlistMutation__
 *
 * To run a mutation, you first call `useUpdateWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWishlistMutation, { data, loading, error }] = useUpdateWishlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateWishlistMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWishlistMutation, UpdateWishlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWishlistMutation, UpdateWishlistMutationVariables>(UpdateWishlistDocument, options);
      }
export type UpdateWishlistMutationHookResult = ReturnType<typeof useUpdateWishlistMutation>;
export type UpdateWishlistMutationResult = Apollo.MutationResult<UpdateWishlistMutation>;
export type UpdateWishlistMutationOptions = Apollo.BaseMutationOptions<UpdateWishlistMutation, UpdateWishlistMutationVariables>;
export const CreateWishlistDocument = gql`
    mutation createWishlist($data: CreateWishlistInput!) {
  createWishlist(data: $data) {
    id
  }
}
    `;
export type CreateWishlistMutationFn = Apollo.MutationFunction<CreateWishlistMutation, CreateWishlistMutationVariables>;

/**
 * __useCreateWishlistMutation__
 *
 * To run a mutation, you first call `useCreateWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWishlistMutation, { data, loading, error }] = useCreateWishlistMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateWishlistMutation(baseOptions?: Apollo.MutationHookOptions<CreateWishlistMutation, CreateWishlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWishlistMutation, CreateWishlistMutationVariables>(CreateWishlistDocument, options);
      }
export type CreateWishlistMutationHookResult = ReturnType<typeof useCreateWishlistMutation>;
export type CreateWishlistMutationResult = Apollo.MutationResult<CreateWishlistMutation>;
export type CreateWishlistMutationOptions = Apollo.BaseMutationOptions<CreateWishlistMutation, CreateWishlistMutationVariables>;
export const RemoveWishlistDocument = gql`
    mutation removeWishlist($id: ID!) {
  removeWishlist(id: $id)
}
    `;
export type RemoveWishlistMutationFn = Apollo.MutationFunction<RemoveWishlistMutation, RemoveWishlistMutationVariables>;

/**
 * __useRemoveWishlistMutation__
 *
 * To run a mutation, you first call `useRemoveWishlistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWishlistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWishlistMutation, { data, loading, error }] = useRemoveWishlistMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWishlistMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWishlistMutation, RemoveWishlistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWishlistMutation, RemoveWishlistMutationVariables>(RemoveWishlistDocument, options);
      }
export type RemoveWishlistMutationHookResult = ReturnType<typeof useRemoveWishlistMutation>;
export type RemoveWishlistMutationResult = Apollo.MutationResult<RemoveWishlistMutation>;
export type RemoveWishlistMutationOptions = Apollo.BaseMutationOptions<RemoveWishlistMutation, RemoveWishlistMutationVariables>;
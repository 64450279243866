/* eslint-disable @revved/immutable/no-mutation */
import { useEffect } from "react"
import { useLocation, useMatch } from "@reach/router"

export const useHelpCrunch = () => {
    const { pathname } = useLocation()
    const matchHomePage = pathname === "/"
    const matchHelpPage = !!useMatch("/help")

    useEffect(() => {
        const showHelpCrunch = matchHomePage || !!matchHelpPage
        if (showHelpCrunch) HelpCrunch("showChatWidget")
        else HelpCrunch("hideChatWidget")
    }, [pathname, matchHomePage, matchHelpPage])
}

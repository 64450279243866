import React, { useState, useEffect } from "react"
import fetch from "isomorphic-fetch"
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    createHttpLink,
} from "@apollo/client"
// import { setContext } from "@apollo/client/link/context"
import { useAuth0 } from "@auth0/auth0-react"

const Provider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    // const accessToken = useAccessToken()

    const httpLink = createHttpLink({
        uri: process.env.GATSBY_API_URL,
        credentials: "same-origin",
        fetch,
    })

    // const authLink = setContext((_, { headers }) => {
    //     if (accessToken)
    //         return {
    //             headers: {
    //                 ...headers,
    //                 authorization: accessToken ? `Bearer ${accessToken}` : "",
    //             },
    //         }

    //     return { headers }
    // })

    const client = new ApolloClient({
        link: httpLink,
        cache: new InMemoryCache(),
        credentials: "include",
    })

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export function useAccessToken() {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0()
    const [accessToken, setAccessToken] = useState<string | null>(null)

    useEffect(() => {
        const getToken = async () => {
            setAccessToken(
                isAuthenticated ? await getAccessTokenSilently() : null
            )
        }
        getToken()
    }, [getAccessTokenSilently, isAuthenticated])

    return accessToken
}

export const useAuthHeaders = () => {
    const accessToken = useAccessToken()

    return {
        authContext: {
            headers: accessToken
                ? { authorization: `Bearer ${accessToken}` }
                : {},
        },
        hasAccessToken: !!accessToken,
    }
}

export default Provider

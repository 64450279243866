export const SOCIAL_LINKS = {
    FACEBOOK: "https://www.facebook.com/Mallard-Bay-Outdoors-116624006387763",
    TWITTER: "https://twitter.com/mallardbay_",
    INSTAGRAM: "https://www.instagram.com/mallardbay_",
    YOUTUBE: "https://www.youtube.com/@mallardbayoutdoors8103",
    LINKEDIN: "https://www.linkedin.com/company/mallard-bay-outdoors-llc",
}

export const SUPPORT_CONTACTS = {
    PHONE: "+15129709123",
    EMAIL: "support@mallardbay.com",
}

export const BASEUI = {
    DATE_FORMAT_STRING: "MM/dd/yyyy",
    DATE_MASK_STRING: "99/99/9999",
}

export const SEARCH_PAGE_SIZE = 20

export const allowedHTMLTags = ["p", "strong", "em", "u"]

export const QueryKeys = {
    SetupIntent: ["setup-intent"],
    PaymentMethods: ["payment-methods"],
}

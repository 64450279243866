import * as queryString from "query-string"
import { format, differenceInDays } from "date-fns"
import money from "money-math"
import head from "lodash/head"
import filter from "lodash/filter"
import replace from "lodash/replace"
import isAfter from "date-fns/isAfter"

import {
    Address,
    ListingType,
    LodgingType,
    BedType,
    PricingOption,
} from "~graphql/generated/graphql"

export function formatPhoneNumber(phoneNumber: string): string {
    const cleaned = ("" + phoneNumber).replace(/\D/g, "")
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`
    }
    return phoneNumber
}

export function formatPrice(price: string, hideZeroCents: boolean = true) {
    const formattedPrice = `$${money.format("USD", price)}`
    return hideZeroCents ? replace(formattedPrice, ".00", "") : formattedPrice
}

export function getLowestPricingOption(pricingOptions?: PricingOption[]) {
    return pricingOptions?.reduce(
        (prev, curr) =>
            parseFloat(prev?.day_rate || "0") < parseFloat(curr.day_rate)
                ? prev
                : curr,
        head(pricingOptions)
    )
}

export function validatePhoneNumber(phone: string): boolean {
    // strip phone number and check if its 10 or 11 digits, if it's 11 digits, check if it starts with 1
    const strippedPhone = phone.replace(/\D/g, "")
    return strippedPhone.length === 11 && strippedPhone[0] === "1"
}

export function getUrlQueryParamsObj() {
    if (typeof window === "undefined") {
        return {}
    }
    const { search } = window.location
    return queryString.parse(search)
}

// @ts-ignore
export function construcQueryParamsString(queryParamsObj) {
    return queryString.stringify(queryParamsObj)
}

export function getDayOfWeek(date: Date) {
    return format(date, "EEEE")
}

export function getDayOfMonth(date: Date) {
    return format(date, "d")
}

export function getMonthAbbr(date: Date) {
    return format(date, "MMM")
}

export function getDifferenceInDays(start: Date, end: Date) {
    return differenceInDays(end, start)
}

export function getListingTypeCamelcase(type = ListingType.Hunting) {
    switch (type) {
        case ListingType.Hunting:
            return "Hunting"
        case ListingType.Fishing:
            return "Fishing"
        case ListingType.BlastCast:
            return "Blast and Cast"
        default:
            return "Other"
    }
}

export function getLodgingTypeCamelcase(type: string) {
    switch (type) {
        case LodgingType.Tent:
            return "Tent"
        case LodgingType.Bedroom:
            return "Bedroom"
        case LodgingType.CommonArea:
            return "Common Area"
        default:
            return "Other"
    }
}

export function getBedTypeCamelcase(type: string) {
    switch (type) {
        case BedType.TwinBed:
            return "Twin Bed"
        case BedType.QueenBed:
            return "Queen Bed"
        case BedType.KingBed:
            return "King Bed"
        case BedType.SleepingBag:
            return "Sleeping Bag"
        case BedType.Hammock:
            return "Hammock"
        case BedType.Sofa:
            return "Sofa"
        default:
            return "Other"
    }
}

export function getListingShortLocation(location: Address) {
    return `${location.city}, ${location.state}`
}

// Filters out dates before minDate
export function getAvailableListingDates({
    includeDates,
    minDate,
}: {
    includeDates: Date[]
    minDate: Date
}) {
    if (!minDate) return includeDates

    return filter(includeDates, (date) => isAfter(date, minDate))
}

import React, { useContext } from "react"
import { useStyletron } from "baseui"
import { Button, SIZE, KIND, SHAPE } from "baseui/button"
import { Delete } from "baseui/icon"

import SignUpForm from "~components/shared/auth-modal/sign-up-form"
import SignInForm from "~components/shared/auth-modal/sign-in-form"
import { authForms, AuthModalContext } from "~components/shared/header/header"

const AuthModalContent = () => {
    const modalContext = useContext(AuthModalContext)
    const styles = useStyles()

    return (
        <div className={styles.wrapper}>
            <div className={styles.header}>
                {modalContext?.authForm === authForms.SIGN_UP && "Sign up"}
                {modalContext?.authForm === authForms.SIGN_IN && "Log in"}
                <Button
                    size={SIZE.mini}
                    kind={KIND.tertiary}
                    shape={SHAPE.round}
                    // @ts-ignore
                    className={styles.closeButton}
                    onClick={() => modalContext?.setOpenAuthModal(false)}
                >
                    <Delete size={25} />
                </Button>
            </div>
            {modalContext?.authForm === authForms.SIGN_UP && <SignUpForm />}
            {modalContext?.authForm === authForms.SIGN_IN && <SignInForm />}
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        wrapper: css({
            width: "550px",
        }),
        header: css({
            position: "relative",
            paddingTop: "16px",
            paddingRight: "0",
            paddingBottom: "16px",
            paddingLeft: "0",
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "center",
            borderBottom: `1px solid ${theme.colors.borderOpaque}`,
        }),
        closeButton: css({
            position: "absolute",
            top: "50%",
            right: "8px",
            transform: "translate(0, -50%)",
        }),
    }
}

export default AuthModalContent

import React from "react"
import { useStyletron } from "baseui"
import { Modal, SIZE as ModalSize } from "baseui/modal"
import { Button, SIZE as ButtonSize, KIND, SHAPE } from "baseui/button"
import { Delete } from "baseui/icon"

type MbModalProps = {
    title: string
    isOpen: boolean
    onClose: (args: any) => any
    containerStyle?: React.CSSProperties
    allowClose?: boolean
    children: React.ReactNode
    noPadding?: boolean
}

const MbModal: React.FC<MbModalProps> = (props) => {
    const {
        title,
        isOpen,
        onClose,
        containerStyle,
        allowClose = true,
        children,
        noPadding = false,
    } = props
    const styles = useStyles()

    return (
        <Modal
            size={ModalSize.auto}
            isOpen={isOpen}
            onClose={onClose}
            overrides={getModalStylesOverrides()}
            closeable={allowClose}
        >
            <div className={styles.header}>
                {title}
                {allowClose && (
                    <Button
                        size={ButtonSize.mini}
                        kind={KIND.tertiary}
                        shape={SHAPE.round}
                        // @ts-ignore
                        className={styles.closeButton}
                        onClick={onClose}
                    >
                        <Delete size={25} />
                    </Button>
                )}
            </div>
            <div
                className={!noPadding ? styles.content : undefined}
                style={containerStyle}
            >
                {children}
            </div>
        </Modal>
    )
}

function getModalStylesOverrides() {
    return {
        Close: { style: { display: "none" } },
        Dialog: {
            style: ({ $theme }: any) => {
                const borderRadius = $theme.borders.radius400

                return {
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    borderBottomRightRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                    width: "90vw",
                    overflow: "hidden",
                    [$theme.mediaQuery.small]: {
                        width: "auto",
                        minWidth: "396px",
                    },
                }
            },
        },
    }
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        header: css({
            position: "relative",
            paddingTop: theme.sizing.scale700,
            paddingRight: 0,
            paddingBottom: theme.sizing.scale700,
            paddingLeft: 0,
            fontSize: "18px",
            fontWeight: 700,
            textAlign: "center",
            color: theme.colors.contentPrimary,
            borderBottom: `1px solid ${theme.colors.borderOpaque}`,
        }),
        content: css({
            boxSizing: "border-box",
            paddingTop: "40px",
            paddingRight: "24px",
            paddingBottom: "40px",
            paddingLeft: "24px",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.small]: {
                minWidth: "400px",
                width: "auto",
            },
        }),
        noPaddingContent: css({
            boxSizing: "border-box",
            color: theme.colors.contentPrimary,
            [theme.mediaQuery.small]: {
                minWidth: "400px",
                width: "auto",
            },
        }),
        closeButton: css({
            position: "absolute",
            top: "50%",
            right: "8px",
            transform: "translate(0, -50%)",
        }),
    }
}

export default MbModal

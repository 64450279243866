import React, { useEffect, useState } from "react"
import {
    FaPaw,
    FaHouseUser,
    FaAddressCard,
    FaMapMarkerAlt,
} from "react-icons/fa"
import { navigate } from "gatsby"
import { useStyletron } from "baseui"

import {
    Listing,
    Outfitter,
    useSearchListingsQuery,
    useSearchOutfittersQuery,
    SearchSpeciesFieldsFragment,
    SearchListingFieldsFragment,
    FeaturedOutfitterFieldsFragment,
} from "~graphql/generated/graphql"
import { useDebounceString } from "~utils/use-debounce-string"

const SearchDropdown: React.FC<{
    input: string
    states: string[]
    species: SearchSpeciesFieldsFragment[]
    clickHandler: (...args: any[]) => void
}> = ({ input, states, species, clickHandler }) => {
    const styles = useStyles()

    const DEBOUNCE_TIME_MS = 300
    const [debouncedInput] = useDebounceString(input, DEBOUNCE_TIME_MS)
    const [outfitters, setOutfitters] = useState<Outfitter[]>([])
    const [listings, setListings] = useState<Listing[]>([])

    const { data: outfittersData, loading: outfittersLoading } =
        useSearchOutfittersQuery({
            variables: {
                text: debouncedInput || "",
                size: 5,
                page: 0,
            },
        })

    // const outfitters = outfittersData?.outfitters.results || []

    const { data: listingsData, loading: listingsLoading } =
        useSearchListingsQuery({
            variables: {
                data: {
                    text: debouncedInput || "",
                },
                from: 0,
            },
        })

    useEffect(() => {
        if (!outfittersLoading) {
            setOutfitters(
                outfittersData?.outfitters.results.filter(
                    (o) => o.status === "APPROVED"
                ) as Outfitter[]
            )
        }
    }, [outfittersData, outfittersLoading])

    useEffect(() => {
        if (!listingsLoading) {
            setListings(listingsData?.listings.results as Listing[])
        }
    }, [listingsData, listingsLoading])

    // const listings = listingsData?.listings.results || []

    return (
        <>
            {/* State suggestions */}
            {states.length > 0 && <div className={styles.label}>STATES</div>}
            {states.map((state, i) => (
                <LocationSuggestion
                    key={i}
                    state={state}
                    handleClick={clickHandler}
                />
            ))}
            {/* Species suggestions */}
            {species.length > 0 && <div className={styles.label}>SPECIES</div>}
            {species.map((s, i) => (
                <SpeciesSuggestion
                    key={i}
                    species={s}
                    handleClick={clickHandler}
                />
            ))}
            {/* Outfitter suggestions */}
            {outfitters.length > 0 && (
                <div className={styles.label}>OUTFITTERS</div>
            )}
            {outfitters.map((o, i) => (
                <OutfitterSuggestion key={i} outfitter={o} />
            ))}
            {/* Listing suggestions */}
            {listings.length > 0 && (
                <div className={styles.label}>LISTINGS</div>
            )}
            {listings.slice(0, 5).map((l, i) => (
                <ListingSuggestion key={i} listing={l} />
            ))}
            {/* Empty suggestions */}
            {states.length === 0 &&
                species.length === 0 &&
                outfitters.length === 0 &&
                listings.length === 0 && (
                    <div className={styles.empty}>NO MATCHING SUGGESTIONS</div>
                )}
        </>
    )
}

const LocationSuggestion: React.FC<{
    state: string
    handleClick: (...args: any[]) => any
}> = ({ state, handleClick }) => {
    const styles = useStyles()

    return (
        <div onClick={() => handleClick(`${state}`)} className={styles.link}>
            <div className={styles.suggestion}>
                <div className={styles.icon}>
                    <FaMapMarkerAlt />
                </div>
                <div className={styles.text}>{state}</div>
            </div>
        </div>
    )
}

const SpeciesSuggestion: React.FC<{
    species: SearchSpeciesFieldsFragment
    handleClick: (...args: any[]) => any
}> = ({ species, handleClick }) => {
    const styles = useStyles()
    const { name } = species

    return (
        <div onClick={() => handleClick(`${name}`)} className={styles.link}>
            <div className={styles.suggestion}>
                <div className={styles.icon}>
                    <FaPaw />
                </div>
                <div className={styles.text}>{name}</div>
            </div>
        </div>
    )
}

const OutfitterSuggestion: React.FC<{
    outfitter: FeaturedOutfitterFieldsFragment
}> = ({ outfitter }) => {
    const styles = useStyles()
    const { name, location, slug, avatar } = outfitter

    function onClick() {
        navigate(`/${slug}`)
    }

    return (
        <div className={styles.link} onClick={onClick}>
            <div className={styles.suggestion}>
                <div className={styles.icon}>
                    {avatar ? (
                        <img
                            alt={name}
                            src={avatar.url}
                            className={styles.outfitterAvatar}
                        />
                    ) : (
                        <FaAddressCard />
                    )}
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.text}>{name}</div>
                    <div className={styles.subtext}>
                        {location?.city}, {location?.state}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ListingSuggestion: React.FC<{
    listing: SearchListingFieldsFragment
}> = ({ listing }) => {
    const styles = useStyles()
    const { id, title, outfitter, location } = listing

    function onClick() {
        navigate(`/listing/${id}`)
    }

    return (
        <div className={styles.link} onClick={onClick}>
            <div className={styles.suggestion}>
                <div className={styles.icon}>
                    <FaHouseUser />
                </div>
                <div className={styles.textContainer}>
                    <div className={styles.text}>{title}</div>
                    <div className={styles.subtext}>
                        {outfitter.name} - {location?.state}
                    </div>
                </div>
            </div>
        </div>
    )
}

function useStyles() {
    const [css, theme] = useStyletron()

    return {
        label: css({
            fontSize: "14px",
            fontWeight: 700,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: theme.sizing.scale900,
            marginBottom: theme.sizing.scale400,
            paddingRight: theme.sizing.scale200,
            paddingLeft: theme.sizing.scale200,
            ":first-child": {
                marginTop: theme.sizing.scale200,
            },
            [theme.mediaQuery.small]: {
                paddingRight: theme.sizing.scale800,
                paddingLeft: theme.sizing.scale800,
            },
        }),
        empty: css({
            fontSize: "14px",
            fontWeight: 700,
            paddingTop: 0,
            paddingRight: theme.sizing.scale200,
            paddingBottom: 0,
            paddingLeft: theme.sizing.scale200,
            marginTop: theme.sizing.scale200,
            marginRight: "0",
            marginBottom: theme.sizing.scale100,
            marginLeft: "0",
            [theme.mediaQuery.small]: {
                paddingRight: theme.sizing.scale800,
                paddingLeft: theme.sizing.scale800,
            },
        }),
        link: css({
            textDecoration: "none",
        }),
        suggestion: css({
            color: theme.colors.contentPrimary,
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "40px auto",
            gridColumnGap: theme.sizing.scale600,
            paddingTop: theme.sizing.scale200,
            paddingRight: theme.sizing.scale200,
            paddingBottom: theme.sizing.scale200,
            paddingLeft: theme.sizing.scale200,
            [theme.mediaQuery.small]: {
                paddingRight: theme.sizing.scale800,
                paddingLeft: theme.sizing.scale800,
                ":hover": {
                    cursor: "pointer",
                    backgroundColor: theme.colors.backgroundSecondary,
                },
            },
        }),
        outfitterAvatar: css({
            width: "100%",
            height: "100%",
            objectFit: "contain",
            backgroundColor: theme.colors.backgroundAlwaysLight,
        }),
        icon: css({
            display: "grid",
            placeItems: "center",
            width: "40px",
            height: "40px",
            overflow: "hidden",
            transform: "translateZ(0)",
            backgroundColor: theme.colors.backgroundSecondary,
            borderTopLeftRadius: theme.borders.radius300,
            borderTopRightRadius: theme.borders.radius300,
            borderBottomRightRadius: theme.borders.radius300,
            borderBottomLeftRadius: theme.borders.radius300,
            ...theme.borders.border200,
        }),
        textContainer: css({
            minWidth: "100%",
        }),
        text: css({
            fontSize: "17px",
            fontWeight: 300,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            minWidth: "0",
        }),
        subtext: css({
            display: "flex",
            alignItems: "center",
            fontSize: "13px",
            fontWeight: 300,
            color: "GrayText",
        }),
    }
}

export default SearchDropdown
